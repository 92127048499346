import { Checkbox, Input, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState, useEffect, useCallback, useMemo } from 'react';

import CategorySelect from '../../../../../../components/CategorySelect';
import ProductSelect from '../../../../../../components/ProductSelect';
import { TableInput } from '../../../../../../components/TableInput';
import { DiscountRule } from '../../../../../../stores/Discount.mobx';
import stores from '../../../../../../stores/index.mobx';
import styles from './SaleChannelsInput.module.less';

type Props = {
	value?: any[];
	onChange?: (any) => void;
};

function SaleChannelsInput({ value = [], onChange, ...props }: Props) {
	const [currentValue, setCurrentValue] = useState(value || []);

	useEffect(() => {
		setCurrentValue(value || []);
	}, [value]);

	const handleChange = useCallback((value) => {
		setCurrentValue(value);
		onChange(value);
	}, []);

	useEffect(() => {
		if (stores.saleChannels.all.length > 0) {
			// remove all categories that are not in the list and add new ones
			const newValue = (value || []).filter((item) =>
				stores.saleChannels.all.find(
					(channel) => channel.id === item.saleChannelId
				)
			);
			stores.saleChannels.all.forEach((channel) => {
				if (!newValue.find((item) => item.saleChannelId === channel.id)) {
					newValue.push({
						saleChannelId: channel.id,
						name: channel.name,
						enabled: false,
					});
				}
			});

			handleChange(newValue);
		}
	}, [stores.saleChannels.all]);

	const formFields = useMemo(() => {
		return [];
	}, []);

	return (
		<TableInput
			isResponsive={false}
			presetItems
			addButtonText=""
			emptyText=""
			iconPath="/images/icons/branch.png"
			columns={[
				{
					title: '',
					key: 'enabled',
					dataIndex: 'enabled',
					editable: true,
					valuePropName: 'checked',
					component: <Checkbox />,
					width: 32,
				},
				{
					title: 'Naziv',
					key: 'name',
					dataIndex: 'name',
					editable: false,
				},
				// TODO: salePrice, saleQuantity
			]}
			value={currentValue}
			onChange={handleChange}
			editInDrawer
			editDrawerTitle={(item) =>
				item ? `Dostupnost na kanalu prodaje ${item.name}` : null
			}
			formFields={formFields}
			{...props}
		/>
	);
}

export default observer(SaleChannelsInput);
