import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import {
	Alert,
	Button,
	Card,
	Col,
	Drawer,
	Form,
	Input,
	InputNumber,
	List,
	notification,
	Row,
	Space,
	Tag,
} from 'antd';
import keyBy from 'lodash/keyBy';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useDrawer } from '../../../../../../../../../components/Page';
import pageStyles from '../../../../../../../../../components/Page/Page.module.less';
import { v2Client } from '../../../../../../../../../store/client';
import { WoltItem } from '../types';
import styles from './ConnectProductsDrawer.module.less';

interface Props {
	visible: boolean;
	onClose: () => void;
	items: WoltItem[];
}

interface RenderDrawerProps {
	items: WoltItem[];
	form: any;
	openSearchDrawer: (index: number) => void;
	onClose: () => void;
	setIsSaving: (isSaving: boolean) => void;
}

function ConnectProductDrawer({ visible, onClose, items }: Props) {
	const [form] = Form.useForm();

	const formatItem = useCallback(
		(item) => ({
			externalId: item.id,
			name: item.product?.name || item.name?.[0]?.value,
			description: item.product?.description || item.description?.[0]?.value,
			productId: item.product?.productId,
			productName: item.product?.product?.parent
				? `${item.product?.product?.parent?.name} ${item.product?.product?.name}`
				: item.product?.product?.name,
			salePrice: item.product?.salePrice,
			saleQuantity: item.product?.saleQuantity,
		}),
		[]
	);

	const formItems = useRef(keyBy(items.map(formatItem), 'externalId'));

	useEffect(() => {
		formItems.current = keyBy(items.map(formatItem), 'externalId');
	}, [items]);

	const onValuesChange = useCallback((changedValues) => {
		formItems.current = {
			...formItems.current,
			...Object.fromEntries(
				Object.entries(changedValues).map(
					([k, v]: [string, Record<string, any>]) => [
						k,
						{
							...formItems.current[k],
							...v,
						},
					]
				)
			),
		};
	}, []);

	const searchListener = useCallback(
		(event, item, index) => {
			if (event === 'select') {
				form.setFieldValue([index, 'productId'], item.id);
				form.setFieldValue(
					[index, 'productName'],
					item.parent ? `${item.parent.name} ${item.name}` : item.name
				);

				onValuesChange({
					[index]: {
						productId: item.id,
						productName: item.parent
							? `${item.parent.name} ${item.name}`
							: item.name,
					},
				});
			}
		},
		[form]
	);

	const [, openSearchDrawer, , , , ProductSearchDrawer] = useDrawer(
		'product-search',
		searchListener,
		false
	);

	const [isSaving, setIsSaving] = useState(false);

	const [id] = useDrawer('marketplace-application-settings');

	const haveNotConnectedItems = useMemo(
		() => items.find((item) => !item.product),
		[items]
	);

	const handleFormSubmit = useCallback(
		async (values) => {
			setIsSaving(true);

			try {
				await v2Client.post(
					`/marketplace-applications/${id}/functions/save-mappings`,
					Object.values(formItems.current)
				);

				notification.success({
					message: 'Artikli su uspešno povezani',
				});
				onClose();
			} catch (error) {
				notification.error({
					message: 'Greška',
					description:
						'Došlo je do greške prilikom čuvanja povezanih artikala.',
				});
			} finally {
				setIsSaving(false);
			}
		},
		[formItems]
	);

	const renderItem = (item) => (
		<List.Item>
			<List.Item.Meta
				title={
					<>
						{item.name?.[0]?.value}
						<br />
						{form.getFieldValue([item.id, 'productId']) &&
							form.getFieldValue([item.id, 'productId']) !==
								item.product?.product?.id && <Tag color="orange">Nacrt</Tag>}
						{form.getFieldValue([item.id, 'productId']) &&
							form.getFieldValue([item.id, 'productId']) ===
								item.product?.product?.id && <Tag color="green">Povezan</Tag>}
						{!form.getFieldValue([item.id, 'productId']) && !item.product && (
							<Tag>Nije povezan</Tag>
						)}
					</>
				}
				description={item.description?.[0]?.value}
				avatar={<img src={item.image} width={100} />}
			/>
			<div className={styles.connectedProductWrapper}>
				<Row gutter={8}>
					<Col span={24}>
						<Form.Item
							name={[item.id, 'externalId']}
							hidden
							initialValue={item.id}
						>
							<Input readOnly />
						</Form.Item>
						<Form.Item
							name={[item.id, 'description']}
							hidden
							initialValue={
								item.product?.description || item.description?.[0]?.value
							}
						>
							<Input readOnly />
						</Form.Item>
						{form.getFieldValue([item.id, 'productId'])}
						<Form.Item
							name={[item.id, 'name']}
							hidden
							initialValue={item.product?.name || item.name?.[0]?.value}
						>
							<Input readOnly />
						</Form.Item>

						<Form.Item
							name={[item.id, 'productId']}
							hidden
							initialValue={item.product?.productId}
						>
							<Input readOnly />
						</Form.Item>
						<Form.Item
							label="Artikal"
							name={[item.id, 'productName']}
							initialValue={
								item.product?.product?.parent
									? `${item.product?.product?.parent?.name} ${item.product?.product?.name}`
									: item.product?.product?.name
							}
						>
							<Input
								readOnly
								suffix={
									form.getFieldValue([item.id, 'productId']) ? (
										<CloseOutlined
											onClick={() => {
												form.setFieldValue([item.id, 'productId'], null);
												form.setFieldValue([item.id, 'productName'], null);
											}}
										/>
									) : (
										<SearchOutlined />
									)
								}
								placeholder="Odaberite artikal"
								onClick={() => openSearchDrawer(item.id)}
							/>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item
							label="Prodajna količina"
							name={[item.id, 'saleQuantity']}
							initialValue={
								typeof item.product?.saleQuantity === 'number'
									? item.product?.saleQuantity
									: 1
							}
						>
							<InputNumber style={{ width: '100%' }} />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item
							label="Cena"
							name={[item.id, 'salePrice']}
							initialValue={
								typeof item.product?.salePrice === 'number'
									? item.product?.salePrice
									: item.baseprice?.amount
							}
						>
							<InputNumber style={{ width: '100%' }} />
						</Form.Item>
					</Col>
				</Row>
			</div>
		</List.Item>
	);

	return (
		<Drawer
			title="Povezivanje artikala"
			width={720}
			visible={visible}
			onClose={onClose}
			destroyOnClose={true}
			footerStyle={{ textAlign: 'right' }}
			footer={
				<>
					<Space className={pageStyles.leftButton}>
						<Button key="close" onClick={onClose}>
							Zatvori
						</Button>
					</Space>
					<Button
						type="primary"
						onClick={() => form.submit()}
						disabled={isSaving}
						loading={isSaving}
					>
						Sačuvaj
					</Button>
				</>
			}
		>
			<Form
				form={form}
				layout="vertical"
				onFinish={handleFormSubmit}
				onValuesChange={onValuesChange}
			>
				<Form.Item>
					<Alert
						type="info"
						message={
							<>
								{haveNotConnectedItems && (
									<>
										Pronađeni su artikli na platformi Wolt koji nisu povezani sa
										artiklima u aplikaciji. Za svaki artikal sa spiska, potrebno
										je odabrati odgovarajući artikal iz aplikacije.
									</>
								)}
								{!haveNotConnectedItems && (
									<>
										Svi artikli sa platforme Wolt su povezani sa artiklima u
										aplikaciji. Možete promeniti povezane artikle odabirom
										odgovarajućeg artikla iz aplikacije.
									</>
								)}
								<br />
								<br />
								<strong>Prodajna količina</strong> je količina koja se prodaje
								na platformi Wolt. Ukoliko je količina na platformi Wolt ista
								kao i u aplikaciji, unesite <strong>1</strong>.
								<br />
								<strong>Cena</strong> je cena koja se prikazuje na platformi
								Wolt, i polje se može ostaviti prazno ukoliko je cena ista kao i
								u aplikaciji.
							</>
						}
					/>
				</Form.Item>
				<List
					dataSource={items}
					itemLayout="vertical"
					renderItem={renderItem}
					pagination={{
						position: 'bottom',
						size: 'small',
					}}
				/>
			</Form>
			<ProductSearchDrawer />
		</Drawer>
	);
}

export default ConnectProductDrawer;
