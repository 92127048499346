// import { app } from '@tauri-apps/api';
import './wdyr';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { ConfigProvider, Spin } from 'antd';
import srRS from 'antd/lib/locale/sr_RS';
import axios from 'axios';
import { auto as followSystemColorScheme } from 'darkreader';
import FastClick from 'fastclick';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { Fragment } from 'react';
import { Provider as BusProvider } from 'react-bus';
import { Routes } from 'react-router';
import { BrowserRouter, Route } from 'react-router-dom';
import 'antd/dist/antd.less';
import 'moment/locale/sr';
// import '@ant-design/pro-layout/dist/layout.less';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import VSDC from './components/VSDC';
import 'array-flat-polyfill';
import 'polyfill-object.fromentries';
import config from './config.json';
import { APPLICATION_VERSION } from './constants/application';
import withAuth from './hocs/withAuth';
import Application from './modules/Application';
import Login from './modules/Login';
import Register from './modules/Register';
import stores from './stores/index.mobx';

import './App.less';

if (process.env.NODE_ENV === 'production') {
	Bugsnag.start({
		apiKey: '4f08ee5076e0cdfadd75a2ede14cfb10',
		plugins: [new BugsnagPluginReact()],
		appVersion: `${process.env.REACT_APP_APP_NAME}-${APPLICATION_VERSION}`,
	});
}
// window.less.modifyVars({
// 	'primary-color': '#7E89C8',
// });

FastClick.attach(document.body);

followSystemColorScheme(
	{
		brightness: 100,
		contrast: 100,
		sepia: 0,
	},
	{
		css: `
		.ant-checkbox-checked .ant-checkbox-inner::after {
			border-right-color: rgba(232, 230, 227, 0.85) !important;
			border-bottom-color: rgba(232, 230, 227, 0.85) !important;
		}

		.ant-radio-inner::after {
			background-color: rgba(232, 230, 227, 0.85) !important;
		}

		.ant-switch-handle::before {
			background-color: rgba(232, 230, 227, 0.85) !important;
		}

		.logo-dark img {
			filter: invert(1) !important;
		}

		#thermal-temporary *, #a4-temporary *, #email-temporary * {
			color: #000 !important;
			background-color: transparent !important;
		}

		#a4-temporary th {
			background: #eee !important;
		}

		#thermal-temporary, #a4-temporary, #email-temporary {
			color: #000 !important;
			background-color: #fff !important;
		}
	`,
		invert: [
			// '#printarea',
			// '#a4-temporary',
			// '#email-temporary',
			// '#thermal-temporary',
		],
		ignoreInlineStyle: [
			'.home-chart svg *',
			'.home-chart .g2-tooltip .g2-tooltip-marker',
		],
	}
);

// Enable once ant supports font in variables css
axios.defaults.baseURL = config.api;

moment.locale('sr');

function injectGA() {
	if (typeof window == 'undefined') {
		return;
	}

	window.dataLayer = window.dataLayer || [];
	function gtag() {
		dataLayer.push(arguments);
	}
	gtag('js', new Date());
	gtag('config', 'AW-10893059914');
}

// const presentation = navigator.presentation;
// const session = presentation.requestSession(
// 	'data:text/html;charset=utf-8,%3Chtml%3E%3Cbody%3Efoo%3C/body%3E%3C/html%3E'
// );

const ErrorBoundary =
	process.env.NODE_ENV === 'production'
		? Bugsnag.getPlugin('react').createErrorBoundary(React)
		: Fragment;

function App() {
	const {
		application: { isInitialized },
	} = stores;

	return (
		<ErrorBoundary>
			<ConfigProvider locale={srRS}>
				<BusProvider>
					{isInitialized && (
						<BrowserRouter>
							<QueryParamProvider adapter={ReactRouter6Adapter}>
								<Routes>
									<Route exact path="/" Component={withAuth(Login)} />
									<Route
										path="/register/:step?/:package?/:paymentTypes?"
										Component={withAuth(Register, false, true)}
									/>
									<Route
										path="/app/*"
										Component={withAuth(Application, true)}
									/>
								</Routes>
							</QueryParamProvider>
						</BrowserRouter>
					)}
					{!isInitialized && (
						<Spin
							spinning={true}
							style={{
								height: '100vh',
								width: '100vw',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						/>
					)}
					{process.env.NODE_ENV && process.env.NODE_ENV === 'production' && (
						<>
							<script
								async
								src="https://www.googletagmanager.com/gtag/js?id=AW-10893059914"
							></script>
							<script>{injectGA()}</script>
						</>
					)}
				</BusProvider>
				<VSDC />
			</ConfigProvider>
		</ErrorBoundary>
	);
}

export default observer(App);
