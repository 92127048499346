import { Button, Drawer, Form, Space, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
	addToDrawersRegistry,
	useDrawer,
} from '../../../../../../components/Page';
import StoreSelect from '../../../../../../components/StoreSelect';
import { Title } from '../../../../../../components/Title';
import stores from '../../../../../../stores/index.mobx';
import styles from '../../Marketplace.module.less';
import ApplicationSettingsEfaktura from './Applications/rs.storebuddy.efaktura';
import ApplicationSettingsWolt from './Applications/rs.storebuddy.wolt';
const ApplicationSettings = () => {
	const internalApplications = {
		'rs.storebuddy.efaktura': ApplicationSettingsEfaktura,
		'rs.storebuddy.wolt': ApplicationSettingsWolt,
	};
	const [applicationId, , close, visible] = useDrawer(
		'marketplace-application-settings'
	);

	const [currentStoreId, setCurrentStoreId] = useState(
		stores.stores.currentStoreId
	);

	const { marketplaceApplications } = stores;
	const { single } = marketplaceApplications;
	useEffect(() => {
		if (visible && applicationId) {
			marketplaceApplications.fetchSingle(applicationId);
		}
	}, [visible, applicationId]);

	const Component = internalApplications[single?.packageId];
	const componentRef = useRef<any>();

	const isFetching = useMemo(() => {
		return typeof single?.isFetching === 'boolean' ? single?.isFetching : true;
	}, [single?.isFetching]);

	const title = useMemo(() => {
		return isFetching ? undefined : (
			<>Podešavanja aplikacije {single?.name || ''}</>
		);
	}, [single?.isFetching, single?.name]);
	console.log(title, single?.isFetching);
	return (
		<Drawer
			visible={visible}
			onClose={close}
			placement="right"
			width={800}
			title={title}
			footer={
				<>
					<Space className={styles.leftButtons}>
						<Button onClick={close}>Zatvori</Button>
					</Space>
					<Space className={styles.rightButtons}>
						<Button
							type="primary"
							onClick={() => {
								componentRef?.current?.save();
							}}
						>
							Sačuvaj
						</Button>
					</Space>
				</>
			}
		>
			<Spin spinning={isFetching}>
				{single?.configurationPerStore && (
					<>
						<Title>Prodajno mesto</Title>
						<Form.Item
							labelCol={{ span: 24 }}
							label={<>Aplikacija se konfiguriše po prodajnom mestu</>}
						>
							<StoreSelect
								value={currentStoreId}
								onChange={setCurrentStoreId}
							/>
						</Form.Item>
					</>
				)}
				{single?.internal && Component ? (
					<Component ref={componentRef} storeId={currentStoreId} />
				) : null}
			</Spin>
		</Drawer>
	);
};

const ObservedApplicationSettings = observer(ApplicationSettings);

addToDrawersRegistry(
	'marketplace-application-settings',
	ObservedApplicationSettings
);

export default ObservedApplicationSettings;
