export const APPLICATION_VERSION =
	process.env.REACT_APP_APP_NAME === 'ibuddy' ? '1.3.2' : '2.4.2';
export const IB_NO = process.env.REACT_APP_APP_NAME === 'ibuddy' ? 1197 : 1190;
export const APPLICATION_NAME =
	process.env.REACT_APP_APP_NAME === 'ibuddy' ? 'I Buddy' : 'StoreBuddy';
export const POS_NO = `${IB_NO}/${APPLICATION_VERSION}`;
export const MINIMUM_CLIENT_VERSION =
	process.env.REACT_APP_APP_NAME === 'ibuddy' ? '1.0.0' : '2.0.0';
export const AVAILABLE_FEATURES: Record<string, string[]> = {
	lite: [],
	pos: [
		'store_management',
		'esdc',
		'variants',
		'users',
		'integrations',
		'documents',
	],
	ibuddy: [
		'store_management',
		'esdc',
		'variants',
		'users',
		'integrations',
		'documents',
	],
	posplus: [
		'store_management',
		'esdc',
		'variants',
		'users',
		'stock',
		'integrations',
		'documents',
	],
	erp: [
		'store_management',
		'esdc',
		'variants',
		'users',
		'stock',
		'integrations',
		'documents',
		'reports',
	],
};

export const PACKAGE_NAME = {
	lite: 'Lite',
	pos: 'POS',
	ibuddy: 'I Buddy',
	posplus: 'POS Plus',
	erp: 'ERP',
};
