import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {
	Button,
	Checkbox,
	Col,
	Form,
	Input,
	Row,
	Tooltip,
	Upload,
	message,
	Progress,
	Spin,
	notification,
	Typography,
	Radio,
	Grid,
	InputNumber,
	Select,
	Modal,
} from 'antd';
import cyr2lat from 'cyrillic-to-latin';
import diacritics from 'diacritics';
import gs1 from 'gs1-key';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';

import BankAccountsInput from '../../../../../components/BankAccountsInput';
import CurrencySelect from '../../../../../components/CurrencySelect';
import TaxRateSelect from '../../../../../components/TaxRateSelect';
import { Title } from '../../../../../components/Title';
import {
	APPLICATION_NAME,
	PACKAGE_NAME,
} from '../../../../../constants/application';
import { v2Client } from '../../../../../store/client';
import store from '../../../../../stores/index.mobx';
import { TaxExemptionInput } from '../../../Documents/OutgoingInvoices/Components/TaxExemptionInput';
import styles from './Company.module.less';
import FlatRateTaxChangeModal from './Modals/FlatRateTaxChangeModal';
import VATChangeModal from './Modals/VATChangeModal';

function Company() {
	const [form] = Form.useForm();

	const screens = Grid.useBreakpoint();

	const [isFormTouched, setIsFormTouched] = useState(false);
	const {
		company,
		kpos,
		application: { classifications, isFetchingClassifications },
	} = store;
	const { isFetching, update, isUpdating } = company;

	const [logoUploadState, setLogoUploadState] = useState({
		status: 'empty',
		percentage: 0,
		url: '',
	});

	const [vatChangeModal, setVatChangeModal] = useState({
		visible: false,
		newStatus: false,
		previousDefaultTaxLabel: '',
	});

	const [isFlatRateTaxModalVisible, setIsFlatRateTaxModalVisible] =
		useState(false);

	useEffect(() => {
		if (company) {
			// form.setFieldsValue(company);
			// setIsFormTouched(false);
			if (company.logoFile) {
				setLogoUploadState({
					status: 'done',
					url: company.logoFile.urls['256x256'],
					percentage: 100,
				});
			}
		}
	}, [company, company?.name, isFetching]);
	async function onSubmit() {
		try {
			const values = await form.validateFields();

			values.logoFileId =
				values.logo?.file?.response?.id || form.getFieldValue('logoFileId');

			values.taxPeriod ? Number(values.taxPeriod) : undefined;

			if (values.vat) {
				values.flatRateTax = false;
			}

			delete values.logo;
			delete values.tin;
			delete values.companyRegistrationNumber;
			values.bankAccounts?.forEach((account: any) => {
				delete account.part1;
				delete account.part2;
				delete account.part3;
				delete account.valid;
			});

			// if (!values.vat) {
			// 	values.defaultTaxRateLabel = null;
			// }

			const oldFlatRateTax = company.flatRateTax;

			await update(values);
			notification.success({
				message: 'Podešavanja su upešno sačuvana',
			});

			if (oldFlatRateTax !== values.flatRateTax && values.flatRateTax) {
				setIsFlatRateTaxModalVisible(true);
			}
			setIsFormTouched(false);
		} catch (error) {
			console.error(error);
			notification.error({
				message: 'Došlo je do greške prilikom čuvanja podešavanja',
			});
		}
	}

	const onValuesChange = useCallback((changedValues, allValues) => {
		console.log('changedValues', changedValues);
		setIsFormTouched(true);
		// setCurrentValues(allValues);
		if (typeof changedValues.vat === 'boolean') {
			setVatChangeModal({
				visible: true,
				newStatus: changedValues.vat,
				previousDefaultTaxLabel: allValues.defaultTaxRateLabel,
			});
		}
	}, []);
	// console.log('isFormTouched', isFormTouched, form.isFieldsTouched());
	return (
		<Spin spinning={isFetching || isUpdating}>
			<div className={styles.wrapper}>
				<Form
					layout="vertical"
					className={styles.form}
					initialValues={company}
					onFinish={onSubmit}
					form={form}
					onValuesChange={onValuesChange}
				>
					<div className="ant-descriptions-header">
						<div className="ant-descriptions-title">Osnovne informacije</div>
					</div>
					<Row gutter={8}>
						<Col span={18}>
							<Form.Item label="Naziv" name="name">
								<Input type="text" />
							</Form.Item>
							<Row gutter={8}>
								<Col span={12}>
									<Form.Item
										label={!screens.xs ? 'Poreski identifikacioni broj' : 'PIB'}
										name="tin"
									>
										<Input type="text" disabled />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										label="Matični broj"
										name="companyRegistrationNumber"
									>
										<Input type="text" disabled />
									</Form.Item>
								</Col>
							</Row>
						</Col>
						<Col span={6}>
							<Form.Item name="logoFileId" hidden>
								<Input />
							</Form.Item>
							<Form.Item name="logo" label="Logo" valuePropName="file">
								<Upload.Dragger
									showUploadList={false}
									headers={{
										'x-access-token':
											v2Client.defaults.headers.common['x-access-token'],
									}}
									action={v2Client.defaults.baseURL + '/files/companyLogo'}
									className={styles.upload}
									onChange={(info) => {
										// TODO: use deferred to wait for upload
										const { status } = info.file;
										if (status !== 'uploading') {
											setLogoUploadState({
												status,
												percentage: info.file.percent,
												url: '',
											});
										}
										if (status === 'done') {
											setIsFormTouched(true);
											setLogoUploadState({
												status,
												percentage: 100,
												url: info.file.response.urls['256x256'],
											});
											form.setFieldValue('logo', info);
										} else if (status === 'error') {
											message.error(`${info.file.name} file upload failed.`);
										}
									}}
									onDrop={(e) => {
										// console.log('Dropped files', e.dataTransfer.files);
									}}
								>
									{logoUploadState.status === 'empty' && (
										<>
											<p
												className={`ant-upload-drag-icon ${styles.uploadIcon}`}
											>
												<img src="/images/icons/folder.png" alt="Otpremanje" />
											</p>
											<p className="ant-upload-hint">
												{screens.xl
													? 'Prevucite logo ili kliknite ovde za otpremanje'
													: 'Otpremi logo'}
											</p>
										</>
									)}
									{logoUploadState.status === 'uploading' && (
										<>
											<Progress
												percent={logoUploadState.percentage}
												size="small"
												status="active"
											/>
										</>
									)}
									{logoUploadState.status === 'done' && (
										<>
											<img
												src={logoUploadState.url}
												alt="Logo"
												className={styles.logoPreview}
											/>
											<Button
												type="primary"
												size="small"
												className={styles.removeLogo}
											>
												<CloseOutlined />
											</Button>
										</>
									)}
								</Upload.Dragger>
								{logoUploadState.status === 'done' && (
									<Button
										type="primary"
										size="small"
										className={styles.removeLogo}
										onClick={() => {
											form.setFieldValue('logo', null);
											form.setFieldValue('logoFileId', null);
											setIsFormTouched(true);
											setLogoUploadState({
												status: 'empty',
												percentage: 0,
												url: '',
											});
										}}
									>
										<CloseOutlined />
									</Button>
								)}
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={8}>
						<Col span={24}>
							<Form.Item label="Adresa" name="address">
								<Input />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={8}>
						<Col sm={8} xs={24}>
							<Form.Item label="Poštanski broj" name="zip">
								<Input />
							</Form.Item>
						</Col>
						<Col sm={8} xs={24}>
							<Form.Item label="Grad" name="city">
								<Input />
							</Form.Item>
						</Col>
						<Col sm={8} xs={24}>
							<Form.Item
								label="GLN sedišta"
								name="gln"
								rules={[
									{
										validator: (_, value) => {
											if (!value || value.length === 0) {
												return Promise.resolve();
											}

											if (!gs1.validate(value)) {
												return Promise.reject();
											}
											return Promise.resolve();
										},
										message: 'GLN nije ispravan',
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={8}>
						<Col span={8}>
							<Form.Item label="Broj telefona" name="phone">
								<Input type="tel" />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item label="E-Mail" name="email">
								<Input type="email" />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item label="Web sajt" name="website">
								<Input type="url" />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={8}>
						<Col span={24}>
							<Form.Item label="Šifra delatnosti" name="classification">
								<Select
									loading={isFetchingClassifications}
									showSearch
									filterOption={(input, option) =>
										option.props.searchValue.indexOf(input.toLowerCase()) !==
											-1 ||
										diacritics
											.remove(option.props.searchValue.toLowerCase())
											.indexOf(input.toLowerCase()) !== -1
									}
								>
									{classifications.map((classification) => (
										<Select.Option
											key={classification.key}
											value={classification.key}
											searchValue={`${classification.key} -${cyr2lat(
												classification.value
											)}`}
										>
											<strong>{classification.key}</strong> -{' '}
											{cyr2lat(classification.value)}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
					</Row>

					<div className="ant-descriptions-header">
						<div className="ant-descriptions-title">Porez</div>
					</div>
					<Row gutter={8}>
						<Col xs={24}>
							<Form.Item name="vat" valuePropName="checked">
								<Checkbox>U sistemu PDV-a</Checkbox>
							</Form.Item>
						</Col>
					</Row>
					{!form.getFieldValue('vat') && (
						<Row gutter={8}>
							<Col xs={12}>
								<Form.Item
									name="flatRateTax"
									valuePropName="checked"
									initialValue={false}
								>
									<Checkbox>Paušalno oporezivan obveznik</Checkbox>
								</Form.Item>
							</Col>
						</Row>
					)}
					{form.getFieldValue('vat') && (
						<Row gutter={8}>
							<Col xs={12}>
								<Form.Item name="taxPeriod" label="Period podnošenja PDV-a">
									<Select>
										<Select.Option value={1}>Mesečno</Select.Option>
										<Select.Option value={3}>Kvartalno</Select.Option>
									</Select>
								</Form.Item>
							</Col>
						</Row>
					)}

					<Row gutter={8}>
						<Col span={12}>
							<Form.Item
								name="defaultTaxRateLabel"
								label={
									<>
										Podrazumevana poreska oznaka&nbsp;
										<Tooltip
											title={
												<span>
													Ova poreska oznaka će podrazumevano biti korišćena
													prilikom dodavanja novih proizvoda. I dalje ćete biti
													u mogućnosti da promenite poresku oznaku. Vodite
													računa da li imate prava da koristite odabranu poresku
													oznaku.
												</span>
											}
										>
											<InfoCircleOutlined />
										</Tooltip>
									</>
								}
							>
								<TaxRateSelect vat={form.getFieldValue('vat')} />
							</Form.Item>
						</Col>
						{form.getFieldValue('vat') && (
							<Col span={24}>
								<Form.Item
									name="taxFreeMode"
									label={<>PDV tretman za LNPDV i SNPDV obrasce</>}
									extra={
										<>
											Način na koji će se vršiti prodaja bez PDV-a uz obrasce
											LNPDV i SNPDV. Ukoliko je odabrana opcija{' '}
											<em>Naknadna refundacija PDV-a</em>, PDV će biti iskazan
											na fiskalnom računu, a ukoliko je odabrana opcija{' '}
											<em>Oslobađanje od PDV-a</em>, kupac će biti oslobođen
											PDV-a
										</>
									}
								>
									<Radio.Group>
										<Radio value={'refund'}>Naknadna refundacija PDV-a</Radio>
										<Radio value={'exempt'}>Oslobađanje od PDV-a</Radio>
									</Radio.Group>
								</Form.Item>
							</Col>
						)}
						<Col span={24}>
							<Form.Item
								name="defaultTaxExemption"
								label="Podrazumevani osnov za oslobađanje/izuzeće od PDV-a"
							>
								<TaxExemptionInput defaultPicker />
							</Form.Item>
						</Col>
					</Row>
					<Title>Dokumenti</Title>
					<Row gutter={8}>
						<Col xl={6} md={12} xs={24}>
							<Form.Item name="invoiceNumberingPrefix" label="Prefiks faktura">
								<Input />
							</Form.Item>
						</Col>
						<Col xl={6} md={12} xs={24}>
							<Form.Item
								name="advanceInvoiceNumberingPrefix"
								label="Prefiks avansnih računa"
							>
								<Input />
							</Form.Item>
						</Col>
						<Col xl={6} md={12} xs={24}>
							<Form.Item
								name="creditNoteNumberingPrefix"
								label="Prefiks knjižnih odobrenja"
							>
								<Input />
							</Form.Item>
						</Col>
						<Col xl={6} md={12} xs={24}>
							<Form.Item
								name="debitNoteNumberingPrefix"
								label="Prefiks knjižnih zaduženja"
							>
								<Input />
							</Form.Item>
						</Col>
						<Col md={17} sm={15} xs={12}>
							<Form.Item
								name="invoiceNumbering"
								label="Numeracija"
								initialValue="#/YYYY"
							>
								<Select>
									<Select.Option value="#/YYYY">
										Redni broj/Godina{' '}
										<Typography.Text type="secondary">
											(1/{moment().format('YYYY')})
										</Typography.Text>
									</Select.Option>
									<Select.Option value="#-YYYY">
										Redni broj-Godina{' '}
										<Typography.Text type="secondary">
											(1-{moment().format('YYYY')})
										</Typography.Text>
									</Select.Option>
									<Select.Option value="YYYY/#">
										Godina/Redni broj{' '}
										<Typography.Text type="secondary">
											({moment().format('YYYY')}/1)
										</Typography.Text>
									</Select.Option>
									<Select.Option value="YYYY-#">
										Godina-Redni broj{' '}
										<Typography.Text type="secondary">
											({moment().format('YYYY')}-1)
										</Typography.Text>
									</Select.Option>
									<Select.Option value="#/PID/YYYY">
										Redni broj/Šifra partnera/Godina{' '}
										<Typography.Text type="secondary">
											(1/00001/{moment().format('YYYY')})
										</Typography.Text>
									</Select.Option>
									<Select.Option value="#-PID-YYYY">
										Redni broj-Šifra partnera-Godina{' '}
										<Typography.Text type="secondary">
											(1-00001-{moment().format('YYYY')})
										</Typography.Text>
									</Select.Option>
									<Select.Option value="YYYY/PID/#">
										Godina/Šifra partnera/Redni broj{' '}
										<Typography.Text type="secondary">
											({moment().format('YYYY')}/00001/1)
										</Typography.Text>
									</Select.Option>
									<Select.Option value="YYYY-#-PID">
										Godina/Šifra partnera/Redni broj{' '}
										<Typography.Text type="secondary">
											({moment().format('YYYY')}-00001-1)
										</Typography.Text>
									</Select.Option>
									<Select.Option value="manual">Ručno</Select.Option>
								</Select>
							</Form.Item>
						</Col>
						<Col md={7} sm={9} xs={12}>
							<Form.Item
								name="invoiceNumberingStart"
								label="Početni broj dokumenta"
								initialValue={1}
							>
								<InputNumber min={1} style={{ width: '100%' }} />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								name="defaultDueDate"
								label="Podrazumevana valuta plaćanja"
							>
								<InputNumber
									min={0}
									style={{ width: '100%' }}
									addonAfter="dana"
								/>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								name="invoicePrintSignature"
								initialValue={false}
								valuePropName="checked"
							>
								<Checkbox>Štampaj polja za potpis na dokumentu</Checkbox>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								name="identityId"
								label="Identifikaciona oznaka"
								extra='Prema članu 9 Zakona o računovodstvu, računovodstvenom ispravom smatra se računovodstvena isprava koja je potpuna, istinita, računski tačna i prikazuje poslovnu promenu, i čiju verodostojnost odgovorno lice potvrđuje potpisom, odnosno drugom identifikacionom oznakom. Pojam "identifikaciona oznaka" predstavlja svaku oznaku koja jednoznačno određuje, odnosno upućuje na odgovorno lice, odnosno lice koje je ovlašćeno za izdavanje računovodstvene isprave. '
							>
								<Input />
							</Form.Item>
						</Col>
					</Row>
					<Title>Valute</Title>
					<Row gutter={8}>
						<Col span={24}>
							<Form.Item
								initialValue={false}
								name="allowCurrencyConversion"
								valuePropName="checked"
								extra="Ukoliko poslujete sa inostranim klijentima, možete uneti cene proizvoda u stranoj valuti, a one će prilikom prodaje biti konvertovane po srednjem kursu Narodne Banke Srbije"
							>
								<Checkbox>
									Dozvoli unos cene proizvoda u stranoj valuti
								</Checkbox>
							</Form.Item>
						</Col>
						{form.getFieldValue('allowCurrencyConversion') && (
							<Col span={24}>
								<Form.Item
									name="preferredCurrencies"
									label="Dostupne valute prilikom kreiranja proizvoda"
								>
									<CurrencySelect multiple placeholder="Odaberite valute" />
								</Form.Item>
							</Col>
						)}
					</Row>
					<div className="ant-descriptions-header">
						<div className="ant-descriptions-title">Bankovni računi</div>
					</div>
					<Form.Item name="bankAccounts">
						<BankAccountsInput showInvoiceSwitch />
					</Form.Item>
					<div className="ant-descriptions-header">
						<div className="ant-descriptions-title">Podešavanja</div>
					</div>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item
								name="searchProductsByDescription"
								valuePropName="checked"
							>
								<Checkbox>
									Pretraga po opisu proizvoda (može uticati na performanse)
								</Checkbox>
							</Form.Item>
						</Col>
					</Row>
					{process.env.REACT_APP_APP_NAME !== 'ibuddy' && (
						<>
							<div className="ant-descriptions-header">
								<div className="ant-descriptions-title">
									{APPLICATION_NAME} nalog
								</div>
							</div>
							<Form.Item label="Paket">
								{PACKAGE_NAME[company?.package]}{' '}
								<Typography.Text type="secondary">
									(Kontaktirajte nas za promenu paketa)
								</Typography.Text>
								{/* <Button type="link">Promenite paket</Button> */}
							</Form.Item>
						</>
					)}
					<Form.Item>
						<Button
							size="large"
							type="primary"
							htmlType="submit"
							disabled={isFetching || isUpdating || !isFormTouched}
							loading={isFetching || isUpdating}
						>
							Sačuvaj
						</Button>
					</Form.Item>
					<Form.Item name="changeTaxRateLabel" style={{ display: 'none' }}>
						<Input type="text" />
					</Form.Item>
				</Form>
				<VATChangeModal
					visible={vatChangeModal.visible}
					newStatus={vatChangeModal.newStatus}
					onCancel={() => {
						setVatChangeModal({ ...vatChangeModal, visible: false });
						const newValues = {
							vat: !vatChangeModal.newStatus,
							defaultTaxRateLabel: vatChangeModal.previousDefaultTaxLabel,
						};
						form.setFieldsValue(newValues);
					}}
					onOk={(newLabel) => {
						setVatChangeModal({ ...vatChangeModal, visible: false });
						const newValues = {
							vat: vatChangeModal.newStatus,
							defaultTaxRateLabel: newLabel,
							changeTaxRateLabel: newLabel,
						};
						form.setFieldsValue(newValues);
					}}
				/>
				<FlatRateTaxChangeModal
					visible={isFlatRateTaxModalVisible}
					onCancel={() => {
						setIsFlatRateTaxModalVisible(false);
					}}
					onOk={async (startDate) => {
						try {
							await kpos.backfill(startDate);
							setIsFlatRateTaxModalVisible(false);
						} catch (err) {
							notification.error({
								message: 'Greška',
								description: 'Došlo je do greške prilikom izrade KPO knjige',
							});
						}
					}}
				/>
			</div>
		</Spin>
	);
}

export default observer(Company);
