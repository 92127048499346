import {
	CopyOutlined,
	DeleteOutlined,
	EditOutlined,
	PlusOutlined,
} from '@ant-design/icons';
import {
	Button,
	Col,
	ColProps,
	Empty,
	Form,
	FormRule,
	Grid,
	List,
	Drawer,
	Row,
	Space,
	Table,
	TableColumnType,
	TableProps,
} from 'antd';
import {
	useCallback,
	createContext,
	useMemo,
	cloneElement,
	ReactNode,
	useEffect,
	useImperativeHandle,
	forwardRef,
	useState,
	isValidElement,
	Fragment,
	useRef,
} from 'react';
import { useLatest } from 'react-use';

import styles from './TableInput.module.less';

interface Column<T> {
	title: string;
	dataIndex: string;
	editable?: boolean;
	width?: number | void;
	component?:
		| ReactNode
		| ((
				record: T,
				index: number,
				handleEdit: (index: number, values: Record<string, any>) => void
		  ) => ReactNode);
	colProps?: ColProps;
	rules?: FormRule[];
	render?: (text: string, record: T, index: number) => ReactNode;
	valuePropName?: string;
}

interface FormField {
	label?: string;
	key?: string | string[];
	rules?: any[]; // TODO use antd rules
	component: ReactNode;
	span?: number;
	xs?: number;
	sm?: number;
	md?: number;
	lg?: number;
	xl?: number;
	xxl?: number;
	initialValue?: unknown;
	valuePropName?: string;
	rerenderOnChange?: boolean;
	hidden?: boolean;
}

export interface FormRow {
	key: string;
	label?: string;
	fields: FormField[];
}

export interface TableInputProps<T> {
	presetItems?: boolean;
	value?: any[T];
	onChange?: (value: any[T]) => void;
	isResponsive?: boolean;
	columns: Column<T>[];
	addButtonText: string;
	emptyText: string;
	iconPath: string;
	shouldShowDuplicateButton?: boolean;
	newRowValue?: T;
	tableProps?: TableProps<object>;
	id?: string;
	insertNewRow?: boolean;
	disallowLastRowDelete?: boolean;
	editInDrawer?: boolean;
	editDrawerTitle?: string | ((item: T) => string);
	formFields?:
		| FormRow[]
		| ((
				record?: any,
				form?: any,
				setFields?: (fields: any) => any
		  ) => FormRow[])
		| ReactNode;
}

const EditableContext = createContext(null);

function BaseTableInput<T>(
	{
		presetItems = false,
		value,
		isResponsive = false,
		onChange,
		columns: columnsProp,
		addButtonText,
		shouldShowDuplicateButton = false,
		iconPath,
		emptyText,
		newRowValue,
		tableProps,
		id,
		insertNewRow = false,
		disallowLastRowDelete = false,
		editInDrawer = false,
		editDrawerTitle,
		formFields: fields,
	}: TableInputProps<T>,
	ref
) {
	const screens = Grid.useBreakpoint();

	const currentValue = useLatest(value);

	const [editDrawerVisible, setEditDrawerVisible] = useState(false);
	const [editDrawerItem, setEditDrawerItem] = useState(null);
	const [editDrawerForm] = Form.useForm();

	const handleEditObject = useCallback(
		(index: number, fields: Record<string, string | number>) => {
			const newValue = (currentValue.current || []).slice();
			newValue[index] = {
				...currentValue.current[index],
				...fields,
			};

			Object.keys(fields).forEach((key) => {
				currentValue.current[index][key] = fields[key];
			});

			onChange(newValue);
		},
		[onChange]
	);

	const handleDelete = useCallback(
		(index) => {
			const newValue = currentValue.current.slice();
			newValue.splice(index, 1);

			onChange(newValue);
		},
		[onChange]
	);

	const handleAdd = useCallback(() => {
		onChange([
			...(currentValue.current || []),
			{
				...(newRowValue || {}),
			},
		]);
	}, [onChange, newRowValue]);

	const handleDuplicate = useCallback(
		(index) => {
			const newValue = currentValue.current.slice();
			newValue.splice(index, 0, currentValue.current[index]);
			onChange(newValue);
		},
		[onChange]
	);

	const handleEditInDrawer = useCallback(
		(index) => {
			setEditDrawerItem(currentValue.current[index]);
			setEditDrawerVisible(true);
		},
		[onChange]
	);

	useImperativeHandle(ref, () => ({
		handleEditObject,
		handleDelete,
		handleAdd,
		handleDuplicate,
	}));

	useEffect(() => {
		if (insertNewRow) handleAdd();
	}, [insertNewRow]);

	const EditableRow = useCallback(({ index, rowComponent, ...props }: any) => {
		const ComponentToUse = rowComponent || 'tr';
		return <ComponentToUse {...props} />;
	}, []);

	const EditableCell = useCallback((props: any) => {
		const {
			editable,
			children,
			record,
			dataIndex,
			rules,
			index,
			component,
			cellComponent,
			valuePropName,
			doNotWrap,
			value,
			...restProps
		} = props;
		const ComponentToUse = cellComponent || 'td';
		const childNode = editable ? (
			<Form.Item
				style={{ margin: 0 }}
				name={[id, index, dataIndex]}
				rules={rules}
				valuePropName={valuePropName}
			>
				{cloneElement(
					typeof component === 'function'
						? component(record, index, handleEditObject)
						: component,
					{ index }
				)}
			</Form.Item>
		) : (
			<div className={doNotWrap ? '' : styles.nonEditableCell}>{children}</div>
		);

		return (
			<ComponentToUse
				{...restProps}
				className={`${restProps.className} ${styles.editableCell}`}
			>
				{childNode}
			</ComponentToUse>
		);
	}, []);

	const components = useMemo(
		() => ({
			body: {
				row: EditableRow,
				cell: EditableCell,
			},
		}),
		[]
	);

	const columns = useMemo(
		() =>
			(!presetItems
				? [
						...columnsProp,
						{
							width:
								(shouldShowDuplicateButton ? 56 : 32) + (editInDrawer ? 24 : 0),
							fixed: 'right',
							doNotWrap: true,
							render: (text: string, record: T, index: number) => {
								return !screens.md && isResponsive ? (
									<Space>
										{editInDrawer && (
											<Button
												icon={<EditOutlined />}
												onClick={() => handleEditInDrawer(index)}
											>
												Izmeni
											</Button>
										)}
										{shouldShowDuplicateButton && (
											<Button
												icon={<CopyOutlined />}
												onClick={() => handleDuplicate(index)}
											>
												Dupliraj
											</Button>
										)}
										{!disallowLastRowDelete ||
										currentValue.current.length > 1 ? (
											<Button
												danger
												onClick={() => handleDelete(index)}
												icon={<DeleteOutlined />}
											>
												Obriši
											</Button>
										) : null}
									</Space>
								) : (
									<Button.Group style={{ marginTop: 5 }}>
										{editInDrawer && (
											<Button
												type="link"
												size="small"
												icon={<EditOutlined />}
												onClick={() => handleEditInDrawer(index)}
											></Button>
										)}
										{shouldShowDuplicateButton && (
											<Button
												type="link"
												size="small"
												icon={<CopyOutlined />}
												onClick={() => handleDuplicate(index)}
											></Button>
										)}
										{!disallowLastRowDelete ||
										currentValue.current.length > 1 ? (
											<Button
												type="link"
												danger
												size="small"
												onClick={() => handleDelete(index)}
												icon={<DeleteOutlined />}
											></Button>
										) : null}
									</Button.Group>
								);
							},
							colProps: {
								span: 24,
								className: styles.buttons,
							},
						},
				  ]
				: [
						...columnsProp,
						{
							width: editInDrawer ? 24 : 0,
							fixed: 'right',
							doNotWrap: true,
							render: (text: string, record: T, index: number) => {
								return !screens.md && isResponsive ? (
									<Space>
										{editInDrawer && (
											<Button
												icon={<EditOutlined />}
												onClick={() => handleEditInDrawer(index)}
											>
												Izmeni
											</Button>
										)}
									</Space>
								) : (
									<Button.Group style={{ marginTop: 5 }}>
										{editInDrawer && (
											<Button
												type="link"
												size="small"
												icon={<EditOutlined />}
												onClick={() => handleEditInDrawer(index)}
											></Button>
										)}
									</Button.Group>
								);
							},
							colProps: {
								span: 24,
								className: styles.buttons,
							},
						},
				  ]
			).map((column: Column<T>) => {
				return {
					...column,
					onCell: (record: T, index: number) => ({
						record,
						index,
						...column,
					}),
				};
			}),
		[screens, columnsProp]
	);

	const [formFields, setFormFields] = useState(null);

	const rerenderOnChangeFields = useMemo(() => {
		const derivedFormFields =
			typeof fields === 'function'
				? (fields as any)(editDrawerItem, editDrawerForm, setFields)
				: fields || [];
		return Array.isArray(derivedFormFields)
			? derivedFormFields
					.reduce((prev, curr) => {
						return [...prev, ...curr.fields];
					}, [])
					.filter((field) => field.rerenderOnChange)
					.map((field) => field.key)
			: [];
	}, [fields, editDrawerItem, editDrawerForm, formFields]);

	const setFields = useCallback(
		(changedFields = null) => {
			if (isValidElement(fields)) {
				setFormFields(fields);
				return;
			}
			if (
				!changedFields ||
				rerenderOnChangeFields.includes(changedFields?.[0]?.name?.[0])
			) {
				const derivedFormFields =
					typeof fields === 'function'
						? (fields as any)(editDrawerItem, editDrawerForm, setFields)
						: fields || [];
				setFormFields(derivedFormFields);
			}
		},
		[editDrawerItem, editDrawerForm, fields, rerenderOnChangeFields]
	);

	useEffect(() => {
		if (editDrawerItem) {
			editDrawerForm.setFieldsValue(editDrawerItem);
		}
		setFields();
	}, [editDrawerForm, setFields, editDrawerItem]);
	const focused = useRef(false);

	if ((value || []).length === 0) {
		return (
			<div className={styles.container}>
				<Empty
					image={<img src={iconPath} alt="" />}
					imageStyle={{
						height: 64,
					}}
					description={emptyText}
				>
					{!presetItems ? (
						<Button type="link" onClick={handleAdd} icon={<PlusOutlined />}>
							{addButtonText}
						</Button>
					) : null}
				</Empty>
			</div>
		);
	}

	return (
		<>
			<div
				className={styles.container}
				style={{ display: !screens.md && isResponsive ? 'block' : 'none' }}
			>
				<List
					footer={
						!presetItems ? (
							<div className={styles.footer}>
								<Button onClick={handleAdd} icon={<PlusOutlined />}>
									{addButtonText}
								</Button>
							</div>
						) : null
					}
					renderItem={(record: T, index: number) => {
						return (
							<EditableRow
								rowComponent="div"
								index={index}
								className={styles.item}
							>
								<Row gutter={[8, 16]}>
									{columns.map((column) => {
										return (
											<Col {...column.colProps}>
												<EditableCell
													{...column}
													label={column.title}
													cellComponent="div"
													record={record}
													index={index}
												>
													{column.render
														? column.render(
																record[column.dataIndex],
																record,
																index
														  )
														: record[column.dataIndex]}
												</EditableCell>
											</Col>
										);
									})}
								</Row>
							</EditableRow>
						);
					}}
					dataSource={value}
				/>
			</div>
			<div
				className={styles.container}
				style={{
					display:
						(screens.md && isResponsive) || !isResponsive ? 'block' : 'none',
				}}
			>
				<Table
					components={components}
					pagination={false}
					dataSource={value}
					size="small"
					footer={
						!presetItems
							? () => (
									<Button onClick={handleAdd} icon={<PlusOutlined />}>
										{addButtonText}
									</Button>
							  )
							: undefined
					}
					columns={columns as TableColumnType<object>[]}
					className={styles.table}
					onRow={(record: object, index: number): any => ({
						index,
					})}
					{...tableProps}
				/>

				{editInDrawer && (
					<Drawer
						title={
							typeof editDrawerTitle === 'function'
								? editDrawerTitle(editDrawerItem)
								: editDrawerTitle
						}
						width={800}
						visible={editDrawerVisible}
						destroyOnClose
					>
						<Form
							form={editDrawerForm}
							// onFinish={handleEditDrawerFinish}
							initialValues={editDrawerItem}
							preserve={false}
						>
							{isValidElement(formFields)
								? cloneElement(formFields, {
										editDrawerForm,
								  })
								: (formFields || []).map((row, rowIndex) => (
										<Fragment key={row.key}>
											{row.label && (
												<div className="ant-descriptions-header">
													<div className="ant-descriptions-title">
														{row.label}
													</div>
												</div>
											)}
											<Row gutter={8}>
												{row.fields.map((field, fieldIndex) => (
													<Col
														key={field.key}
														span={field.span}
														xs={field.xs}
														sm={field.sm}
														md={field.md}
														lg={field.lg}
														xl={field.xl}
														xxl={field.xxl}
														style={field.hidden && { display: 'none' }}
													>
														<Form.Item {...field} name={field.key}>
															{cloneElement(
																typeof field.component === 'function'
																	? field.component(editDrawerItem)
																	: field.component,
																{
																	ref: (ref) => {
																		setTimeout(() => {
																			if (
																				ref &&
																				ref.focus &&
																				rowIndex === 0 &&
																				fieldIndex === 0 &&
																				!focused.current
																			) {
																				ref.focus();
																				focused.current = true;
																			}
																		}, 100);
																	},
																}
															)}
														</Form.Item>
													</Col>
												))}
											</Row>
										</Fragment>
								  ))}
							<input type="submit" style={{ display: 'none' }} />
						</Form>
					</Drawer>
				)}
			</div>
		</>
	);
}

export const TableInput = forwardRef(BaseTableInput);
