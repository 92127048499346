import keyBy from 'lodash/keyBy';
import { computed, flow, observable } from 'mobx';

import { CreateStore } from './Crud.mobx';
import stores from './index.mobx';
import type { File as ImageFile } from './shared/File.mobx';
const { Store, Entity } = CreateStore({
	name: 'marketplaceApplication',
	paginated: false,
	persistFields: ['installedApplications', 'myApplications'],
});

class MarketplaceApplication extends Entity {
	@observable isInstalling = false;
	@observable name?: string;
	@observable packageId?: string;
	@observable description?: string;
	@observable version?: string;
	@observable scopes?: string[];
	@observable installationCount = 0;
	@observable publisher?: {
		name?: string;
	};
	@observable privacyPolicyUrl?: string;
	@observable termsOfServiceUrl?: string;
	@observable websiteUrl?: string;
	@observable applicationUrl?: string;
	@observable supportEmail?: string;
	@observable internal?: boolean;
	@observable configurationPerStore?: boolean;
	@observable images?: ImageFile[];
	@observable logoFile?: ImageFile;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}
	get isEditable() {
		return false;
	}

	get isDeletable() {
		return false;
	}

	@computed
	get isInstalled() {
		return stores.marketplaceApplications?.installedApplications.find(
			(app) => app.id === this.id
		);
	}

	@flow.bound
	*install() {
		this.isInstalling = true;
		try {
			const { data } = yield this.getClient().post(
				`marketplace-applications/${this.id}/install`
			);
			yield this.getParent().fetchInstalled();
			this.replace(data);
		} catch (e) {
			console.log(e);
		} finally {
			this.isInstalling = false;
		}
	}

	@flow.bound
	*uninstall() {
		this.isInstalling = true;
		try {
			const { data } = yield this.getClient().delete(
				`marketplace-applications/${this.id}/uninstall`
			);
			yield this.getParent().fetchInstalled();
			this.replace(data);
		} catch (e) {
			console.log(e);
		} finally {
			this.isInstalling = false;
		}
	}
}

class MarketplaceApplications extends Store<MarketplaceApplication> {
	@observable installedApplications: MarketplaceApplication[] = [];
	@observable myApplications: MarketplaceApplication[] = [];
	constructor(options) {
		super(MarketplaceApplication, options);
	}

	get isCreatable() {
		return false;
	}

	@computed
	get installedByApplicationId() {
		return keyBy(this.installedApplications, 'applicationId');
	}

	isInstalled(packageId: string) {
		return this.installedApplications.find(
			(application) => application.packageId === packageId
		);
	}

	@flow.bound
	*fetchInstalled() {
		this.isFetching = true;
		try {
			const { data } = yield this.getClient().get(
				'marketplace-applications/installed'
			);

			this.installedApplications = data.map(
				(application) => new MarketplaceApplication(application, this)
			);
		} catch (e) {
			console.log(e);
		} finally {
			this.isFetching = false;
		}
	}
	@flow.bound
	*fetchMine() {
		this.isFetching = true;
		try {
			const { data } = yield this.getClient().get(
				'marketplace-applications/my-applications'
			);

			this.myApplications = data.map(
				(application) => new MarketplaceApplication(application, this)
			);
		} catch (e) {
			console.log(e);
		} finally {
			this.isFetching = false;
		}
	}

	async afterAuth(authenticated: boolean) {
		if (authenticated) {
			await this.fetchInstalled();
			await this.fetchMine();
		}
	}
}

export { MarketplaceApplications, MarketplaceApplication };
