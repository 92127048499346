import Bugsnag from '@bugsnag/browser';
import { notification, Progress } from 'antd';
// import html2pdf from 'html2pdf.js/src';
import reverse from 'lodash/reverse';
import round from 'lodash/round';
import { action, computed, flow, observable } from 'mobx';
import type { Moment } from 'moment';
import moment from 'moment';
import printjs from 'print-js';
import Deferred from 'promise-deferred';
import { createRoot } from 'react-dom/client';

import { POS_NO } from '../constants/application';
import { ERROR_CONFLICT_DUPLICATE_RECEIPT } from '../constants/errors';
import {
	INVOICE_TYPE_MAP,
	PAYMENT_TYPE_MAP,
	PaymentType,
	InvoiceType,
	TRANSACTION_TYPE_MAP,
	TransactionType,
	InvoiceTypeAPI,
	PaymentTypeAPI,
	TransactionTypeAPI,
	INVOICE_TYPE_FROM_STRING,
} from '../constants/journal';
import { A4Journal } from '../lib/a4Journal';
import numberFormat from '../lib/numberFormat';
import { SocketError } from '../lib/socketError';
import { ThermalJournal } from '../lib/thermalJournal';
import { CreateStore } from './Crud.mobx';
import stores from './index.mobx';
import { LocalSale, LocalSales } from './LocalSale.mobx';
import { Product, Products } from './Product.mobx';
import { Store } from './Store.mobx';
import { ArrayTypeTransformer } from './transformers/ArrayType';
import { MomentTransformer } from './transformers/Moment';
import { ReferenceTransformer } from './transformers/Reference';
import { User } from './User.mobx';

let deferred = null;
let deferredCreateReceipt = null;

const { Store: CrudStore, Entity } = CreateStore({
	name: 'receipt',
	paginated: true,
	persistFields: ['failedSyncing'],
});

export interface DraftReceiptPayment {
	paymentType: PaymentTypeAPI;
	amount: number;
}

export interface DraftReceiptItem {
	sku: number;
	quantity: number;
	unitPrice?: number;
}

export interface DraftReceiptDelivery {
	thermalPrinter?: boolean;
	a4Printer?: boolean;
	email?: string;
	pdf?: boolean;
	html?: boolean;
}

export interface CreateDraftReceiptRequest {
	invoiceType: InvoiceTypeAPI;
	dateAndTimeOfIssue?: string;
	items: DraftReceiptItem[];
}

export interface FinalizeDraftReceiptRequest {
	payment?: DraftReceiptPayment[];
	receiptDelivery?: DraftReceiptDelivery;
	buyerId?: string;
	buyerCostCenterId?: string;
}

export type ReceiptPayment = {
	paymentType: PaymentTypeAPI;
	amount: number;
};

export type SyncFailItem = {
	url: string;
	data: any;
};

export type TaxItem = {
	label: string;
	categoryName: string;
	rate: number;
	amount: number;
};

export type SecureElement = {
	uid: string;
	tin: string;
	businessName: string;
	locationName: string;
	address: string;
	district: string;
};

export type AdvanceData = {
	advancePayments: number;
};

let retryInterval = null;

export class ReceiptItem extends Entity {
	@observable productId?: string;
	@observable name?: string;
	@observable unitPrice?: number;
	@observable totalAmount?: number;
	@observable quantity?: number;
	@observable taxLabels?: string[];
	@observable unit?: string;
	@observable sku?: number;
	@observable isPieceUnitOfMeasure?: boolean;
	@observable gtin?: string;
	@observable discount?: number;

	@ReferenceTransformer('product', 'productId')
	product?: Product;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}
}

class Receipt extends Entity {
	@observable totalAmount?: number;
	@observable payment?: ReceiptPayment[] = [];
	@observable invoiceType?: InvoiceTypeAPI;
	@observable transactionType?: TransactionTypeAPI;
	@observable invoiceNumber?: string;
	@observable buyerId?: string;
	@observable buyerCostCenterId?: string;
	@observable posNumber?: string;
	@observable referentDocumentNumber?: string;
	@observable verificationUrl?: string;
	@observable taxItems?: TaxItem[] = [];
	@observable storeId?: string;
	@observable cashierId?: string;
	@observable secureElement?: SecureElement;
	@observable taxTotal?: number;
	@observable paymentChange?: number;
	@observable paymentTotal?: number;
	@observable invoiceCounter?: string;
	@observable advanceData?: AdvanceData;
	@observable void? = false;
	@observable voids? = false;
	@observable sdcRequest?: any;
	@observable sdcResponse?: any;
	@observable additionalText?: string;
	@observable unknownAmountAdvance?: boolean = false;

	@ReferenceTransformer('store', 'storeId')
	store?: Store;
	@ReferenceTransformer('user', 'cashierId')
	cashier?: User;

	@MomentTransformer
	posTime?: Moment;
	@MomentTransformer
	referentDocumentDT?: Moment;
	@MomentTransformer
	sdcTime?: Moment;

	@ArrayTypeTransformer(Receipt)
	@observable
	connectedReceipts?: Receipt[];

	@ArrayTypeTransformer(ReceiptItem)
	@observable
	receiptItems: ReceiptItem[] = [];

	@ArrayTypeTransformer(ReceiptItem)
	@observable
	advanceItems?: ReceiptItem[] = [];

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}

	get isEditable() {
		return false;
	}

	get isDeletable() {
		return false;
	}

	@computed
	get hasSignatureLine() {
		return (
			this.invoiceType === INVOICE_TYPE_MAP[InvoiceType.COPY] &&
			this.transactionType === TRANSACTION_TYPE_MAP[TransactionType.REFUND] &&
			this.payment.some(
				(payment) => payment.paymentType === PAYMENT_TYPE_MAP[PaymentType.CASH]
			) &&
			!this.connectedReceipts.find(
				(receipt) => receipt.invoiceNumber === this.referentDocumentNumber
			)?.voids
		);
	}
	@computed
	get showAdvanceSum() {
		return (
			this.invoiceType === INVOICE_TYPE_MAP[InvoiceType.NORMAL] &&
			this.transactionType === TRANSACTION_TYPE_MAP[TransactionType.SALE] &&
			this.connectedReceipts?.some(
				(receipt) =>
					receipt.invoiceType === INVOICE_TYPE_MAP[InvoiceType.ADVANCE]
			)
		);
	}

	@computed
	get advancePaymentsSum() {
		return this.connectedReceipts
			?.filter(
				(receipt) =>
					receipt.invoiceType === INVOICE_TYPE_MAP[InvoiceType.ADVANCE] &&
					moment(receipt.sdcTime).isBefore(this.sdcTime) &&
					receipt.invoiceNumber !== this.referentDocumentNumber
			)
			.reduce((acc, cur) => {
				const paymentTotal =
					cur.transactionType === TRANSACTION_TYPE_MAP[TransactionType.SALE]
						? cur.paymentTotal
						: -cur.paymentTotal;
				return acc + paymentTotal - cur.paymentChange;
			}, 0);
	}

	@computed
	get lastAdvance() {
		const lastAdvance = reverse([...this.connectedReceipts])
			?.filter(
				(receipt) =>
					receipt.invoiceType === INVOICE_TYPE_MAP[InvoiceType.ADVANCE] &&
					moment(receipt.sdcTime).isBefore(this.sdcTime)
			)
			.find(
				(receipt) =>
					receipt.transactionType ===
						TRANSACTION_TYPE_MAP[TransactionType.SALE] &&
					receipt.invoiceType === INVOICE_TYPE_MAP[InvoiceType.ADVANCE]
			);

		const closed = reverse([...this.connectedReceipts])?.find(
			(receipt) =>
				receipt.transactionType ===
					TRANSACTION_TYPE_MAP[TransactionType.SALE] &&
				receipt.invoiceType === INVOICE_TYPE_MAP[InvoiceType.NORMAL]
		);

		if (
			closed &&
			!closed.void &&
			this.invoiceType === INVOICE_TYPE_MAP[InvoiceType.NORMAL] &&
			this.transactionType === TRANSACTION_TYPE_MAP[TransactionType.SALE]
		) {
			return lastAdvance;
		}
	}
}

class Receipts extends CrudStore<Receipt> {
	@observable failedSyncing: SyncFailItem[] = [];

	constructor() {
		super(Receipt);
	}

	get isCreatable() {
		return false;
	}

	@action.bound
	addToFailedQueue(url: string, data: any) {
		this.failedSyncing.push({ url, data });
	}

	@action.bound
	removeFailed(failedReceipt) {
		this.failedSyncing = this.failedSyncing.filter(
			(failed) => failed !== failedReceipt
		);
	}

	@flow.bound
	*getByInvoiceNumber(invoiceNumber: string) {
		const response = yield this.getClient().get(
			`/receipts/by-invoice-number/${invoiceNumber}`
		);
		return new Receipt(response.data, this);
	}

	@flow.bound
	*retryFailed() {
		clearInterval(retryInterval);
		retryInterval = null;
		const count = this.failedSyncing.length;
		let current = 0;
		let errorCount = 0;
		notification.info({
			key: 'receiptSync',
			message: 'Sinhronizacija računa',
			description: (
				<span>
					<Progress percent={0} status="active" />
				</span>
			),
			duration: 0,
		});
		yield this.failedSyncing.reduce(async (previousPromise, receipt) => {
			await previousPromise;
			try {
				const { url, data } = receipt;
				await this.getClient().post(url, data);

				this.removeFailed(receipt);
			} catch (apiError) {
				if (
					apiError.response?.data?.errorCode ===
					ERROR_CONFLICT_DUPLICATE_RECEIPT
				) {
					this.removeFailed(receipt);
				} else {
					errorCount += 1;
				}
			}
			current += 1;
			notification.info({
				key: 'receiptSync',
				message: 'Sinhronizacija računa',
				description: (
					<span>
						<Progress percent={(current / count) * 100} status="active" />
					</span>
				),
				duration: 0,
			});
		}, Promise.resolve());

		if (this.failedSyncing.length === 0) {
			notification.success({
				key: 'receiptSync',
				message: 'Sinhronizacija uspešna',
				description: 'Računi su uspešno sinhronozovani sa serverom.',
				duration: 10,
			});
		} else {
			retryInterval = setInterval(() => {
				this.retryFailed();
			}, 60000);

			if (errorCount > 0) {
				notification.warning({
					key: 'receiptSync',
					message: 'Sinhronizacija neuspešna',
					description: `Sinhronizacija nije potpuno završena. Broj grešaka: ${errorCount} `,
					duration: 0,
				});
			}
		}
	}

	@flow.bound
	*saveWithFailHandler(url: string, data: any) {
		try {
			if (deferred) {
				yield deferred.promise;
			}
			deferred = new Deferred();
			yield this.getClient().post(url, data);
			deferred.resolve();
			deferred = null;
			if (window.location.pathname === '/app/receipts/list') {
				if (this.single) {
					this.fetchSingle(this.single.id);
				}
				this.fetchAll(
					this.pagination?.limit,
					this.pagination?.offset,
					this.pagination?.filters
				);
			}
		} catch (e) {
			if (e.response?.data?.errorCode !== ERROR_CONFLICT_DUPLICATE_RECEIPT) {
				this.addToFailedQueue(url, data);
			}

			if (this.failedSyncing.length > 0) {
				if (!retryInterval) {
					retryInterval = setInterval(() => {
						this.retryFailed();
					}, 60000);
				}
			}

			if (deferred) {
				deferred.resolve();
				deferred = null;
			}
		}
	}

	@flow.bound
	*sendEmail(email: string, data: string, invoiceNumber: string) {
		yield this.saveWithFailHandler(`/receipts/send-email`, {
			email,
			data,
			invoiceNumber,
			verificationUrls: [],
		});
	}
	@flow.bound
	*download(data: string, invoiceNumber: string) {
		const response = yield this.getClient().post(`/receipts/download`, {
			data,
			invoiceNumber,
		});
		if (window.electron) {
			const element = document.createElement('a');

			element.href = response.data.url;
			element.download = `${invoiceNumber}.pdf`;
			element.click();
		} else {
			window.open(response.data.url, '_blank');
		}
	}

	@flow.bound
	*createInvoice(
		invoiceType: InvoiceType,
		transactionType: TransactionType,
		payment: Record<string, number | string | Moment | void>,
		output: {
			thermal: boolean;
			a4: boolean;
			email: string | void;
			pdf: boolean;
			html: boolean;
		},
		items: any[],
		includeSignature = false,
		advanceItems = [],
		lastAdvanceSale?: any
	) {
		const sdc = stores.sdc;
		const user = stores.users.authenticatedUser;
		const requestPayment = [
			...(payment.cash > 0
				? [{ amount: payment.cash, paymentType: PaymentType.CASH }]
				: []),
			...(payment.card > 0
				? [{ amount: payment.card, paymentType: PaymentType.CARD }]
				: []),
			...(payment.check > 0
				? [{ amount: payment.check, paymentType: PaymentType.CHECK }]
				: []),
			...(payment.wiretransfer > 0
				? [
						{
							amount: payment.wiretransfer,
							paymentType: PaymentType.WIRE_TRANSFER,
						},
				  ]
				: []),
			...(payment.mobilemoney > 0
				? [
						{
							amount: payment.mobilemoney,
							paymentType: PaymentType.MOBILE_MONEY,
						},
				  ]
				: []),
			...(payment.voucher > 0
				? [{ amount: payment.voucher, paymentType: PaymentType.VOUCHER }]
				: []),
			...(payment.other > 0
				? [{ amount: payment.other, paymentType: PaymentType.OTHER }]
				: []),
		];
		const invoiceRequest = {
			invoiceType,
			transactionType,
			payment:
				requestPayment.length > 0
					? requestPayment
					: [
							{
								amount: 0,
								paymentType: PaymentType.CASH,
							},
					  ],
			paymentChange: payment.change || 0,
			cashier: user.fullName,
			invoiceNumber: POS_NO,
			buyerId: payment.buyerId ? payment.buyerId : undefined,
			buyerCostCenterId: payment.buyerCostCenterId
				? payment.buyerCostCenterId
				: undefined,
			referentDocumentNumber: payment.referentDocumentNumber,
			referentDocumentDT: payment.referentDocumentDT,
			dateAndTimeOfIssue: payment.posTime,
			items: items.map((item) => ({
				id: item.variant ? item.variant.id : item.product.id,
				gtin: (item.product.ean || '').trim() !== '' ? item.product.ean : null,
				name: `${item.product.name}${
					item.variant?.variantName ? ` ${item.variant?.variantName}` : ''
				}`,
				unit: item.variant
					? item.variant.saleUnit?.label
					: item.product?.saleUnit?.label || '',
				isPieceUnitOfMeasure: item.variant
					? item.variant.saleUnit?.isPieceUnitOfMeasure
					: item.product?.saleUnit?.isPieceUnitOfMeasure,
				sku: item.variant ? item.variant.sku : item.product.sku,
				quantity: round(item.quantity, 3),
				unitPrice: round(item.finalPrice, 2),
				labels: item.labels || item.product.taxRateLabels,
				totalAmount: round(
					round(item.quantity, 3) * round(item.finalPrice, 2),
					2
				),
				discount: item.discount,
			})),
			options: {
				omitQRCodeGen: '0',
				omitTextualRepresentation: '0',
			},
		};
		let receiptData;
		let invoiceResponse;
		let saved = false;
		let sent = false;
		try {
			if (deferredCreateReceipt) {
				yield deferredCreateReceipt.promise;
			}
			deferredCreateReceipt = new Deferred();
			invoiceResponse = yield sdc.createInvoice(invoiceRequest);

			receiptData = {
				tin: invoiceResponse.tin,
				businessName: invoiceResponse.businessName,
				locationName: invoiceResponse.locationName,
				address: invoiceResponse.address,
				district: invoiceResponse.district,
				totalAmount: invoiceResponse.totalAmount,
				payment: invoiceRequest.payment.map((payment) => ({
					...payment,
					amount:
						payment.paymentType === PaymentType.CASH
							? Number(payment.amount) + Number(invoiceRequest.paymentChange)
							: Number(payment.amount),
					paymentType: PAYMENT_TYPE_MAP[payment.paymentType],
				})),
				paymentChange: Number(invoiceRequest.paymentChange),
				invoiceType: INVOICE_TYPE_MAP[invoiceRequest.invoiceType],
				transactionType: TRANSACTION_TYPE_MAP[invoiceRequest.transactionType],
				invoiceNumber: invoiceResponse.invoiceNumber,
				buyerId: invoiceRequest.buyerId,
				buyerCostCenterId: invoiceRequest.buyerCostCenterId,
				referentDocumentNumber: invoiceRequest.referentDocumentNumber,
				referentDocumentDT: invoiceRequest.referentDocumentDT,
				items: invoiceRequest.items,
				additionalText: payment.additionalText,
				unknownAmountAdvance: payment.unknownAmountAdvance,

				advanceItems: advanceItems
					? advanceItems.map((item) => ({
							id: item.variant ? item.variant.id : item.product.id,
							gtin:
								(item.product.ean || '').trim() !== ''
									? item.product.ean
									: null,
							name: `${item.product.name}${
								item.variant?.variantName ? ` ${item.variant?.variantName}` : ''
							}`,
							unit: item.variant
								? item.variant.saleUnit?.label
								: item.product?.saleUnit?.label || '',
							isPieceUnitOfMeasure: item.variant
								? item.variant.saleUnit?.isPieceUnitOfMeasure
								: item.product?.saleUnit?.isPieceUnitOfMeasure,
							sku: item.variant ? item.variant.sku : item.product.sku,
							quantity: round(item.quantity, 3),
							unitPrice: round(item.finalPrice, 2),
							labels: item.labels || item.product.taxRateLabels,
							totalAmount: round(
								round(item.quantity, 3) * round(item.finalPrice, 2),
								2
							),
					  }))
					: null,
				posNumber: invoiceRequest.invoiceNumber,
				posTime: invoiceRequest.dateAndTimeOfIssue,
				sdcTime: invoiceResponse.sdcDateTime,
				verificationUrl: invoiceResponse.verificationUrl,
				taxItems: invoiceResponse.taxItems,
				invoiceCounter: invoiceResponse.invoiceCounter,
				storeId: stores.stores.currentStoreId,
				...(typeof payment.advancePayments !== 'undefined'
					? { advanceData: { advancePayments: payment.advancePayments } }
					: {}),
				sdcRequest: invoiceRequest,
				sdcResponse: invoiceResponse,
				// emails: output.email ? [output.email] : [],
				// receiptHTML: html,
			};

			this.saveWithFailHandler(`/receipts`, receiptData);
			saved = true;
			const a4Journal = new A4Journal(
				invoiceRequest,
				invoiceResponse,
				includeSignature,
				payment.advancePayments,
				advanceItems,
				lastAdvanceSale,
				payment.additionalText,
				payment.unknownAmountAdvance
			);

			const element = document.createElement('div');

			const root = createRoot(element);
			root.render(a4Journal.getComponent());

			yield a4Journal.getRenderDeferred();

			document.querySelector('#a4-temporary').innerHTML = element.innerHTML;
			const html = element.innerHTML;

			if (output.a4) {
				setTimeout(() => {
					printjs({
						printable: 'a4-temporary',
						type: 'html',
						targetStyles: ['*'],
						font_size: '',
					});
				});
			}

			if (output.thermal) {
				setTimeout(() => {
					const thermalJournal = new ThermalJournal(
						invoiceRequest,
						invoiceResponse,
						includeSignature,
						payment.advancePayments,
						advanceItems,
						lastAdvanceSale,
						payment.additionalText,
						payment.unknownAmountAdvance
					);
					thermalJournal.print();
				});
			}

			if (output.email) {
				this.saveWithFailHandler(`/receipts/send-email`, {
					email: output.email,
					data: html,
					invoiceNumber: invoiceResponse.invoiceNumber,
					verificationUrls: [invoiceResponse.verificationUrl],
				});
				sent = true;
			}

			// const pdf = output.pdf
			// 	? yield html2pdf()
			// 			.set({
			// 				margin: 1,
			// 				filename: `${invoiceResponse.invoiceNumber}.pdf`,
			// 				pageBreak: {
			// 					mode: 'css',
			// 					after: ['.page'],
			// 				},
			// 				html2canvas: { scale: 2 },
			// 				jsPDF: { unit: 'cm', format: 'a4', orientation: 'portrait' },
			// 			})
			// 			.from(html)
			// 			.toPdf()
			// 			.output('datauristring')
			// 	: null;

			deferredCreateReceipt.resolve();
			deferredCreateReceipt = null;

			notification.success({
				message: `Račun ${invoiceResponse.invoiceNumber} je uspešno izdat`,
				description: payment.change
					? `Izvršite povraćaj u iznosu od ${numberFormat(
							payment.change,
							true,
							2,
							true
					  )}`
					: '',
				duration: 10,
			});

			try {
				root.unmount();
			} catch (unmountError) {
				Bugsnag.notify(unmountError);
			}

			return {
				invoice: {
					...invoiceResponse,
					// ...(output.pdf ? { pdf } : {}),
					...(output.html ? { html: html } : {}),
				},
			};
		} catch (e) {
			if (deferredCreateReceipt) {
				deferredCreateReceipt.reject();
				deferredCreateReceipt = null;
			}
			if (invoiceResponse) {
				let message = '';
				let description = '';
				if ((output.a4 || output.thermal) && output.email) {
					message = `Račun ${invoiceResponse.invoiceNumber} je izdat, ali je došlo do greške prilikom štampanja ili slanja na e-mail`;
					if (saved && sent) {
						description = `Aplikacija će pokušati da sačuva i ponovo pošalje račun.`;
					} else if (saved && !sent) {
						description = `Aplikacija će pokušati da sačuva račun, ali ćete morati ručno da pošaljete račun na e-mail.`;
					} else {
						description = `Aplikacija zbog nepredviđene greške ne može sačuvati račun.`;
					}
				} else if (output.a4 || output.thermal) {
					message = `Račun ${invoiceResponse.invoiceNumber} je izdat, ali je došlo do greške prilikom štampanja.`;
					if (saved) {
						description = `Aplikacija će pokušati da sačuva račun.`;
					} else {
						description = `Aplikacija zbog nepredviđene greške ne može sačuvati račun.`;
					}
				} else if (output.email) {
					message = `Račun ${invoiceResponse.invoiceNumber} je izdat, ali je došlo do greške prilikom slanja na e-mail`;
					if (saved && sent) {
						description = `Aplikacija će pokušati da sačuva i ponovo pošalje račun.`;
					} else if (saved && !sent) {
						description = `Aplikacija će pokušati da sačuva račun, ali ćete morati ručno da pošaljete račun na e-mail.`;
					} else {
						description = `Aplikacija zbog nepredviđene greške ne može sačuvati račun.`;
					}
				} else {
					message = `Račun ${invoiceResponse.invoiceNumber} je izdat, ali je došlo do greške prilikom čuvanja računa`;
					if (saved) {
						description = `Aplikacija će pokušati sačuva račun.`;
					} else {
						description = `Aplikacija zbog nepredviđene greške ne može sačuvati račun.`;
					}
				}

				description += ` Obavešteni smo o grešci i radimo na njenom ispravljanju.`;
				notification.warning({
					message: message,
					description: (
						<>
							{description}
							{payment.change > 0 && (
								<>
									<br />
									<br />
								</>
							)}
							{payment.change
								? `Izvršite povraćaj u iznosu od ${numberFormat(
										payment.change,
										true,
										2,
										true
								  )}`
								: ''}
						</>
					),
					duration: 10,
				});
				console.error(e);
				Bugsnag.addMetadata('receipt', {
					invoiceRequest: JSON.stringify(invoiceRequest),
					invoiceResponse: JSON.stringify(invoiceResponse),
					receiptData: JSON.stringify(receiptData),
				});
				Bugsnag.notify(e);
				Bugsnag.clearMetadata('receipt');
				return {
					invoice: {
						...(invoiceResponse || {}),
					},
				};
			}
		}
	}

	@flow.bound
	*fiscalize(
		localSale: LocalSale,
		buyerId: string | void,
		buyerCostCenterId: string | void,
		output: {
			a4: boolean;
			email: string | void;
			thermal: boolean;
			pdf: boolean;
			html: boolean;
		}
	) {
		const { total, payment, invoiceType } = localSale;

		const cashSum = round(
			payment.reduce((sum, payment) => {
				if (payment.paymentType === 'cash') {
					return sum + payment.amount;
				}
				return sum;
			}, 0),
			2
		);
		const otherPaymentMethodsSum = round(
			payment.reduce((sum, payment) => {
				if (payment.paymentType !== 'cash') {
					return sum + payment.amount;
				}
				return sum;
			}, 0),
			2
		);

		const totalSum = round(cashSum + otherPaymentMethodsSum, 2);

		if (otherPaymentMethodsSum > total) {
			throw new Error(
				'ERROR_BAD_REQUEST_SUM_NON_CASH_PAYMENT_METHODS_GREATER_THAN_TOTAL'
			);
		}

		if (invoiceType !== 'advance') {
			if (totalSum < total) {
				throw new Error(
					'ERROR_BAD_REQUEST_SUM_PAYMENT_METHODS_LESS_THAN_TOTAL'
				);
			}
		}

		const change = totalSum > total ? round(totalSum - total, 2) : 0;

		const paidCash = round(cashSum - change, 2);

		const mappedPayments: Record<string, number> = payment.reduce(
			(payments, payment) => {
				payments[payment.paymentType] = payments[payment.paymentType] || 0;
				payments[payment.paymentType] += payment.amount;
				return payments;
			},
			{}
		);

		if (paidCash > 0) {
			mappedPayments.cash = paidCash;
		}

		if (change > 0) {
			mappedPayments.change = change;
		}

		// TODO: advance payment
		const { invoice } = yield this.createInvoice(
			INVOICE_TYPE_FROM_STRING[localSale.invoiceType],
			TransactionType.SALE,
			{
				...mappedPayments,
				buyerId,
				buyerCostCenterId,
			},
			output,
			localSale.itemsAsArray,
			false
		);

		return invoice;
	}

	@action.bound
	apiAddItem(draftId: string, item: DraftReceiptItem) {
		const localSaleStore = stores.localSales as LocalSales;
		const productStore = stores.products as Products;

		const localSale = localSaleStore.byUniqueId[draftId];

		if (!localSale) {
			throw new Error('ERROR_NOT_FOUND_DRAFT_RECEIPT_NOT_FOUND');
		}

		const product = productStore.bySku[item.sku];
		if (!product) {
			throw new SocketError('ERROR_NOT_FOUND_PRODUCT_SKU', {
				sku: item.sku,
			});
		}

		const { quantity, unitPrice } = item;

		localSale.addItem(product, quantity, unitPrice);

		return {
			id: product.id,
			sku: item.sku,
			quantity: item.quantity,
			unitPrice: item.unitPrice,
		};
	}

	@flow.bound
	*apiCreateReceipt(data: CreateDraftReceiptRequest) {
		const localSaleStore = stores.localSales as LocalSales;
		const productStore = stores.products as Products;

		// get products by sku and return an error if any of the products are not found
		const products = yield Promise.all(
			data.items.map(async (item) => {
				const product = productStore.bySku[item.sku];
				if (!product) {
					throw new SocketError('ERROR_NOT_FOUND_PRODUCT_SKU', {
						sku: item.sku,
					});
				}
				return {
					...item,
					product,
				};
			})
		);

		const localSale = localSaleStore.createSale();
		localSale.setInvoiceType(data.invoiceType);

		products.forEach(({ product, quantity, unitPrice }) => {
			localSale.addItem(product, quantity, unitPrice);
		});

		// if (data.payment) {
		// 	localSale.setPayment(data.payment);
		// }

		// if (data.status === 'draft') {
		return {
			id: localSale.uniqueId,
			items: localSale.itemsAsArray.map((item) => ({
				id: item.id,
				sku: item.product.sku,
				quantity: item.quantity,
				unitPrice: item.price,
			})),
			// receipts: [],
		};
	}

	@flow.bound
	*apiFinalize(draftId: string, data: FinalizeDraftReceiptRequest) {
		const localSaleStore = stores.localSales as LocalSales;

		const localSale = localSaleStore.byUniqueId[draftId];
		if (!localSale) {
			throw new Error('ERROR_NOT_FOUND_DRAFT_RECEIPT_NOT_FOUND');
		}

		localSale.setPayment(data.payment);

		const invoice = yield this.fiscalize(
			localSale,
			data.buyerId,
			data.buyerCostCenterId,
			{
				a4: !!data.receiptDelivery?.a4Printer,
				email: data.receiptDelivery?.email,
				thermal: !!data.receiptDelivery?.thermalPrinter,
				pdf: !!data.receiptDelivery?.pdf,
				html: !!data.receiptDelivery?.html,
			}
		);
		const id = localSale.uniqueId;
		const items = [...localSale.itemsAsArray];
		localSaleStore.removeSale(localSale);
		return {
			draftId: id,
			items: items,
			receipts: [invoice],
		};
	}

	async afterAuth(authenticated: boolean) {
		if (authenticated && this.failedSyncing.length > 0) {
			if (!retryInterval) {
				retryInterval = setInterval(() => {
					this.retryFailed();
				}, 60000);
			}
		}
	}
}

export { Receipts, Receipt };
