import ProLayout from '@ant-design/pro-layout/es';
import flattenDeep from 'lodash/flattenDeep';
import React, { forwardRef, useRef, useImperativeHandle, useMemo } from 'react';
import { Route, Link, Routes, Navigate, useLocation } from 'react-router-dom';

function RouteRender({ route, componentRef, onRouteChange }) {
	const location = useLocation();
	if (
		route.routes?.length &&
		route.path.replace('/*', '') === location.pathname
	) {
		return <Navigate to={route.routes[0].path} />;
	}

	if (route.page) {
		return (
			<route.page.Page
				ref={(ref) => {
					componentRef.current = ref;
					onRouteChange(route.path, ref);
				}}
			/>
		);
	}

	return route.component ? (
		<route.component
			// {...routeProps}
			routes={(route as any).routes}
			ref={(ref) => {
				componentRef.current = ref;
				onRouteChange(route.path, ref);
			}}
		/>
	) : null;
}

function Settings(props, ref) {
	const { routes, onRouteChange = () => null } = props;

	const componentRef = useRef();
	useImperativeHandle(ref, () => ({
		renderHeader: () =>
			componentRef?.current
				? (componentRef?.current as any)?.renderHeader?.()
				: null,
	}));

	const reducedRoutes = useMemo(() => {
		return routes.reduce((prev, curr) => {
			prev.push(curr);
			function getChildren(route) {
				if (route.routes) {
					route.routes.forEach((child) => {
						prev.push(child);
						getChildren(child);
					});
				}
			}
			getChildren(curr);
			return prev;
		}, []);
	}, [routes]);

	const location = useLocation();
	if (location.pathname === '/app/settings') {
		return <Navigate to="/app/settings/about" />;
	}

	return (
		<ProLayout
			menuHeaderRender={() => null}
			siderWidth={200}
			menuItemRender={(item, defaultDom) => (
				<Link to={item.path}>
					{item.isInSubmenu && item.icon && item.icon}
					{defaultDom}
				</Link>
			)}
			subMenuItemRender={(item, defaultDom) => (
				<>
					{item.isInSubmenu && item.icon}
					{defaultDom}
				</>
			)}
			menuDataRender={() => routes}
			footerRender={() => null}
			navTheme="light"
			fixedHeader
			fixSiderbar
			collapsedButtonRender={() => null}
			disableContentMargin
			headerRender={false}
		>
			<Routes>
				{flattenDeep(reducedRoutes).map((route: any) => (
					<Route
						path={route.path.replace('/app/settings', '')}
						key={route.path}
						element={
							<RouteRender
								route={route}
								componentRef={componentRef}
								onRouteChange={onRouteChange}
							/>
						}
					/>
				))}
			</Routes>
		</ProLayout>
	);
}

const RefSettings = forwardRef(Settings);

export default RefSettings;
