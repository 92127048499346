import { InfoCircleOutlined } from '@ant-design/icons';
import {
	Checkbox,
	Input,
	Tag,
	Tooltip,
	Select,
	message,
	InputNumber,
	Row,
	Col,
} from 'antd';

import CategorySelect from '../../../../components/CategorySelect';
import CurrencySelect from '../../../../components/CurrencySelect';
import Editor from '../../../../components/Editor';
import { CreatePage } from '../../../../components/Page';
import PhotosEdit from '../../../../components/PhotosEdit';
import TaxRateSelect from '../../../../components/TaxRateSelect';
import UnitSelect from '../../../../components/UnitSelect';
import { ERROR_BAD_REQUEST_SKU_ALREADY_USED } from '../../../../constants/errors';
import numberFormat from '../../../../lib/numberFormat';
import stores from '../../../../stores/index.mobx';
import CategoriesView from './Components/CategoriesView';
import CustomCreateButton from './Components/CustomCreateButton';
import QuantitiesEdit from './Components/QuantitiesEdit';
import SaleChannelsInput from './Components/SaleChannelsInput';
import VariantsEdit from './Components/VariantsEdit';
import Search from './Drawers/Search';
import styles from './Products.module.less';

export default CreatePage({
	module: 'catalog',
	submodule: 'products',
	model: 'product',
	view: {
		useEdit: true,
	},
	edit: {
		width: 842,
		title: {
			new: 'Dodavanje artikla',
			existing: (entity) => `Izmena artikla ${entity?.name || ''}`,
		},
		shouldFetch: true,
		beforeSave: (values) => {
			return {
				...values,
				// dimensions: values.dimensions === 'xx' ? null : values.dimensions,
				images: values.images
					? values.images.map((image) => image.response?.id || image?.id)
					: [],
				ean: values.ean === '' ? null : values.ean,
				categories: (values.categories || []).map(
					(category) => category.value || category
				),
			};
		},
		onError: (error, entity) => {
			if (
				error?.response?.data?.errorCode === ERROR_BAD_REQUEST_SKU_ALREADY_USED
			) {
				return message.error('Šifra je već u upotrebi za drugi artikal');
			}

			if (entity) {
				message.error(
					'Došlo je do nepredviđene greške prilikom izmene artikla.'
				);
			} else {
				message.error(
					'Došlo je do nepredviđene greške prilikom dodavanja artikla.'
				);
			}
		},
		fields: (record, form, setFields) => {
			return [
				{
					key: 'info',
					label: 'Osnovne informacije',
					fields: [
						{
							key: 'productType',
							label: 'Tip artikla',
							rules: [{ required: true, message: 'Tip artkila je obavezan' }],
							rerenderOnChange: true,
							initialValue: 'product',
							component: (
								<Select>
									<Select.Option value="product">
										Fizički proizvod
									</Select.Option>
									<Select.Option value="digital">
										Digitalni proizvod
									</Select.Option>
									<Select.Option value="service">Usluga</Select.Option>
								</Select>
							),
							xs: 24,
							sm: 6,
						},
						{
							key: 'name',
							label: 'Naziv',
							rules: [
								{ required: true, message: 'Naziv artkila je obavezan' },
								{
									max: 255,
									message: 'Maksimalna dužina je 255 karaktera',
									type: 'string',
								},
							],
							component: <Input />,
							xs: 24,
							sm: 18,
						},
						{
							key: 'categories',
							label: 'Kategorije',
							component: (
								<CategorySelect
									placeholder="Izaberite kategorije"
									treeCheckable
									showCheckedStrategy="SHOW_CHILD"
									treeCheckStrictly
									showCreateButton
								/>
							),
							span: 24,
						},
						{
							key: 'subtitle',
							label: 'Kratak opis',
							component: <Input />,
							rules: [
								{
									max: 255,
									message: 'Maksimalna dužina je 255 karaktera',
									type: 'string',
								},
							],
							xs: 24,
							sm: 19,
						},
						{
							key: 'taxRateLabels',
							label: 'Poreske oznake',
							initialValue: stores.company?.vat
								? [
										...(stores.company?.defaultTaxRateLabel
											? [stores.company?.defaultTaxRateLabel]
											: []),
								  ]
								: [stores.taxRates.noVat?.[0]?.label],
							rules: [
								{ required: true, message: 'Poreska oznaka je obavezna' },
							],
							component: <TaxRateSelect mode="multiple" />,
							xs: 24,
							sm: 5,
						},
						{
							key: 'description',
							label: 'Detaljan opis',
							component: <Editor />,
							span: 24,
						},
						// {
						// 	key: 'active',
						// 	label: '',
						// 	component: <Checkbox>Proizvod je aktivan</Checkbox>,
						// 	valuePropName: 'checked',
						// 	span: 24,
						// },
					],
				},
				{
					key: 'images',
					label: 'Fotografije',
					fields: [
						{
							key: 'images',
							component: <PhotosEdit />,
							span: 24,
							rerenderOnChange: true,
						},
					],
				},
				...(stores.company?.hasFeature('variants') ||
				record?.variants?.length > 0
					? [
							{
								key: 'variants',
								label: 'Varijante',
								fields: [
									{
										key: 'hasVariants',
										component: (
											<Checkbox>
												Artikal ima varijante&nbsp;
												<Tooltip
													title={
														<>
															Ukoliko želite da grupišete različite varijante
															proizvoda u jedan proizvod, omogućite ovu opciju.{' '}
															<br />
															<br />
															Kao varijante možete definisati na primer čokoladu
															od 80g i 300g.
														</>
													}
												>
													<InfoCircleOutlined />
												</Tooltip>
											</Checkbox>
										),
										valuePropName: 'checked',
										rerenderOnChange: true,
									},
									...(form.getFieldValue('hasVariants')
										? [
												{
													key: 'variants',
													component: (
														<VariantsEdit
															productType={form.getFieldValue('productType')}
														/>
													),
													validateTrigger: 'onBlur',
													rules: [
														{
															validator: (_, value) => {
																if (
																	value.find(
																		(variant) =>
																			typeof variant.variantName ===
																				'undefined' ||
																			variant.variantName === '' ||
																			((variant.ean || '') !== '' &&
																				((variant.ean || '').length < 8 ||
																					(variant.ean || '').length > 14))
																	)
																) {
																	return Promise.reject();
																}
																return Promise.resolve();
															},
															message:
																'Neispravan unos varijanti, proverite da li na nekom polju postoji greška',
														},
													],
													span: 24,
												},
										  ]
										: []),
								],
							},
					  ]
					: []),
				...(!form.getFieldValue('hasVariants')
					? [
							...(stores.saleChannels.all.length > 0 &&
							form.getFieldValue('currencyId') === 'RSD'
								? [
										{
											key: 'saleChannels',
											label: 'Dodatni kanali prodaje',
											fields: [
												{
													key: 'saleChannels',
													component: <SaleChannelsInput />,
													span: 24,
												},
											],
										},
								  ]
								: []),
							{
								key: 'inventory',
								label: 'Inventar',
								fields: [
									{
										key: 'sku',
										rules: [
											{
												type: 'number',
												validator: async (rule, value) => {
													if (value > 9999999) {
														throw new Error('Maksimalna šifra je 9999999');
													}
												},
												message: 'Maksimalna šifra je 9999999',
											},
										],
										label: (
											<>
												Šifra&nbsp;
												<Tooltip
													title={
														<>
															Šifru možete koristiti da otkucate artikal ukoliko
															on nema bar kod
															<br />
															<br />
															Ukoliko ovo polje ostavite prazno, artiklu će se
															dodeliti prva slobodna šifra.
														</>
													}
												>
													<InfoCircleOutlined />
												</Tooltip>
											</>
										),
										component: <Input type="number" />,
										xs: 5,
										sm: 3,
										md: 4,
									},
									...(form.getFieldValue('productType') === 'product'
										? [
												{
													key: 'manufacturerSku',
													label: <>Šifra proizvođača</>,
													component: <Input />,
													xs: 8,
													sm: 5,
													md: 4,
												},
										  ]
										: []),
									...(form.getFieldValue('productType') === 'product'
										? [
												{
													key: 'ean',
													label: 'Bar kod (GTIN)',
													rules: [
														{
															type: 'string',
															validator: async (rule, value) => {
																if (
																	typeof value === 'undefined' ||
																	value === null ||
																	value === ''
																) {
																	return;
																}
																if (value.length < 8 || value.length > 14) {
																	throw new Error(
																		'GTIN ne može biti kraći od 8 i duži od 14 karaktera'
																	);
																}
															},
														},
													],
													component: <Input type="number" />,
													xs: 11,
													sm: 5,
												},
										  ]
										: []),
									...(stores.company.allowCurrencyConversion
										? [
												{
													key: 'currencyId',
													label: 'Valuta',
													hidden: true,
													component: <CurrencySelect onlyPreferred />,
													rerenderOnChange: true,
												},
										  ]
										: []),
									{
										key: 'price',
										label: 'Prodajna cena',
										component: (
											<InputNumber
												addonAfter={
													stores.company.allowCurrencyConversion ? (
														<CurrencySelect
															style={{ width: 75 }}
															onlyPreferred
															value={form.getFieldValue('currencyId') || 'RSD'}
															optionLabelProp="value"
															onChange={(value) => {
																form.setFieldsValue({ currencyId: value });
																setFields([{ name: ['currencyId'] }]);
															}}
														/>
													) : (
														'RSD'
													)
												}
												step={0.01}
											/>
										),
										xs: 12,
										sm: 6,
									},
									// ...(form.getFieldValue('productType') === 'product'
									// 	? [
									// 			{
									// 				key: 'trackStocks',
									// 				component: <Checkbox>Prati zalihe robe</Checkbox>,
									// 				valuePropName: 'checked',
									// 				rerenderOnChange: true,
									// 				span: 24,
									// 			},
									// 	  ]
									// 	: []),
									...(form.getFieldValue('trackStocks')
										? [
												{
													key: 'variants',
													component: <QuantitiesEdit />,
													span: 24,
												},
										  ]
										: []),
									...(form.getFieldValue('trackStocks')
										? [
												{
													key: 'trackStocks',
													component: (
														<Checkbox>
															Dozvoli prodaju ukoliko artikal nije na stanju
														</Checkbox>
													),
													rerenderOnChange: true,
													span: 24,
												},
										  ]
										: []),
								],
							},
					  ]
					: []),
				...(!form.getFieldValue('hasVariants') &&
				form.getFieldValue('productType') === 'product'
					? [
							{
								key: 'measurements',
								label: 'Mere',
								fields: [
									{
										key: 'baseUnitId',
										component: <UnitSelect />,
										rerenderOnChange: true,
										hidden: true,
									},
									{
										key: 'quantityPerBaseUnit',
										label: (
											<>
												Količina u JM&nbsp;
												<Tooltip
													title={
														<>
															Jedinica mere u kojoj je iskazana količina
															proizvoda na ambalaži, na primer: ukoliko je u
															pitanju čokolada koja ima 100g, onda je jedinica
															mere kilogram, a količina je 0.1
														</>
													}
												>
													<InfoCircleOutlined />
												</Tooltip>
											</>
										),
										component: (
											<Input
												type="number"
												addonAfter={
													<>
														<UnitSelect
															value={form.getFieldValue('baseUnitId')}
															onChange={(value) => {
																form.setFieldsValue({ baseUnitId: value });
																setFields([{ name: ['baseUnitId'] }]);
															}}
															placeholder="JM"
														/>
													</>
												}
											/>
										),
										xs: 12,
										sm: 6,
									},
									{
										key: 'saleUnitId',
										label: 'Prodajna JM',
										component: <UnitSelect />,
										xs: 12,
										sm: 6,
									},
									// {
									// 	key: 'weight',
									// 	label: 'Bruto masa',
									// 	component: <Input type="number" suffix="kg" />,
									// 	span: 4,
									// },
									// {
									// 	key: 'dimensions',
									// 	label: 'Dimenzije',
									// 	component: <DimensionsInput />,
									// 	span: 8,
									// },
								],
							},
					  ]
					: [
							{
								key: 'measurements',
								label: 'Mere',
								fields: [
									{
										key: 'saleUnitId',
										label: 'Prodajna JM',
										component: <UnitSelect />,
										xs: 12,
										sm: 6,
									},
								],
							},
					  ]),
				...(!form.getFieldValue('hasVariants') &&
				stores.devices.scales.length > 0 &&
				form.getFieldValue('productType') === 'product'
					? [
							{
								key: 'scale',
								label: 'Vaga',
								fields: [
									{
										key: 'quantityFromScale',
										valuePropName: 'checked',
										component: (
											<Checkbox>
												Koristi težinu sa vage&nbsp;
												<Tooltip
													title={
														<>
															Ukoliko je vaga konfigurisana, prilikom dodavanja
															proizvoda na račun, kao količina će se koristiti
															težina sa vage
														</>
													}
												>
													<InfoCircleOutlined />
												</Tooltip>
											</Checkbox>
										),
										span: 24,
									},
								],
							},
					  ]
					: []),
				{
					key: 'receipt',
					label: 'Račun',
					fields: [
						{
							key: 'multiplePerReceipt',
							valuePropName: 'checked',
							component: (
								<Checkbox>Dozvoli više puta na jednom računu</Checkbox>
							),
							span: 24,
						},
					],
				},
			];
		},
	},
	page: {
		empty: {
			text: 'Nema proizvoda',
		},
		shouldFetch: false,
		searchable: true,
		searchPlaceholder: 'Pretraži artikle',
		table: {
			listProp: 'sorted',
			columns: () => {
				return [
					{
						title: '',
						dataIndex: 'coverImage',
						key: 'coverImage',
						width: 50,
						render: (image) => {
							return image ? (
								<>
									<img
										src={image.urls['64x64']}
										alt="Slika"
										className={styles.image}
									/>
								</>
							) : null;
						},
						shouldLink: true,
					},
					{
						title: 'Naziv',
						dataIndex: 'name',
						key: 'name',
						shouldLink: true,
						ellipsis: true,
					},
					{
						title: 'Prodajna cena',
						dataIndex: 'price',
						key: 'price',
						render: (value, record) => {
							if (record.variants.length === 0) {
								return numberFormat(
									value || 0,
									true,
									2,
									true,
									record.currencyId || 'RSD'
								);
							}
							if (record.variants.length === 1) {
								return numberFormat(
									record.variants[0].price,
									true,
									2,
									true,
									record.currencyId || 'RSD'
								);
							}

							return `${numberFormat(
								record.minPricedVariant.price,
								(record.minPricedVariant.currencyId || 'RSD') !==
									(record.maxPricedVariant.currencyId || 'RSD'),
								2,
								true,
								record.minPricedVariant.currencyId || 'RSD'
							)} - ${numberFormat(
								record.maxPricedVariant.price,
								true,
								2,
								true,
								record.maxPricedVariant.currencyId || 'RSD'
							)}`;
						},
						responsive: ['md'],
					},
					{
						title: 'Kategorije',
						dataIndex: 'categories',
						key: 'categories',
						responsive: ['md'],
						filters: () => {
							return stores.categories.tree;
						},
						// filterMultiple: true,
						filterMode: 'tree',
						onFilter: (value: string, record) =>
							record.categories.map((c) => c.id).includes(value),
						render: (text, record) => {
							return <CategoriesView record={record} />;
						},
					},
					...(stores.products.hasVariants ||
					stores.company.hasFeature('variants')
						? [
								{
									title: 'Varijante',
									dataIndex: 'variants',
									key: 'variants',
									responsive: ['md'],
									ellipsis: true,
									render: (text, record) => {
										if (!record.hasVariants) {
											return <Tag color="orange">Nema varijanti</Tag>;
										}
										return record.variants?.map((variant) => (
											<Tag color="magenta">{variant.variantName}</Tag>
										));
									},
								},
						  ]
						: []),
				];
			},
		},
		deletePrompt: 'Da li ste sigurni da želite da obrišete ovaj artikal?',
		customCreateButton: (createHandler) => (
			<CustomCreateButton createButtonHandler={createHandler} />
		),
	},
	drawers: { 'product-search': Search },
});
