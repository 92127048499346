import { Mod97_10 } from '@konfirm/iso7064';
import { AlignmentEnum } from 'ascii-table3';
import round from 'lodash/round';
import upperFirst from 'lodash/upperFirst';
import moment from 'moment';

import enUS from '../assets/locales/journal/en-US.json';
import srCyrlRS from '../assets/locales/journal/sr-Cyrl-RS.json';
import srLatnRS from '../assets/locales/journal/sr-Latn-RS.json';
import { InvoiceType, TransactionType } from '../constants/invoice';
import {
	INVOICE_TYPE_MAP,
	PAYMENT_TYPE_MAP,
	TextSize,
	PaymentType,
	TRANSACTION_TYPE_MAP,
} from '../constants/journal';
import stores from '../stores/index.mobx';
import numberFormat from './numberFormat';
import { normalize } from './referenceNoMod97';
import { ThermalPrinter } from './thermalPrinter';
import { VariableFontJournal } from './variableFontJournal';

const locales = {
	'en-US': enUS,
	'sr-Cyrl-RS': srCyrlRS,
	'sr-Latn-RS': srLatnRS,
};

export class ThermalJournal {
	locale: Record<string, string>;
	invoiceRequest = null;
	invoiceResponse = null;
	items = [];
	advancePayments = undefined;
	advanceItems = undefined;
	lastAdvanceSale = undefined;
	additionalText = undefined;
	unknownAmountAdvance = undefined;

	printer = null;
	constructor(
		invoiceRequest: any,
		invoiceResponse: any,
		includeSignature = false,
		advancePayments = undefined,
		advanceItems = undefined,
		lastAdvanceSale = undefined,
		additionalText = undefined,
		unknownAmountAdvance = undefined
	) {
		const language = stores.stores.currentStore?.language || 'sr-Cyrl-RS';

		this.locale = locales[language];
		this.invoiceRequest = invoiceRequest;
		this.invoiceResponse = invoiceResponse;
		this.advancePayments = advancePayments;
		this.advanceItems = advanceItems;
		this.lastAdvanceSale = lastAdvanceSale;
		this.additionalText = additionalText;
		this.unknownAmountAdvance = unknownAmountAdvance;

		this.printer = new ThermalPrinter();

		const totalTax = this.calculateTotalTax();
		const advanceTax = this.calculateAdvanceTax(
			totalTax,
			advancePayments,
			this.invoiceResponse.totalAmount
		);

		const totalDiscount = this.calculateTotalDiscount();

		const cashPayment = this.invoiceRequest.payment.find(
			(payment) => payment.paymentType === PaymentType.CASH
		);

		if (stores.devices.thermalPrinters[0].configuration.fontType === 'fixed') {
			if (this.isFiscalInvoice()) {
				this.printer.addTitle(this.locale['fiscalInvoice']);
			} else {
				this.printer.addTitle(this.locale['notFiscalReceipt']);
			}
			// this.printer.addLeftRight(this.locale['tin'], invoiceResponse.tin, true);
			this.printer.addCenteredText(invoiceResponse.tin, TextSize.NORMAL);
			// this.printer.addLeftRight(
			// 	this.locale['company'],
			// 	invoiceResponse.businessName,
			// 	true
			// );
			this.printer.addCenteredText(
				invoiceResponse.businessName,
				TextSize.NORMAL
			);

			// this.printer.addLeftRight(
			// 	this.locale['store'],
			// 	invoiceResponse.locationName,
			// 	true
			// );
			this.printer.addCenteredText(
				invoiceResponse.locationName,
				TextSize.NORMAL
			);

			// this.printer.addLeftRight(
			// 	this.locale['address'],
			// 	invoiceResponse.address,
			// 	true
			// );
			this.printer.addCenteredText(invoiceResponse.address, TextSize.NORMAL);

			// this.printer.addLeftRight(
			// 	this.locale['district'],
			// 	invoiceResponse.district,
			// 	true
			// );
			this.printer.addCenteredText(invoiceResponse.district, TextSize.NORMAL);

			this.printer.addSeparator('-');

			this.printer.addLeftRight(
				this.locale['cashier'],
				invoiceRequest.cashier || '',
				true
			);
			if (invoiceRequest.buyerId) {
				this.printer.addLeftRight(
					this.locale['buyer'],
					invoiceRequest.buyerId,
					true
				);
			}
			if (invoiceRequest.buyerCostCenterId) {
				this.printer.addLeftRight(
					this.locale['buyerCostCenter'],
					invoiceRequest.buyerCostCenterId,
					true
				);
			}
			if (invoiceRequest.invoiceNumber) {
				this.printer.addLeftRight(
					this.locale['posNumber'],
					invoiceRequest.invoiceNumber,
					true
				);
			}
			if (invoiceRequest.dateAndTimeOfIssue) {
				this.printer.addLeftRight(
					this.locale['posTime'],
					this.formatDate(invoiceRequest.dateAndTimeOfIssue),
					true
				);
			}
			if (invoiceRequest.referentDocumentNumber) {
				this.printer.addLeftRight(
					this.locale['refNo'],
					invoiceRequest.referentDocumentNumber,
					true
				);
			}
			if (invoiceRequest.referentDocumentDT) {
				this.printer.addLeftRight(
					this.locale['refDT'],
					this.formatDate(invoiceRequest.referentDocumentDT),
					true
				);
			}

			this.printer.addSubtitle(this.locale[this.getInvoiceType()]);
			this.printer.addCenteredText(this.locale['items'], TextSize.NORMAL);

			this.printer.addSeparator();
			this.printer.addTableRow(
				[
					this.printer.createLeftAndRight(
						this.locale['itemName'],
						this.locale['price'],
						false,
						13
					),
					this.locale['quantity'],
					this.locale['itemTotal'],
				],
				[13, 11, -1],
				[AlignmentEnum.LEFT, AlignmentEnum.RIGHT, AlignmentEnum.RIGHT]
			);
			this.invoiceRequest.items.forEach((item: any, index) => {
				this.printer.addText(
					`${item.name} (${item.labels.join(', ')})${
						item.unit && item.isPieceUnitOfMeasure ? `/${item.unit}` : ''
					}`
				);
				this.printer.addTableRow(
					[
						numberFormat(item.unitPrice, false, 2, true),
						numberFormat(item.quantity, false, 3, false),
						numberFormat(
							this.invoiceRequest.transactionType === TransactionType.SALE
								? item.totalAmount
								: -item.totalAmount,
							false,
							2,
							true
						),
					],
					[13, 11, -1],
					[AlignmentEnum.RIGHT, AlignmentEnum.RIGHT, AlignmentEnum.RIGHT]
				);
			});
			this.printer.addSeparator('-');
			this.printer.addLeftRight(
				this.invoiceRequest.transactionType == TransactionType.SALE
					? this.locale['totalPurchase']
					: this.locale['totalRefund'],
				numberFormat(this.invoiceResponse.totalAmount, false, 2, true),
				true
			);

			if (typeof this.advancePayments !== 'undefined') {
				this.printer.addLeftRight(
					this.locale.advancePayments,
					numberFormat(this.advancePayments, false, 2, true),
					true
				);
				this.printer.addLeftRight(
					this.locale.advanceTaxes,
					numberFormat(advanceTax, false, 2, true),
					true
				);
			}

			this.invoiceRequest.payment.forEach((payment) => {
				this.printer.addLeftRight(
					this.locale[PAYMENT_TYPE_MAP[payment.paymentType]],
					numberFormat(Number(payment.amount), false, 2, true),
					true
				);
			});
			if (typeof this.advancePayments !== 'undefined') {
				this.printer.addLeftRight(
					this.locale.remaining,
					numberFormat(0, false, 2, true),
					true
				);
			}
			if (
				this.invoiceRequest.transactionType === TransactionType.SALE &&
				(cashPayment?.amount || 0) > 0
			) {
				this.printer.addLeftRight(
					this.locale.paidCash,
					numberFormat(
						cashPayment.amount + this.invoiceRequest.paymentChange,
						false,
						2,
						true
					),
					true
				);
				this.printer.addLeftRight(
					this.locale.change,
					numberFormat(this.invoiceRequest.paymentChange, false, 2, true),
					true
				);
			}
			this.printer.addSeparator('=');
			if (!this.isFiscalInvoice()) {
				this.printer.addTitle(
					this.locale['notFiscalInvoice'],
					TextSize.DOUBLE,
					' '
				);
				this.printer.addSeparator('=');
			}
			this.printer.addTableRow(
				[
					this.locale.label,
					this.locale.taxName,
					this.locale.rate,
					this.locale.tax,
				],
				[7, 10, 9, -1],
				[
					AlignmentEnum.LEFT,
					AlignmentEnum.RIGHT,
					AlignmentEnum.RIGHT,
					AlignmentEnum.RIGHT,
				]
			);
			this.invoiceResponse.taxItems.forEach((taxItem) => {
				this.printer.addTableRow(
					[
						taxItem.label,
						taxItem.categoryName,
						`${numberFormat(taxItem.rate, false, 2, true)}%`,
						numberFormat(taxItem.amount, false, 2, true),
					],
					[7, 10, 9, -1],
					[
						AlignmentEnum.LEFT,
						AlignmentEnum.RIGHT,
						AlignmentEnum.RIGHT,
						AlignmentEnum.RIGHT,
					]
				);
			});
			this.printer.addSeparator('-');
			this.printer.addLeftRight(
				this.locale['totalTax'],
				numberFormat(totalTax, false, 2, true),
				true
			);
			this.printer.addSeparator('=');
			this.printer.addLeftRight(
				this.locale['sdcTime'],
				this.formatDate(this.invoiceResponse.sdcDateTime),
				true
			);
			this.printer.addLeftRight(
				this.locale['sdcInvoiceNo'],
				this.invoiceResponse.invoiceNumber,
				true
			);
			this.printer.addLeftRight(
				this.locale['invoiceCounter'],
				this.invoiceResponse.invoiceCounter,
				true
			);
			this.printer.addSeparator('=');
			this.printer.addQrCode(
				this.invoiceResponse.verificationUrl,
				this.invoiceResponse.verificationQRCode
			);
			if (includeSignature) {
				this.printer.addText(`${this.locale['signature']}:`);
				this.printer.addSeparator(' ');
				this.printer.addSeparator('_');
			}
			if (this.isFiscalInvoice()) {
				this.printer.addTitle(this.locale['endInvoice']);
			} else {
				this.printer.addTitle(this.locale['notFiscalReceipt']);
			}

			if (this.advanceItems && this.advanceItems.length > 0) {
				this.advanceItems.forEach((item) => {
					const itemName = `${item.product.name}${
						item.variant?.variantName ? ` ${item.variant?.variantName}` : ''
					}`;
					const itemPrice = numberFormat(
						item.finalPrice * item.quantity,
						false,
						2,
						true
					);

					if (this.unknownAmountAdvance) {
						this.printer.addText(itemName);
					} else {
						if (
							itemName.length + itemPrice.length + 1 >
							this.printer.maxWidth
						) {
							this.printer.addText(itemName);
							this.printer.addLeftRight('', itemPrice, false);
						} else {
							this.printer.addLeftRight(itemName, itemPrice, false);
						}
					}
				});
			}

			if (this.lastAdvanceSale) {
				this.printer.addText(`${this.locale['lastAdvance']}`);
				this.printer.addText(
					`${this.lastAdvanceSale.invoiceNumber} ${this.formatDate(
						this.lastAdvanceSale.sdcTime,
						true
					)}`
				);
			}

			const currentStore = stores.stores.currentStore;
			if (
				currentStore?.printBankAccounts &&
				stores.company.bankAccounts?.length &&
				invoiceRequest.transactionType === TransactionType.SALE &&
				invoiceRequest.payment.find(
					(payment) => payment.paymentType === PaymentType.WIRE_TRANSFER
				)
			) {
				this.printer.addText(' ');
				this.printer.addText(`${this.locale['paymentInstructions']}:`);
				this.printer.addText(' ');
				this.printer.addText(
					(stores.company.bankAccounts.length > 1
						? this.locale['bankAccounts']
						: this.locale['bankAccount']) + ':',
					TextSize.SMALL
				);

				stores.company.bankAccounts.forEach((bankAccount) => {
					this.printer.addText(' ');

					this.printer.addText(
						stores.application.banks[bankAccount.bankId],
						TextSize.SMALL
					);
					this.printer.addText(bankAccount.formattedNumber, TextSize.SMALL);
				});
				this.printer.addText(' ');
				this.printer.addText(`${this.locale['model']}: 97`, TextSize.SMALL);
				this.printer.addText(
					`${this.locale['referenceNo']}: ${Mod97_10.checksum(
						normalize(invoiceResponse.invoiceNumber)
					)}-${invoiceResponse.invoiceNumber}`,
					TextSize.SMALL
				);
				this.printer.addText(' ');
				this.printer.addSeparator('_');

				this.printer.addText(' ');
			}

			if (totalDiscount > 0 && currentStore?.printDiscounts) {
				this.printer.addText(' ');
				this.printer.addText(`${this.locale['discounts']}:`);
				this.printer.addText(' ');

				this.printer.addLeftRight(
					this.locale['itemName'],
					this.locale['discount'],
					false
				);
				this.invoiceRequest.items.forEach((item) => {
					if (item.discount > 0) {
						this.printer.addText(item.name);
						this.printer.addLeftRight(
							'',
							numberFormat(item.discount, false, 2, true),
							false
						);
					}
				});
				this.printer.addSeparator('-');
				this.printer.addLeftRight(
					this.locale.itemTotal,
					numberFormat(totalDiscount, false, 2, true),
					false
				);
			}

			if (this.additionalText) {
				this.printer.addText(' ');
				this.printer.addText(this.additionalText);
				this.printer.addText(' ');
			}

			if (
				stores.devices.thermalPrinters[0].configuration.additionalText !== ''
			) {
				if ((advanceItems && advanceItems.length > 0) || this.lastAdvanceSale) {
					this.printer.addSeparator('-');
				}
				this.printer.addText(
					stores.devices.thermalPrinters[0].configuration.additionalText,
					TextSize.SMALL
				);
			}

			this.printer.addText(' ');
		} else {
			const variableFontJournal = new VariableFontJournal(
				invoiceRequest,
				invoiceResponse,
				includeSignature,
				advancePayments,
				advanceItems,
				lastAdvanceSale,
				additionalText,
				unknownAmountAdvance
			);
			this.printer.setComponent(
				variableFontJournal.getComponent(),
				variableFontJournal.getRenderDeferred()
			);
		}
	}

	isFiscalInvoice(): boolean {
		return [InvoiceType.NORMAL, InvoiceType.ADVANCE].includes(
			this.invoiceRequest.invoiceType
		);
	}

	formatDate(date: string, dateOnly = false): string {
		if (dateOnly) {
			return moment(date).format('DD.MM.YYYY');
		}
		return moment(date).format('DD.MM.YYYY HH:mm:ss');
	}

	getInvoiceType() {
		return `${INVOICE_TYPE_MAP[this.invoiceRequest.invoiceType]}${upperFirst(
			TRANSACTION_TYPE_MAP[this.invoiceRequest.transactionType]
		)}`;
	}

	calculateTotalTax() {
		return round(
			this.invoiceResponse.taxItems.reduce(
				(acc, curr) => acc + Number(curr.amount),
				0
			),
			2
		);
	}

	calculateTotalDiscount() {
		return round(
			this.invoiceRequest.items.reduce(
				(acc, curr) => acc + Number(curr.discount),
				0
			),
			2
		);
	}

	calculateAdvanceTax(totalTax, advancePayments, totalAmount) {
		return round((totalTax * advancePayments) / totalAmount, 2);
	}

	print() {
		this.printer.print();
	}
}
