import { useLatest } from 'ahooks';
import {
	Modal,
	Input,
	Divider,
	Button,
	Select,
	Form,
	message,
	Row,
	Col,
	Space,
	Checkbox,
	DatePicker,
	Alert,
	Spin,
	Grid,
	Drawer,
	InputNumber,
} from 'antd';
import clamp from 'lodash/clamp';
import reverse from 'lodash/reverse';
import round from 'lodash/round';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import Deferred from 'promise-deferred';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import enUS from '../../assets/locales/journal/en-US.json';
import srCyrlRS from '../../assets/locales/journal/sr-Cyrl-RS.json';
import srLatnRS from '../../assets/locales/journal/sr-Latn-RS.json';
import { APPLICATION_NAME } from '../../constants/application';
import { TAX_FREE_LABEL } from '../../constants/invoice';
import {
	ADVANCE_TYPE,
	InvoiceType,
	INVOICE_TYPE_FROM_STRING,
	PaymentType,
	PAYMENT_TYPE_MAP,
	PAYMENT_TYPE_TEXT,
	TransactionType,
	TransactionTypeAPI,
	TRANSACTION_TYPE_FROM_STRING,
} from '../../constants/journal';
import numberFormat from '../../lib/numberFormat';
import SelectStore from '../../modules/Application/POS/Components/SelectStore';
import stores from '../../stores/index.mobx';
import { Receipt } from '../../stores/Receipt.mobx';
import PosTerminalModal from '../PosTerminalModal';
import BuyerCostCenterInput from './Components/BuyerCostCenterInput';
import BuyerInput from './Components/BuyerInput';
import EmailModal from './Components/EmailModal';
import AdvanceFinalizeSpecificationDrawer from './Drawers/AdvanceFinalizeSpecification';
import AdvanceSpecificationDrawer from './Drawers/AdvanceSpecification';
import styles from './PaymentModal.module.less';

const locales = {
	'en-US': enUS,
	'sr-Cyrl-RS': srCyrlRS,
	'sr-Latn-RS': srLatnRS,
};

type Props = {
	type?: 'sale' | 'refund';
	amount: number;
	form?: any;
	visible?: boolean;
	items?: any[];
	receipt?: Receipt;
	connectedReceipts?: Receipt[];
	closePayment: (removeSale?: boolean) => void;
	payment?: any;
	printing?: boolean;
	completed?: boolean;
	prefillPayment: [any, any, any, any, any, any, any];
	isAdvanceOld?: boolean;
	// deliveries?: Array<Instance<typeof Delivery>>;
	copy?: boolean;
	addAdvancePayment?: boolean;
	closeAdvancePayment?: boolean;
	finalizeCloseAdvancePayment?: boolean;
	finalizeProforma?: boolean;
	advanceRefund?: boolean;
	advancePayments?: number;
	hasForeignCurrency?: boolean;
	voidMode?: boolean;
	setDate?: (date) => void;
	setTaxFree?: (taxFree: boolean) => void;
};

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		lg: { span: 8 },
	},
	wrapperCol: {
		xs: { span: 24 },
		lg: { span: 16 },
	},
};

const rightFormItemLayout = {
	labelCol: {
		xs: { span: 24 },
		lg: { span: 12 },
	},
	wrapperCol: {
		xs: { span: 24 },
		lg: { span: 12 },
	},
};

const invoiceType = [
	{
		label: 'Promet',
		value: 0,
	},
	{
		label: 'Predračun',
		value: 1,
	},
	{
		label: 'Obuka',
		value: 3,
	},
	{
		label: 'Avans',
		value: 4,
	},
];
const transactionType = [
	{
		label: 'Prodaja',
		value: 0,
	},
	{
		label: 'Refundacija',
		value: 1,
	},
];

let advanceSpecificationDeferred;

function PaymentModal({
	type = 'sale',
	visible,
	closePayment,
	amount,
	items,
	receipt,
	prefillPayment,
	copy = false,
	addAdvancePayment = false,
	closeAdvancePayment = false,
	finalizeCloseAdvancePayment = false,
	advanceRefund = false,
	advancePayments = 0,
	finalizeProforma = false,
	hasForeignCurrency = false,
	isAdvanceOld = false,
	voidMode = false,
	setDate = (date) => {
		//
	},
	setTaxFree = (taxFree) => {
		//
	},
	connectedReceipts,
}: Props) {
	const {
		receipts,
		exchangeRates,
		devices: { thermalPrinters, posTerminals },
		stores: { currentStore: store, currentStoreId: storeId },
		company: { taxFreeMode, availablePaymentMethods, tin },
	} = stores;

	const { language } = store || {};

	const [form] = Form.useForm();
	const [showHidden, setShowHidden] = useState(false);
	const [showAdditionalFields, setShowAdditionalFields] = useState(false);

	const [values, setValues] = useState<{
		buyerId?: string;
		buyerCostCenterId?: string;
		remaining?: number;
		invoiceType?: InvoiceType;
		transactionType?: TransactionType;
		referentDocumentDT?: string;
		referentDocumentNumber?: string;
		posTime?: string;
		printCopy?: boolean;
		discountedAmount?: number;
		discount?: number;
		unknownAmountAdvance?: boolean;
	}>({});
	const [emailModalVisible, setEmailModalVisible] = useState(false);
	const [
		advanceSpecificationDrawerVisible,
		setAdvanceSpecificationDrawerVisible,
	] = useState(false);

	const [
		advanceFinalizeSpecificationDrawerVisible,
		setAdvanceFinalizeSpecificationDrawerVisible,
	] = useState(false);
	const [
		advanceFinalizeSpecificationLabels,
		setAdvanceFinalizeSpecificationLabels,
	] = useState({});
	const [
		advanceFinalizeSpecificationItems,
		setAdvanceFinalizeSpecificationItems,
	] = useState([]);

	const [advanceSpecificationLabels, setAdvanceSpecificationLabels] = useState(
		[]
	);
	const [advanceSpecificationSum, setAdvanceSpecificationSum] = useState(0);

	const [posTerminalModalVisible, setPosTerminalModalVisible] = useState(false);
	const [isTaxFree, setIsTaxFree] = useState(false);

	const [loadingReferentDocument, setLoadingReferentDocument] = useState(false);
	const [referentDocumentIsAdvanceRefund, setReferentDocumentIsAdvanceRefund] =
		useState(false);

	const [referentDocument, setReferentDocument] = useState<Receipt>(null);

	const latestIsTaxFree = useLatest(isTaxFree);
	const latestAmount = useLatest<number>(amount);
	const latestItems = useLatest<any[]>(items);

	const [isLoading, setIsLoading] = useState(false);

	const overrideCloseAdvance = useRef(false);

	const [printThermal, setPrintThermal] = useState(
		window.electron && thermalPrinters.length
	);
	const [printA4, setPrintA4] = useState(
		!window.electron || thermalPrinters.length === 0
	);
	const [sendEmail, setSendEmail] = useState(false);
	const printSettings = useRef({
		printThermal,
		printA4,
		sendEmail,
	});

	useEffect(() => {
		printSettings.current = {
			printThermal: printThermal && store?.printMethod?.thermal,
			printA4: printA4 && store?.printMethod?.a4,
			sendEmail: sendEmail && store?.printMethod?.email,
		};
	}, [printThermal, printA4, sendEmail, store]);

	const isInputFocused = useRef(false);

	const paymentMethods = useMemo(() => {
		if (!store) {
			return [];
		}

		return availablePaymentMethods === 'all' || copy
			? store.paymentMethodsOrder
			: store.paymentMethodsOrder.filter((pm) => {
					return [
						PaymentType.CASH,
						PaymentType.VOUCHER,
						PaymentType.WIRE_TRANSFER,
						PaymentType.OTHER,
					].includes(pm);
			  });
	}, [store, store?.paymentMethodsOrder, availablePaymentMethods]);

	const visiblePaymentMethods = useMemo(() => {
		const hideIndex = paymentMethods.indexOf(-1);
		return paymentMethods.filter((pm, index) => {
			return index < hideIndex;
		});
	}, [paymentMethods]);

	const hiddenPaymentMethods = useMemo(() => {
		const hideIndex = paymentMethods.indexOf(-1);
		return paymentMethods.filter((pm, index) => {
			return index > hideIndex;
		});
	}, [paymentMethods]);

	const advPayments = useMemo(() => {
		if (
			referentDocumentIsAdvanceRefund &&
			values.invoiceType === InvoiceType.NORMAL &&
			values.transactionType === TransactionType.SALE
		) {
			if (!referentDocument) {
				return 0;
			}
			return referentDocument.totalAmount;
		}

		return 0;
	}, [
		values.invoiceType,
		values.transactionType,
		referentDocumentIsAdvanceRefund,
		referentDocument,
	]);

	function getSum() {
		const vals = form.getFieldsValue();
		return round(
			paymentMethods
				.filter((pm) => pm !== -1)
				.reduce((acc, curr) => {
					return acc + parseFloat(vals[PAYMENT_TYPE_MAP[curr]] || '0');
				}, 0),
			2
		);
	}

	const screens = Grid.useBreakpoint();
	const Component = screens.md ? Modal : Drawer;

	async function finalizePayment() {
		setIsLoading(false);
		closePayment(true);
	}

	async function createInvoice({ email = null } = {}) {
		try {
			setIsLoading(true);
			const vals = form.getFieldsValue();
			if (finalizeCloseAdvancePayment) {
				const lastAdvanceSale = reverse(connectedReceipts).find(
					(receipt) =>
						INVOICE_TYPE_FROM_STRING[receipt.invoiceType] ===
							InvoiceType.ADVANCE &&
						TRANSACTION_TYPE_FROM_STRING[receipt.transactionType] ===
							TransactionType.SALE
				);

				await receipts.createInvoice(
					InvoiceType.NORMAL,
					TransactionType.SALE,
					{
						referentDocumentNumber: receipt.invoiceNumber,
						referentDocumentDT: moment(receipt.sdcTime),
						advancePayments,
					},
					{
						thermal: printSettings.current.printThermal,
						a4: printSettings.current.printA4,
						email,
					},
					(receipt.advanceItems && receipt.advanceItems.length
						? receipt.advanceItems
						: receipt.receiptItems
					).map((item) => ({
						...item,
						product: {
							id: item.productId,
							name: item.name,
							gtin: item.ean,
							saleUnit: {
								label: item.unit,
								isPieceUnitOfMeasure: item.isPieceUnitOfMeasure,
							},
							sku: item.sku,
							taxRateLabels: item.taxLabels,
						},
						labels: latestIsTaxFree.current ? [TAX_FREE_LABEL] : item.labels,
						finalPrice: item.unitPrice,
					})),
					false,
					undefined,
					lastAdvanceSale
				);
			} else if (
				voidMode &&
				type === 'refund' &&
				INVOICE_TYPE_FROM_STRING[receipt?.invoiceType] === InvoiceType.NORMAL &&
				TRANSACTION_TYPE_FROM_STRING[receipt?.transactionType] ===
					TransactionType.SALE &&
				receipt?.referentDocumentNumber &&
				receipt?.connectedReceipts?.find(
					(cr) =>
						cr.invoiceNumber === receipt?.referentDocumentNumber &&
						TRANSACTION_TYPE_FROM_STRING[cr?.transactionType] ===
							TransactionType.REFUND &&
						INVOICE_TYPE_FROM_STRING[cr.invoiceType] === InvoiceType.ADVANCE
				)
			) {
				const lastAdvanceSale = reverse(receipt.connectedReceipts).find(
					(cr) =>
						INVOICE_TYPE_FROM_STRING[cr.invoiceType] === InvoiceType.ADVANCE &&
						TRANSACTION_TYPE_FROM_STRING[cr.transactionType] ===
							TransactionType.SALE
				);

				const advanceRefund = receipt?.connectedReceipts?.find(
					(cr) =>
						cr.invoiceNumber === receipt?.referentDocumentNumber &&
						TRANSACTION_TYPE_FROM_STRING[cr?.transactionType] ===
							TransactionType.REFUND &&
						INVOICE_TYPE_FROM_STRING[cr.invoiceType] === InvoiceType.ADVANCE
				);
				const advanceSum = round(
					receipt.connectedReceipts
						.filter(
							(cr) =>
								INVOICE_TYPE_FROM_STRING[cr.invoiceType] ===
									InvoiceType.ADVANCE && cr.id !== advanceRefund?.id
						)
						.reduce((acc, cur) => {
							const paymentTotal =
								TRANSACTION_TYPE_FROM_STRING[cur.transactionType] ===
								TransactionType.SALE
									? cur.paymentTotal
									: -cur.paymentTotal;
							return acc + paymentTotal - cur.paymentChange;
						}, 0),
					2
				);

				await receipts.createInvoice(
					InvoiceType.NORMAL,
					TransactionType.REFUND,
					{
						referentDocumentNumber: receipt.invoiceNumber,
						referentDocumentDT: moment(receipt.sdcTime),
						advancePayments: advanceSum,
						buyerId: `${tin}`.length === 13 ? `11:${tin}` : `10:${tin}`,
					},
					{
						thermal: printSettings.current.printThermal,
						a4: printSettings.current.printA4,
						email,
					},
					(receipt.advanceItems && receipt.advanceItems.length
						? receipt.advanceItems
						: receipt.receiptItems
					).map((item) => ({
						...item,
						product: {
							id: item.productId,
							name: item.name,
							gtin: item.ean,
							saleUnit: {
								label: item.unit,
								isPieceUnitOfMeasure: item.isPieceUnitOfMeasure,
							},
							sku: item.sku,
							taxRateLabels: item.taxLabels,
						},
						labels: latestIsTaxFree.current ? [TAX_FREE_LABEL] : item.labels,
						finalPrice: item.unitPrice,
					})),
					false,
					undefined,
					lastAdvanceSale
				);
			} else if (
				referentDocumentIsAdvanceRefund &&
				vals.invoiceType === InvoiceType.NORMAL &&
				vals.transactionType === TransactionType.SALE
			) {
				const cr: any[] = [...referentDocument.connectedReceipts];
				if (
					INVOICE_TYPE_FROM_STRING[cr[0].invoiceType] === InvoiceType.ADVANCE &&
					TRANSACTION_TYPE_FROM_STRING[cr[0].transactionType] ===
						TransactionType.REFUND
				) {
					cr.unshift({
						...referentDocument.connectedReceipts[0],
						transactionType: TransactionTypeAPI.SALE,
						invoiceNumber:
							referentDocument.connectedReceipts[0].referentDocumentNumber,
						sdcTime: referentDocument.connectedReceipts[0].referentDocumentDT,
					});
				}
				cr.pop();

				const lastAdvanceSale = reverse(cr).find((r) => {
					return (
						INVOICE_TYPE_FROM_STRING[r.invoiceType] === InvoiceType.ADVANCE &&
						TRANSACTION_TYPE_FROM_STRING[r.transactionType] ===
							TransactionType.SALE
					);
				});

				const advancePaymentsPerMethod = cr
					.filter((crc) => !crc.void && !crc.voids)
					.reduce((acc, crc) => {
						crc.payment.forEach((payment) => {
							if (typeof acc[payment.paymentType] === 'undefined') {
								acc[payment.paymentType] = 0;
							}

							acc[payment.paymentType] = round(
								acc[payment.paymentType] +
									(TRANSACTION_TYPE_FROM_STRING[crc.transactionType] ===
									TransactionType.SALE
										? payment.amount
										: -payment.amount),
								2
							);
						});
						return acc;
					}, {});

				// go through each payment method and if it's negative, subtract that amount from the rest of the payment methods so sum is equal to the original amount
				Object.entries(advancePaymentsPerMethod).forEach(
					([paymentType, aamount]) => {
						if (aamount < 0) {
							let toSubtract = -aamount;
							advancePaymentsPerMethod[paymentType] = 0;
							Object.entries(advancePaymentsPerMethod).forEach(
								([paymentType2, apamount]) => {
									if (paymentType2 !== paymentType) {
										// don't let that amount after subtraction is negative
										if (apamount < toSubtract) {
											toSubtract -= apamount as number;
											advancePaymentsPerMethod[paymentType2] = 0;
										} else {
											advancePaymentsPerMethod[paymentType2] -= toSubtract;
											toSubtract = 0;
										}

										if (toSubtract === 0) {
											return;
										}
									}
								}
							);
						}
					}
				);
				const invoice = referentDocument;
				await receipts.createInvoice(
					InvoiceType.NORMAL,
					TransactionType.SALE,
					{
						...vals,
						referentDocumentNumber: invoice.invoiceNumber,
						referentDocumentDT: moment(invoice.sdcTime),
						advancePayments: advPayments,
					},
					{
						thermal: printSettings.current.printThermal,
						a4: printSettings.current.printA4,
						email,
					},
					items,
					false,
					undefined,
					lastAdvanceSale
				);
			} else if (overrideCloseAdvance.current || closeAdvancePayment) {
				const cr: any[] = [...connectedReceipts];

				const lastAdvanceSale = reverse(cr).find(
					(receipt) =>
						INVOICE_TYPE_FROM_STRING[receipt.invoiceType] ===
							InvoiceType.ADVANCE &&
						TRANSACTION_TYPE_FROM_STRING[receipt.transactionType] ===
							TransactionType.SALE
				);
				const advancePaymentsPerMethod = cr
					.filter((cr) => !cr.void && !cr.voids)
					.reduce((acc, cr) => {
						cr.payment.forEach((payment) => {
							if (typeof acc[payment.paymentType] === 'undefined') {
								acc[payment.paymentType] = 0;
							}

							acc[payment.paymentType] = round(
								acc[payment.paymentType] +
									(TRANSACTION_TYPE_FROM_STRING[cr.transactionType] ===
									TransactionType.SALE
										? payment.amount
										: -payment.amount),
								2
							);
						});
						return acc;
					}, {});
				const advanceItems = (
					overrideCloseAdvance.current
						? lastAdvanceSale.receiptItems.map((item) => ({
								...item,
								product: {
									id: item.productId,
									gtin: item.gtin,
									name: item.name,
									price: item.unitPrice,
									taxRateLabels: item.taxLabels,
									unit: item.unit,
								},
								finalPrice: item.unitPrice,
						  }))
						: items
				).map((item) => {
					const aamount = round(
						cr
							.filter(
								(cr) =>
									INVOICE_TYPE_FROM_STRING[cr.invoiceType] ===
										InvoiceType.ADVANCE &&
									!cr.void &&
									!cr.voids
							)
							.reduce((acc, cr) => {
								return (
									acc +
									(cr.receiptItems.find((ri) => ri.name === item.name)
										?.totalAmount || 0) *
										(TRANSACTION_TYPE_FROM_STRING[cr.transactionType] ===
										TransactionType.SALE
											? 1
											: -1)
								);
							}, 0),
						2
					);
					return {
						...item,
						product: {
							...item.product,
							price: aamount,
						},
						totalAmount: aamount,
						finalPrice: aamount,
						unitPrice: aamount,
					};
				});

				let receiptItems = (
					receipt.advanceItems && receipt.advanceItems.length
						? receipt.advanceItems
						: receipt.receiptItems
				).map((item) => ({
					...item,
					product: {
						id: item.productId,
						name: item.name,
						gtin: item.ean,
						saleUnit: {
							label: item.unit,
							isPieceUnitOfMeasure: item.isPieceUnitOfMeasure,
						},
						sku: item.sku,
						taxRateLabels: item.taxLabels,
					},
					labels: latestIsTaxFree.current ? [TAX_FREE_LABEL] : item.labels,
					finalPrice: item.unitPrice,
				}));

				if (lastAdvanceSale.unknownAmountAdvance) {
					const advanceSumByLabel = receipt.connectedReceipts
						.filter(
							(cr) =>
								INVOICE_TYPE_FROM_STRING[cr.invoiceType] === InvoiceType.ADVANCE
						)
						.reduce((acc, cur) => {
							cur.receiptItems.forEach((item) => {
								if (typeof acc[item.taxLabels[0]] === 'undefined') {
									acc[item.taxLabels[0]] = {
										label: item.taxLabels[0],
										amount: 0,
									};
								}

								acc[item.taxLabels[0]].amount = round(
									acc[item.taxLabels[0]].amount + item.unitPrice
								);
							});
							return acc;
						}, {});

					if (
						Object.keys(advanceSumByLabel).length === receiptItems.length &&
						getSum() === 0
					) {
						receiptItems = receiptItems.map((item) => {
							const totalAmount = advanceSumByLabel[item.taxLabels[0]].amount;
							const unitPrice = round(totalAmount / item.quantity, 2);
							return {
								...item,
								totalAmount,
								unitPrice,
								finalPrice: unitPrice,
							};
						});
					} else {
						setAdvanceFinalizeSpecificationLabels(advanceSumByLabel);
						setAdvanceSpecificationSum(round(advancePayments + getSum(), 2));

						setAdvanceFinalizeSpecificationItems(receiptItems);
						setAdvanceFinalizeSpecificationDrawerVisible(true);
						advanceSpecificationDeferred = new Deferred();
						const updatedItems = await advanceSpecificationDeferred.promise;

						receiptItems = updatedItems;
					}
				}

				// go through each payment method and if it's negative, subtract that amount from the rest of the payment methods so sum is equal to the original amount
				Object.entries(advancePaymentsPerMethod).forEach(
					([paymentType, aamount]) => {
						if (aamount < 0) {
							let toSubtract = -aamount;
							advancePaymentsPerMethod[paymentType] = 0;
							Object.entries(advancePaymentsPerMethod).forEach(
								([paymentType2, apamount]) => {
									if (paymentType2 !== paymentType) {
										// don't let that amount after subtraction is negative
										if (apamount < toSubtract) {
											toSubtract -= apamount as number;
											advancePaymentsPerMethod[paymentType2] = 0;
										} else {
											advancePaymentsPerMethod[paymentType2] -= toSubtract;
											toSubtract = 0;
										}

										if (toSubtract === 0) {
											return;
										}
									}
								}
							);
						}
					}
				);
				let invoice = cr[0];

				if (!isAdvanceOld) {
					const data = await receipts.createInvoice(
						InvoiceType.ADVANCE,
						TransactionType.REFUND,
						{
							...vals,
							cash: advancePaymentsPerMethod['cash'] || 0,
							card: advancePaymentsPerMethod['card'] || 0,
							check: advancePaymentsPerMethod['check'] || 0,
							wiretransfer: advancePaymentsPerMethod['wiretransfer'] || 0,
							voucher: advancePaymentsPerMethod['voucher'] || 0,
							mobilemoney: advancePaymentsPerMethod['mobilemoney'] || 0,
							other: advancePaymentsPerMethod['other'] || 0,
						},
						{
							thermal: printSettings.current.printThermal,
							a4: printSettings.current.printA4,
							email,
						},
						advanceItems,
						false
					);
					invoice = data.invoice;
				}

				await receipts.createInvoice(
					InvoiceType.NORMAL,
					TransactionType.SALE,
					{
						...vals,
						referentDocumentNumber: invoice.invoiceNumber,
						referentDocumentDT: moment(invoice.sdcDateTime),
						advancePayments,
					},
					{
						thermal: printSettings.current.printThermal,
						a4: printSettings.current.printA4,
						email,
					},
					receiptItems,
					false,
					undefined,
					lastAdvanceSale
				);

				// create normal sale with advance details
			} else if (
				vals.invoiceType === InvoiceType.ADVANCE &&
				vals.transactionType === TransactionType.SALE
			) {
				const totalsPerLabel = items.reduce((acc, curr) => {
					const label = latestIsTaxFree.current
						? TAX_FREE_LABEL
						: curr.product.taxRateLabels[0];
					if (!acc[label]) {
						acc[label] = 0;
					}

					acc[label] = round(
						acc[label] +
							round(
								curr.finalPrice -
									(curr.finalPrice * (values.discount || 0)) / 100,
								2
							) *
								curr.quantity,
						2
					);
					return acc;
				}, {});

				let advancePaymentsPerLabel = [];

				if (values.unknownAmountAdvance) {
					const labels = Object.keys(totalsPerLabel);
					if (labels.length > 1) {
						setAdvanceSpecificationDrawerVisible(true);
						setAdvanceSpecificationLabels(labels);
						setAdvanceSpecificationSum(getSum());
						advanceSpecificationDeferred = new Deferred();
						const updatedLabels = await advanceSpecificationDeferred.promise;

						advancePaymentsPerLabel = updatedLabels.map((label) => ({
							finalPrice: label.amount,
							labels: [label.label],
							quantity: 1,
							product: {
								id: null,
								ean: null,
								name: `${ADVANCE_TYPE[label.label]}: ${
									locales[language || 'sr-Cyrl-RS'].advance
								}`,
								sku: null,
							},
							isAdvance: true,
						}));
					} else {
						advancePaymentsPerLabel = [
							{
								finalPrice: getSum(),
								labels: [labels[0]],
								quantity: 1,
								product: {
									id: null,
									ean: null,
									name: `${ADVANCE_TYPE[labels[0]]}: ${
										locales[language || 'sr-Cyrl-RS'].advance
									}`,
									sku: null,
								},
								isAdvance: true,
							},
						];
					}
				} else {
					advancePaymentsPerLabel = Object.entries(totalsPerLabel).map(
						([label, total]) => {
							const sum = getSum();
							const unitPrice = round(
								(Number(total) * sum) / values.discountedAmount,
								2
							);
							return {
								finalPrice: unitPrice,
								labels: [label],
								quantity: 1,
								product: {
									id: null,
									ean: null,
									name: `${ADVANCE_TYPE[label]}: ${
										locales[language || 'sr-Cyrl-RS'].advance
									}`,
									sku: null,
								},
								isAdvance: true,
							};
						}
					);
				}
				await receipts.createInvoice(
					InvoiceType.ADVANCE,
					TransactionType.SALE,
					{
						...vals,
					},
					{
						thermal: printSettings.current.printThermal,
						a4: printSettings.current.printA4,
						email,
					},
					advancePaymentsPerLabel,
					false,
					items.map((item) => ({
						...item,
						product: item.product,
						variant: item.variant,
						finalPrice: round(
							item.finalPrice - (item.finalPrice * (vals.discount || 0)) / 100,
							2
						),
						labels: latestIsTaxFree.current ? [TAX_FREE_LABEL] : item.labels,
					}))
				);
			} else if (
				vals.invoiceType === InvoiceType.ADVANCE &&
				vals.transactionType === TransactionType.REFUND
			) {
				const totalsPerLabel = items.reduce((acc, curr) => {
					if (!acc[curr.product.taxRateLabels[0]]) {
						acc[curr.product.taxRateLabels[0]] = 0;
					}

					acc[curr.product.taxRateLabels[0]] += curr.finalPrice * curr.quantity;
					return acc;
				}, {});
				const advancePaymentsPerLabel = Object.entries(totalsPerLabel).map(
					([label, total]) => {
						const sum = getSum();
						const unitPrice = round(
							(Number(total) * sum) /
								(advanceRefund ? advancePayments : latestAmount.current),
							2
						);
						return {
							finalPrice: unitPrice,
							labels: [label],
							quantity: 1,
							product: {
								id: null,
								ean: null,
								name: `${ADVANCE_TYPE[label]}: ${
									locales[language || 'sr-Cyrl-RS'].advance
								}`,
								sku: null,
							},
							isAdvance: true,
						};
					}
				);

				const { invoice } = await receipts.createInvoice(
					InvoiceType.ADVANCE,
					TransactionType.REFUND,
					{
						...vals,
					},
					{
						thermal: printSettings.current.printThermal,
						a4: printSettings.current.printA4,
						email,
					},
					advancePaymentsPerLabel,
					false,
					items
				);
				if (!voidMode && vals.buyerId && vals.cash > 0) {
					await receipts.createInvoice(
						InvoiceType.COPY,
						TransactionType.REFUND,
						{
							...vals,
							referentDocumentNumber: invoice.invoiceNumber,
							referentDocumentDT: moment(invoice.sdcDateTime),
						},
						{
							thermal: printSettings.current.printThermal,
							a4: printSettings.current.printA4,
							email: false,
						},
						advancePaymentsPerLabel,
						true,
						items
					);
				}
			} else if (!copy) {
				const { invoice } = await receipts.createInvoice(
					receipt
						? INVOICE_TYPE_FROM_STRING[receipt.invoiceType]
						: vals.invoiceType,
					type === 'sale' && vals.transactionType === TransactionType.SALE
						? TransactionType.SALE
						: TransactionType.REFUND,
					{
						...vals,
						cash: round(vals.cash - (vals.change || 0), 2),
					},
					{
						thermal: printSettings.current.printThermal,
						a4: printSettings.current.printA4,
						email,
					},
					items.map((item) => ({
						...item,
						product: !receipt
							? item.product
							: {
									id: item.product.id,
									name: item.name,
									ean: item.gtin || null,
									isPieceUnitOfMeasure: item.isPieceUnitOfMeasure,
									sku: item.sku,
									saleUnit: {
										label: item.unit,
									},
							  },
						variant: item.variant,
						labels: latestIsTaxFree.current
							? [TAX_FREE_LABEL]
							: item.labels || item.taxLabels,
						finalPrice: round(
							item.finalPrice - (item.finalPrice * (vals.discount || 0)) / 100,
							2
						),
						discount: round(
							item.priceWithoutDiscount -
								(item.finalPrice -
									(item.finalPrice * (vals.discount || 0)) / 100),
							2
						),
					})),
					false
				);
				if (
					((type === 'refund' && receipt) ||
						(vals.transactionType === TransactionType.REFUND &&
							type === 'sale')) &&
					vals.cash > 0 &&
					!voidMode
				) {
					await receipts.createInvoice(
						InvoiceType.COPY,
						TransactionType.REFUND,
						{
							...vals,
							referentDocumentNumber: invoice.invoiceNumber,
							referentDocumentDT: moment(invoice.sdcDateTime),
							cash: vals.cash ? round(vals.cash - (vals.change || 0), 2) : 0,
						},
						{
							thermal: printSettings.current.printThermal,
							a4: printSettings.current.printA4,
							email: false,
						},
						items.map((item) => ({
							...item,
							product: !receipt
								? item.product
								: {
										id: item.product.id,
										name: item.name,
										ean: item.gtin || null,
										isPieceUnitOfMeasure: item.isPieceUnitOfMeasure,
										sku: item.sku,
										saleUnit: {
											label: item.unit,
										},
								  },
							variant: item.variant,
							labels: latestIsTaxFree.current
								? [TAX_FREE_LABEL]
								: item.labels || item.taxLabels,
							finalPrice: item.finalPrice,
						})),
						true
					);
				}
			} else {
				await receipts.createInvoice(
					InvoiceType.COPY,
					type === 'sale' ? TransactionType.SALE : TransactionType.REFUND,
					{
						...vals,
						cash: round(vals.cash - (vals.change || 0), 2),
					},
					{
						thermal: printSettings.current.printThermal,
						a4: printSettings.current.printA4,
						email,
					},
					items,
					false,
					receipt.advanceItems && receipt.advanceItems.length > 0
						? receipt.advanceItems.map((item) => ({
								...item,
								product: {
									id: item.productId,
									gtin: item.gtin,
									name: item.name,
									price: item.unitPrice,
									taxRateLabels: item.taxLabels,
									unit: item.unit,
								},
								finalPrice: item.unitPrice,
						  }))
						: undefined
				);
			}

			finalizePayment();
		} catch (error) {
			setIsLoading(false);
		}
	}

	async function submit() {
		if (isLoading) {
			return;
		}

		if (form.validateFields())
			if (!values.unknownAmountAdvance) {
				if (advanceRefund && !voidMode) {
					if (getSum() > values.discountedAmount) {
						return message.error(
							'Suma svih metoda plaćanja mora biti manja ili jednaka uplaćenom avansu!'
						);
					}

					if (getSum() === 0) {
						return message.error(
							'Suma svih metoda plaćanja mora biti veća od 0!'
						);
					}
				} else if (
					(values.transactionType === TransactionType.REFUND ||
						type === 'refund') &&
					!voidMode &&
					values.invoiceType !== InvoiceType.ADVANCE
				) {
					if (getSum() !== values.discountedAmount) {
						return message.error(
							'Suma svih metoda plaćanja mora biti jednaka iznosu refundacije!'
						);
					}
				}

				if (!copy) {
					if (
						round(
							getSum() +
								((referentDocument ? advPayments : advancePayments) || 0),
							2
						) < values.discountedAmount &&
						values.invoiceType !== InvoiceType.ADVANCE &&
						!voidMode
					) {
						return message.error(
							'Suma svih metoda plaćanja mora biti veća ili jednaka iznosu računa!'
						);
					}
				}
			}

		if (sendEmail) {
			setEmailModalVisible(true);
			return false;
		}

		if (
			!values.unknownAmountAdvance &&
			addAdvancePayment &&
			Number(values.remaining) === 0
		) {
			Modal.confirm({
				cancelText: 'Dodaj avansnu uplatu',
				okText: 'Zatvori avans',
				title: 'Zatvaranje avansa',
				zIndex: 3000,
				content:
					'Ovom uplatom je isplaćen ceo iznos računa. Želite li da zatvorite avans ili da dodate avansnu uplatu?',
				onCancel: async () => {
					await finalizeSubmit();
				},
				onOk: async () => {
					overrideCloseAdvance.current = true;
					await finalizeSubmit();
				},
			});
			return;
		}

		await finalizeSubmit();
	}

	async function finalizeSubmit() {
		if (posTerminals.length && type === 'sale' && (values as any).card > 0) {
			setPosTerminalModalVisible(true);
			return;
		}

		try {
			await createInvoice();
		} catch (e) {
			console.log(e);
		}
	}

	useEffect(() => {
		const newValues = paymentMethods
			.filter((pm) => pm !== -1)
			.reduce((acc, curr, index) => {
				acc[PAYMENT_TYPE_MAP[curr]] = prefillPayment[index];
				return acc;
			}, {});

		form.setFieldsValue({ ...values, ...newValues, change: 0 });
		setValues(form.getFieldsValue());
	}, [prefillPayment]);

	useEffect(() => {
		if (visible) {
			const lastAdvance =
				addAdvancePayment ||
				advanceRefund ||
				closeAdvancePayment ||
				finalizeCloseAdvancePayment ||
				overrideCloseAdvance.current
					? (receipt.connectedReceipts || [])
							.filter(
								(cr) =>
									INVOICE_TYPE_FROM_STRING[cr.invoiceType] ===
									InvoiceType.ADVANCE
							)
							.pop()
					: null;
			const lastAdvanceSale =
				addAdvancePayment ||
				advanceRefund ||
				closeAdvancePayment ||
				finalizeCloseAdvancePayment ||
				overrideCloseAdvance.current
					? (receipt.connectedReceipts || [])
							.filter(
								(cr) =>
									INVOICE_TYPE_FROM_STRING[cr.invoiceType] ===
										InvoiceType.ADVANCE &&
									TRANSACTION_TYPE_FROM_STRING[cr.transactionType] ===
										TransactionType.SALE
							)
							.pop()
					: null;
			const newValues = {
				...values,
				invoiceType: InvoiceType.NORMAL,
				transactionType: TransactionType.SALE,
				...(receipt?.buyerId ? { buyerId: receipt.buyerId } : {}),
				...(copy
					? {
							buyerCostCenterId: receipt.buyerCostCenterId,
							invoiceType: InvoiceType.COPY,
							referentDocumentNumber: receipt.invoiceNumber,
							referentDocumentDT: receipt.sdcTime,
							change: receipt.paymentChange || 0,
							unknownAmountAdvance:
								lastAdvanceSale?.unknownAmountAdvance || false,
					  }
					: {}),
				...(addAdvancePayment
					? {
							buyerCostCenterId: lastAdvance.buyerCostCenterId,
							invoiceType: InvoiceType.ADVANCE,
							referentDocumentNumber: lastAdvance?.invoiceNumber,
							referentDocumentDT: moment(lastAdvance?.sdcTime),
							unknownAmountAdvance:
								lastAdvanceSale?.unknownAmountAdvance || false,
					  }
					: {}),
				...(type === 'refund'
					? {
							buyerCostCenterId: receipt.buyerCostCenterId,
							referentDocumentNumber: receipt.invoiceNumber,
							referentDocumentDT: receipt.sdcTime,
					  }
					: {}),
				...(advanceRefund
					? {
							invoiceType: InvoiceType.ADVANCE,

							...(voidMode
								? {
										buyerId: `${tin}`.length === 13 ? `11:${tin}` : `10:${tin}`,
										buyerCostCenterId: receipt.buyerCostCenterId,
										referentDocumentNumber: receipt.invoiceNumber,
										referentDocumentDT: moment(receipt.sdcTime),
								  }
								: {
										buyerCostCenterId: lastAdvance?.buyerCostCenterId,
										referentDocumentNumber: lastAdvance?.invoiceNumber,
										referentDocumentDT: moment(lastAdvance?.sdcTime),
								  }),
					  }
					: {}),
				...(closeAdvancePayment || overrideCloseAdvance.current
					? {
							invoiceType: InvoiceType.NORMAL,
							buyerCostCenterId: lastAdvance?.buyerCostCenterId,
							referentDocumentNumber: lastAdvance?.invoiceNumber,
							referentDocumentDT: moment(lastAdvance?.sdcTime),
							unknownAmountAdvance:
								lastAdvanceSale?.unknownAmountAdvance || false,
					  }
					: {}),
				...(finalizeCloseAdvancePayment || overrideCloseAdvance.current
					? {
							invoiceType: InvoiceType.NORMAL,
							buyerCostCenterId: receipt?.buyerCostCenterId,
							referentDocumentNumber: receipt?.invoiceNumber,
							referentDocumentDT: moment(receipt?.sdcTime),
							unknownAmountAdvance:
								lastAdvanceSale?.unknownAmountAdvance || false,
					  }
					: {}),
				...(finalizeProforma
					? {
							invoiceType: InvoiceType.NORMAL,
							buyerCostCenterId: receipt?.buyerCostCenterId,
							referentDocumentNumber: receipt.invoiceNumber,
							referentDocumentDT: moment(receipt.sdcTime),
					  }
					: {}),
				...(type === 'refund' ||
				values.transactionType === TransactionType.REFUND
					? { transactionType: TransactionType.REFUND }
					: {}),
				...(voidMode
					? {
							buyerId: `${tin}`.length === 13 ? `11:${tin}` : `10:${tin}`,
					  }
					: {}),
			};
			setValues(newValues);
			form.setFieldsValue(newValues);
		}
	}, [receipt, visible]);

	useEffect(() => {
		onValuesChange(values);
	}, [amount]);

	const fetchReferentDocument = useCallback(async () => {
		if (values.referentDocumentNumber) {
			setLoadingReferentDocument(true);
			try {
				const refDoc = await receipts.getByInvoiceNumber(
					values.referentDocumentNumber
				);

				if (refDoc) {
					const newValues = {
						...values,
						referentDocumentDT: refDoc.sdcTime,
						buyerCostCenterId: refDoc.buyerCostCenterId,
					};
					form.setFieldsValue(newValues);
					setValues(newValues);

					if (
						INVOICE_TYPE_FROM_STRING[refDoc.invoiceType] ===
							InvoiceType.ADVANCE &&
						TRANSACTION_TYPE_FROM_STRING[refDoc.transactionType] ===
							TransactionType.REFUND &&
						values.invoiceType === InvoiceType.NORMAL &&
						values.transactionType === TransactionType.SALE
					) {
						setReferentDocumentIsAdvanceRefund(true);
					}

					setReferentDocument(refDoc);
				}
			} catch (e) {
				console.log(e);
			} finally {
				setLoadingReferentDocument(false);
			}
		}
	}, [values.referentDocumentNumber]);

	function onValuesChange(newValues) {
		const updatedValues = { ...values, ...newValues };
		form.setFieldsValue(updatedValues);

		const discountPercentage = updatedValues.discount || 0;

		const discountedAmount =
			advanceRefund || finalizeCloseAdvancePayment || closeAdvancePayment
				? latestAmount.current
				: latestItems.current.reduce((prev, curr) => {
						return round(
							prev +
								round(
									round(
										curr.finalPrice -
											(curr.finalPrice * discountPercentage) / 100,
										2
									) * round(curr.quantity, 3),
									2
								),
							2
						);
				  }, 0);
		const total = getSum();
		const change = updatedValues.unknownAmountAdvance
			? 0
			: clamp(
					round(
						total +
							(referentDocument ? advPayments : advancePayments) -
							(advanceRefund ||
							finalizeCloseAdvancePayment ||
							closeAdvancePayment
								? latestAmount.current
								: discountedAmount),
						2
					),
					0,
					Infinity
			  );

		const remaining = clamp(
			round(
				discountedAmount -
					total -
					(!advanceRefund
						? referentDocument
							? advPayments
							: advancePayments
						: 0),
				2
			),
			0,
			Infinity
		);

		form.setFieldsValue({
			...updatedValues,
			change,
			discountedAmount,
			remaining,
		});
		setValues({
			...updatedValues,
			...form.getFieldsValue(),
			change,
			discountedAmount,
			remaining,
		});
		if (newValues.posTime) {
			setDate(moment(newValues.posTime));
		}
		if (typeof newValues.buyerCostCenterId === 'string') {
			if (
				newValues.buyerCostCenterId &&
				((taxFreeMode === 'exempt' &&
					(newValues.buyerCostCenterId.startsWith('20:') ||
						newValues.buyerCostCenterId.startsWith('21'))) ||
					newValues.buyerCostCenterId.startsWith('30:'))
			) {
				setTaxFree(true);
				setIsTaxFree(true);
			} else {
				setTaxFree(false);
				setIsTaxFree(false);
			}
		}

		form.setFields([
			{
				name: 'buyerId',
				errors: [],
			},
		]);
	}

	let title;
	if (copy) {
		title = 'Kopija računa';
	} else {
		title = type === 'sale' ? 'Plaćanje' : 'Refundacija';
	}
	let confirm;
	if (copy) {
		confirm = 'Napravi kopiju';
	} else if (voidMode) {
		confirm = 'Potvrdi storniranje';
	} else {
		confirm = type === 'sale' ? 'Potvrdi plaćanje' : 'Potvrdi refundaciju';
	}
	return (
		<Component
			centered
			width={1000}
			title={title}
			visible={visible}
			onOk={() => {
				form.submit();
			}}
			// zIndex={1500}
			onCancel={() => {
				closePayment();
			}}
			onClose={() => {
				closePayment();
			}}
			destroyOnClose
			footer={
				<>
					<Space className={styles.printModes}>
						{window.electron &&
							thermalPrinters.length > 0 &&
							store?.printMethod?.thermal && (
								<Checkbox
									checked={printThermal}
									onChange={(e) => {
										setPrintThermal(!printThermal);
									}}
									disabled={isLoading}
								>
									{screens.lg ? 'Štampa na termalnom štampaču' : 'Termalni'}
								</Checkbox>
							)}
						{store?.printMethod?.a4 && (
							<Checkbox
								checked={printA4}
								onChange={(e) => {
									setPrintA4(!printA4);
								}}
								disabled={isLoading}
							>
								{screens.lg ? 'Štampa na A4 štampaču' : 'A4'}
							</Checkbox>
						)}
						{store?.printMethod?.email && (
							<Checkbox
								checked={sendEmail}
								onChange={(e) => {
									setSendEmail(!sendEmail);
								}}
								disabled={isLoading}
							>
								{screens.lg ? 'Slanje na E-Mail' : 'E-Mail'}
							</Checkbox>
						)}
					</Space>
					<Space className={styles.buttons}>
						<Button onClick={() => closePayment()} disabled={isLoading}>
							Otkaži
						</Button>
						<Button
							onClick={form.submit}
							key="close"
							type="primary"
							disabled={isLoading}
						>
							{confirm}
						</Button>
					</Space>
					<div style={{ clear: 'both' }} />
				</>
			}
			// okButtonProps={{ disabled: completed || printing || waitForCard }}
			// cancelButtonProps={{ disabled: completed || printing || waitForCard }}
			// confirmLoading={printing}
		>
			{advanceRefund && !voidMode && (
				<Form.Item>
					<Alert
						type="warning"
						message={
							<>
								Refundaciju avansa koristite samo u slučaju povraćaja novca,
								odnosno ukoliko se kupac predomislio ili je avansni račun
								greškom izdat. Ukoliko želite da zatvorite avans, koristite
								opciju <strong>Zatvori avans</strong>
							</>
						}
					/>
				</Form.Item>
			)}
			{voidMode &&
				TRANSACTION_TYPE_FROM_STRING[receipt.transactionType] ===
					TransactionType.REFUND && (
					<Form.Item>
						<Alert
							type="warning"
							message={
								<>
									Storniranje refundacija je implementirano u skladu sa
									tehničkim vodičem, odnosno izdaje se isti račun refundacije sa
									Vašim PIB-om kao identifikacijom kupca, međutim, primetili smo
									da se na ovaj način, na ESF-u, poreska obaveza ponovo umanjuje
									za iznos refundacije. <br />
									<br />
									Uputili smo pitanje tehničkom timu Poreske uprave, te Vas
									molimo da, da ukoliko nije neophodno, sačekate sa storniranjem
									refundacija dok ne dobijemo odgovor od strane Poreske uprave.
								</>
							}
						/>
					</Form.Item>
				)}
			{values.transactionType === TransactionType.REFUND && type === 'sale' && (
				<Form.Item>
					<Alert
						type="warning"
						message={
							<>
								Ručnu refundaciju računa koristite u slučaju da je račun
								fiskalizovan pre početaka korišćenja aplikacije ili po starom
								modelu fiskalizacije, u suprotnom koristite opciju refundacije
								na detaljima računa kako bi aplikacija automatski povezala
								račune
							</>
						}
					/>
				</Form.Item>
			)}
			{values.invoiceType === InvoiceType.ADVANCE &&
				hasForeignCurrency &&
				!exchangeRates.groupedByDate[
					(values.posTime ? moment(values.posTime) : moment()).format(
						'YYYY-MM-DD'
					)
				] && (
					<>
						{(!values.posTime ||
							exchangeRates.getByDate(moment(values.posTime))) && (
							<Form.Item>
								<Alert
									type="warning"
									message={
										values.posTime
											? `Postoje artikli čija je cena u stranoj valuti, ali kursna lista za odabrani dan nije dostupna. Narodna Banka Srbije kursnu listu objavljuje radnim danima u 8:00, a ${APPLICATION_NAME} kursnu listu preuzima najkasnije 5 minuta od njenog objavljivanja. Primenjena je kursna lista dana ${exchangeRates
													.getByDate(moment(values.posTime))[1]
													.format('LL')}`
											: `Postoje artikli čija je cena u stranoj valuti, ali kursna lista za današnji dan nije dostupna. Narodna Banka Srbije kursnu listu objavljuje radnim danima u 8:00, a ${APPLICATION_NAME} kursnu listu preuzima najkasnije 5 minuta od njenog objavljivanja. Primenjena je kursna lista dana ${exchangeRates
													.getByDate(moment())[1]
													.format('LL')}`
									}
								/>
							</Form.Item>
						)}
						{values.posTime &&
							!exchangeRates.getByDate(moment(values.posTime)) && (
								<Form.Item>
									<Alert
										type="error"
										message="Nije moguće pronaći kursnu listu za odabrani dan. "
									/>
								</Form.Item>
							)}
					</>
				)}
			<Spin spinning={isLoading || loadingReferentDocument}>
				<Form
					{...formItemLayout}
					onValuesChange={onValuesChange}
					form={form}
					onFinish={submit}
					layout={screens.lg ? 'horizontal' : 'vertical'}
				>
					<Row gutter={24}>
						<Col span={screens.md ? 12 : 24}>
							<Form.Item label="Vrsta računa" name="invoiceType">
								<Select
									disabled={
										copy ||
										addAdvancePayment ||
										closeAdvancePayment ||
										finalizeCloseAdvancePayment ||
										finalizeProforma ||
										type === 'refund'
									}
								>
									{(copy
										? [
												{
													label: 'Kopija',
													value: InvoiceType.COPY,
												},
										  ]
										: invoiceType
									).map((type) => (
										<Select.Option key={type.value} value={type.value}>
											{type.label}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							{values.invoiceType === InvoiceType.ADVANCE &&
								values.transactionType === TransactionType.SALE && (
									<>
										<Form.Item
											name="unknownAmountAdvance"
											label={screens.lg ? ' ' : undefined}
											colon={false}
											valuePropName="checked"
											initialValue={store.defaultUnknownAmountAdvance}
										>
											<Checkbox disabled={addAdvancePayment || copy}>
												Avans bez poznatog konačnog iznosa
											</Checkbox>
										</Form.Item>
									</>
								)}
							<Form.Item label="Vrsta transackije" name="transactionType">
								<Select
									disabled={
										copy ||
										addAdvancePayment ||
										closeAdvancePayment ||
										finalizeCloseAdvancePayment ||
										finalizeProforma ||
										type === 'refund'
									}
								>
									{transactionType.map((type) => (
										<Select.Option key={type.value} value={type.value}>
											{type.label}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							{values.invoiceType === InvoiceType.ADVANCE &&
								values.transactionType === TransactionType.SALE && (
									<>
										<Form.Item label="Datum uplate" name="posTime">
											<DatePicker
												showTime
												disabled={voidMode}
												style={{ width: '100%' }}
												format="L HH:mm:ss"
											/>
										</Form.Item>
									</>
								)}
							<Form.Item
								rules={
									type === 'refund' ||
									(values.transactionType === TransactionType.REFUND &&
										(values.invoiceType !== InvoiceType.ADVANCE ||
											(values.invoiceType === InvoiceType.ADVANCE &&
												!(values.referentDocumentNumber || '')
													.toLowerCase()
													.startsWith('xxxxxxxx-xxxxxxxx-'))))
										? [
												{
													required: true,
													message:
														'Identifikacija kupca je obavezna kod refundacije',
												},
												{
													message: 'Morate uneti broj dokumenta',
													validator: async (rule, value) => {
														if (!value) {
															return Promise.resolve();
														}
														const [type, ...id] = value.split(':');
														if (type !== '' && id.join(':') === '') {
															return Promise.reject();
														}
														return Promise.resolve();
													},
												},
										  ]
										: []
								}
								extra={
									values.transactionType === TransactionType.REFUND &&
									values.invoiceType === InvoiceType.ADVANCE &&
									(values.referentDocumentNumber || '')
										.toLowerCase()
										.startsWith('xxxxxxxx-xxxxxxxx-')
										? 'Ukoliko vršite refundaciju u cilju zatvaranja avansa, identifikacija kupca nije obavezna'
										: null
								}
								label="Kupac"
								name="buyerId"
							>
								<BuyerInput readOnly={copy} disabled={voidMode} />
							</Form.Item>
							{values.buyerId && (
								<Form.Item
									label="Opciono polje kupca"
									name="buyerCostCenterId"
									rules={[
										{
											message: 'Morate uneti broj dokumenta',
											validator: async (rule, value) => {
												if (!value) {
													return Promise.resolve();
												}
												const [type, ...id] = value.split(':');
												if (type !== '' && id.join(':') === '') {
													return Promise.reject();
												}
												return Promise.resolve();
											},
										},
									]}
								>
									<BuyerCostCenterInput readOnly={copy} disabled={voidMode} />
								</Form.Item>
							)}
							<Divider />
							{!values.unknownAmountAdvance && (
								<>
									<Form.Item label="Ukupno">
										<Input
											value={`${numberFormat(amount, false, 2, true)}`}
											readOnly
											disabled={copy || voidMode}
										/>
									</Form.Item>

									{type === 'sale' &&
										!copy &&
										values.transactionType !== TransactionType.REFUND &&
										!closeAdvancePayment &&
										!addAdvancePayment &&
										!finalizeProforma &&
										!finalizeCloseAdvancePayment && (
											<>
												<Form.Item
													label="Popust"
													name="discount"
													initialValue={0}
													rules={[
														{
															type: 'number',
															validator: async (rule, value) => {
																if (value < 0 || value > 100) {
																	throw new Error(
																		'Popust mora biti između 0 i 100'
																	);
																}
															},
															message: 'Popust mora biti između 0 i 100',
														},
													]}
													extra="Popust se obračunava na pojedinačnim stavkama"
												>
													<Input
														type="number"
														suffix="%"
														style={{ width: '100%' }}
													/>
												</Form.Item>
												<Form.Item label="Ukupno sa popustom">
													<Input
														value={`${numberFormat(
															values.discountedAmount,
															false,
															2,
															true
														)}`}
														readOnly
														disabled={copy || voidMode}
													/>
												</Form.Item>
											</>
										)}
								</>
							)}
							{(closeAdvancePayment ||
								finalizeCloseAdvancePayment ||
								referentDocumentIsAdvanceRefund) && (
								<>
									<Form.Item label="Uplaćeno avansom">
										<Input
											value={`${numberFormat(
												referentDocument ? advPayments : advancePayments,
												false,
												2,
												true
											)}`}
											readOnly
											disabled={copy || voidMode}
										/>
									</Form.Item>
									{!values.unknownAmountAdvance && (
										<Form.Item label="Preostalo za uplatu">
											<Input
												value={`${numberFormat(
													values.remaining,
													false,
													2,
													true
												)}`}
												readOnly
												disabled={copy || voidMode}
											/>
										</Form.Item>
									)}
								</>
							)}
						</Col>
						<Col span={screens.md ? 12 : 24}>
							{visiblePaymentMethods.map((method, index) => (
								<Form.Item
									{...rightFormItemLayout}
									label={PAYMENT_TYPE_TEXT[method]}
									key={PAYMENT_TYPE_MAP[method]}
									name={PAYMENT_TYPE_MAP[method]}
								>
									<InputNumber
										onClick={(e) => e.currentTarget.select()}
										onWheel={(e) => {
											const element = e.currentTarget;
											const hasFocus = element.matches(':focus');
											element.blur();
											setTimeout(() => {
												if (hasFocus) {
													element.focus();
												}
											}, 0);
										}}
										className={styles.paymentMethodInput}
										type="number"
										tabIndex={index}
										controls={false}
										disabled={copy || finalizeCloseAdvancePayment || voidMode}
										ref={(input) => {
											if (!isInputFocused.current) {
												setTimeout(() => {
													if (!input) {
														return;
													}
													if (
														prefillPayment[index] > 0 ||
														(index === 0 &&
															prefillPayment.reduce((a, b) => a + b, 0) === 0)
													) {
														// input.input.focus();
														input.select();
														isInputFocused.current = true;
													}
												});
											}
										}}
										onPressEnter={() => {
											form.submit();
										}}
										addonAfter={
											!copy &&
											!values.unknownAmountAdvance && (
												<Button.Group>
													<Button
														onClick={() => {
															const vals = form.getFieldsValue(true);
															const newValue = round(
																Number(
																	closeAdvancePayment ||
																		addAdvancePayment ||
																		finalizeCloseAdvancePayment ||
																		referentDocumentIsAdvanceRefund
																		? clamp(
																				round(
																					values.discountedAmount -
																						(!advanceRefund
																							? referentDocument
																								? advPayments
																								: advancePayments
																							: 0),
																					2
																				),
																				0,
																				Infinity
																		  )
																		: values.discountedAmount
																) -
																	paymentMethods
																		.filter((pm) => pm !== method && pm !== -1)
																		.reduce(
																			(sum, pm) =>
																				sum +
																				Number(vals[PAYMENT_TYPE_MAP[pm]]),
																			0
																		),
																2
															);
															onValuesChange({
																[PAYMENT_TYPE_MAP[method]]: newValue,
															});
														}}
													>
														Ostatak
													</Button>
													<Button
														onClick={() => {
															onValuesChange({
																cash: 0,
																check: 0,
																card: 0,
																wiretransfer: 0,
																voucher: 0,
																mobilemoney: 0,
																other: 0,
																[PAYMENT_TYPE_MAP[method]]:
																	closeAdvancePayment ||
																	addAdvancePayment ||
																	finalizeCloseAdvancePayment ||
																	referentDocumentIsAdvanceRefund
																		? clamp(
																				round(
																					values.discountedAmount -
																						(!advanceRefund
																							? referentDocument
																								? advPayments
																								: advancePayments
																							: 0),
																					2
																				),
																				0,
																				Infinity
																		  )
																		: values.discountedAmount,
															});
														}}
													>
														Sve
													</Button>
												</Button.Group>
											)
										}
									/>
								</Form.Item>
							))}
							{!showHidden && hiddenPaymentMethods.length > 0 && (
								<Row>
									<Col span={screens.lg ? 12 : 0}></Col>
									<Col span={screens.lg ? 12 : 24}>
										<Button
											type="link"
											onClick={() => setShowHidden(true)}
											size="small"
											block
										>
											Prikaži sve načine plaćanja
										</Button>
									</Col>
								</Row>
							)}
							{hiddenPaymentMethods.map((method, index) => (
								<Form.Item
									{...rightFormItemLayout}
									label={PAYMENT_TYPE_TEXT[method]}
									key={PAYMENT_TYPE_MAP[method]}
									name={PAYMENT_TYPE_MAP[method]}
									className={!showHidden ? styles.hiddenPaymentMethod : ''}
								>
									<InputNumber
										style={{ width: '100%' }}
										className={styles.paymentMethodInput}
										onClick={(e) => e.currentTarget.select()}
										onWheel={(e) => {
											const element = e.currentTarget;
											const hasFocus = element.matches(':focus');
											element.blur();
											setTimeout(() => {
												if (hasFocus) {
													element.focus();
												}
											}, 0);
										}}
										controls={false}
										type="number"
										disabled={copy || finalizeCloseAdvancePayment || voidMode}
										onPressEnter={() => {
											form.submit();
										}}
										addonAfter={
											!copy &&
											!values.unknownAmountAdvance && (
												<Button.Group>
													<Button
														onClick={() => {
															const vals = form.getFieldsValue();
															const newValue = round(
																Number(
																	closeAdvancePayment ||
																		addAdvancePayment ||
																		finalizeCloseAdvancePayment ||
																		referentDocumentIsAdvanceRefund
																		? clamp(
																				round(
																					values.discountedAmount -
																						(!advanceRefund
																							? referentDocument
																								? advPayments
																								: advancePayments
																							: 0),
																					2
																				),
																				0,
																				Infinity
																		  )
																		: values.discountedAmount
																) -
																	paymentMethods
																		.filter((pm) => pm !== method && pm !== -1)
																		.reduce(
																			(sum, pm) =>
																				sum +
																				Number(vals[PAYMENT_TYPE_MAP[pm]]),
																			0
																		),
																2
															);
															onValuesChange({
																[PAYMENT_TYPE_MAP[method]]: newValue,
															});
														}}
													>
														Ostatak
													</Button>
													<Button
														onClick={() => {
															onValuesChange({
																cash: 0,
																check: 0,
																card: 0,
																wiretransfer: 0,
																voucher: 0,
																mobilemoney: 0,
																other: 0,
																[PAYMENT_TYPE_MAP[method]]:
																	closeAdvancePayment ||
																	addAdvancePayment ||
																	finalizeCloseAdvancePayment ||
																	referentDocumentIsAdvanceRefund
																		? clamp(
																				round(
																					values.discountedAmount -
																						(!advanceRefund
																							? referentDocument
																								? advPayments
																								: advancePayments
																							: 0),
																					2
																				),
																				0,
																				Infinity
																		  )
																		: values.discountedAmount,
															});
														}}
													>
														Sve
													</Button>
												</Button.Group>
											)
										}
									/>
								</Form.Item>
							))}

							{!values.unknownAmountAdvance && (
								<>
									{values.invoiceType === InvoiceType.ADVANCE && (
										<>
											<Divider />
											<Form.Item
												{...rightFormItemLayout}
												label="Preostali iznos"
												name="remaining"
											>
												<Input readOnly disabled={voidMode} />
											</Form.Item>
										</>
									)}
									{type === 'sale' &&
										values.transactionType !== TransactionType.REFUND && (
											<>
												<Divider />
												<Form.Item
													{...rightFormItemLayout}
													label="Povraćaj"
													name="change"
												>
													<Input
														readOnly
														disabled={copy || finalizeCloseAdvancePayment}
													/>
												</Form.Item>
											</>
										)}
								</>
							)}
						</Col>
					</Row>
					<Divider />
					{!showAdditionalFields && (
						<Row>
							<Col span={24}>
								<Button
									type="link"
									onClick={() => setShowAdditionalFields(true)}
									size="small"
									block
								>
									Prikaži napredna polja
								</Button>
							</Col>
						</Row>
					)}
					<Row
						className={!showAdditionalFields ? styles.hiddenPaymentMethod : ''}
					>
						<Col span={screens.lg ? 12 : 24}>
							<Form.Item
								name="referentDocumentNumber"
								label="Broj ref. dokumenta"
								rules={
									values.transactionType === TransactionType.REFUND ||
									values.referentDocumentDT
										? [{ required: true }]
										: []
								}
							>
								<Input
									disabled={
										copy ||
										addAdvancePayment ||
										closeAdvancePayment ||
										finalizeCloseAdvancePayment ||
										finalizeProforma ||
										type === 'refund'
									}
									onBlur={fetchReferentDocument}
								/>
							</Form.Item>
							<Form.Item
								name="referentDocumentDT"
								label="Datum ref. dokum."
								rules={
									values.transactionType === TransactionType.REFUND ||
									values.referentDocumentNumber
										? [{ required: true }]
										: []
								}
							>
								<DatePicker
									disabled={
										copy ||
										addAdvancePayment ||
										closeAdvancePayment ||
										finalizeCloseAdvancePayment ||
										finalizeProforma ||
										type === 'refund'
									}
									format="L HH:mm:ss"
									showTime
									style={{ width: '100%' }}
								/>
							</Form.Item>
						</Col>
						<Col span={screens.lg ? 12 : 24}>
							<Form.Item name="additionalText" label="Dodatni tekst">
								<Input.TextArea style={{ height: 88 }} />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Spin>

			<EmailModal
				visible={emailModalVisible}
				onCancel={() => setEmailModalVisible(false)}
				onOk={(email) => {
					setEmailModalVisible(false);
					createInvoice({ email });
				}}
			/>
			<AdvanceSpecificationDrawer
				visible={advanceSpecificationDrawerVisible}
				labels={advanceSpecificationLabels}
				sum={advanceSpecificationSum}
				onCancel={() => {
					setAdvanceSpecificationDrawerVisible(false);
					advanceSpecificationDeferred.reject();
				}}
				onSave={(labels) => {
					setAdvanceSpecificationDrawerVisible(false);
					advanceSpecificationDeferred.resolve(labels);
				}}
			/>
			<AdvanceFinalizeSpecificationDrawer
				visible={advanceFinalizeSpecificationDrawerVisible}
				labels={advanceFinalizeSpecificationLabels}
				items={advanceFinalizeSpecificationItems}
				sum={advanceSpecificationSum}
				onCancel={() => {
					setAdvanceFinalizeSpecificationDrawerVisible(false);
					advanceSpecificationDeferred.reject();
				}}
				onSave={(labels) => {
					setAdvanceFinalizeSpecificationDrawerVisible(false);
					advanceSpecificationDeferred.resolve(labels);
				}}
			/>
			<PosTerminalModal
				visible={posTerminalModalVisible}
				onCancel={() => setPosTerminalModalVisible(false)}
				amount={round((values as any).card, 2)}
				onOk={async (terminal) => {
					try {
						await createInvoice();
					} catch (e) {
						console.log(e);
					}
				}}
			/>
			{(!storeId || storeId === ' ') && <SelectStore />}
		</Component>
	);
}

export default observer(PaymentModal);
