import { Typography } from 'antd';

import Logo from '../../../../components/Logo';
import {
	APPLICATION_NAME,
	APPLICATION_VERSION,
} from '../../../../constants/application';
import stores from '../../../../stores/index.mobx';
import styles from './About.module.less';
function About() {
	return (
		<div className={styles.wrapper}>
			<p>
				<Logo
					color="dark"
					version={`${APPLICATION_VERSION}${
						window.electron
							? ` (verzija klijenta: ${
									window.electron.clientVersion || '1.x.x'
							  })`
							: ''
					}`}
				/>
				<small>
					<strong>ID klijenta:</strong>{' '}
					<Typography.Text copyable>
						{stores.application.clientId}
					</Typography.Text>
				</small>
			</p>
			<p>
				<strong>{APPLICATION_NAME}</strong> je sistem za upravljanje
				maloprodajnim objektima. Implementiran je prema zakonu o fiskalizaciji i
				tehničkom vodiču koji je propisala Poreska uprava Republike Srbije.
			</p>
			<div className="ant-descriptions-header">
				<div className="ant-descriptions-title">Kontakt</div>
			</div>
			<p>
				<strong>Naziv firme:</strong> Dejan Velimirović Agencija za računarsko
				programiranje DVSoftware Bela Crkva
				<br />
				<strong>Sedište firme:</strong> Proleterska 46, 26340 Bela Crkva
				<br />
				<strong>Kontakt telefon:</strong>{' '}
				{process.env.REACT_APP_APP_NAME === 'ibuddy'
					? '069 622 422'
					: '060 1755 733'}
				<br />
				<strong>E-Mail:</strong>{' '}
				{process.env.REACT_APP_APP_NAME === 'ibuddy' ? (
					<a href="mailto:miroslav.petrovic@icloud.com">
						miroslav.petrovic@icloud.com
					</a>
				) : (
					<a href="mailto:info@dvsoftware.rs">info@dvsoftware.rs</a>
				)}
				<br />
				<strong>Web sajt:</strong>{' '}
				<a href="https://dvsoftware.rs" target="_blank">
					{process.env.REACT_APP_APP_NAME === 'ibuddy'
						? 'https://ibuddy.rs'
						: 'https://dvsoftware.rs'}
				</a>
				<br />
			</p>
			<div className="ant-descriptions-header">
				<div className="ant-descriptions-title">Istorija verzija</div>
			</div>
			<p>
				{process.env.REACT_APP_APP_NAME === 'ibuddy' ? (
					<>
						<strong>1.3.2</strong> - 07.07.2023.
						<br />
						<ul>
							<li>
								🍾&nbsp;Prikaz načina plaćanja po računu na pregledu poslednjih
								računa
							</li>
							<li>
								🐛&nbsp;Svaka instalacija sada ima unikatni clientId koji se
								može koristiti za pristup putem API-ja
							</li>
						</ul>
						<strong>1.3.1</strong> - 05.07.2023.
						<br />
						<ul>
							<li>
								🍾&nbsp;Automatsko popunjavanje šifre delatnosti prilikom
								registracije
							</li>
							<li>🍾&nbsp;Štampa ostvarenog popusta na fiskalnom računu</li>
							<li>
								🍾&nbsp;PDF fakture se sada šalje kao prilog na sistem e-Faktura
							</li>
							<li>
								🍾&nbsp;Fakture poslate preko sistema e-Faktura su obeležene
								ikonicom na listi faktura
							</li>
							<li>
								🍾&nbsp;Sažetak iznosa fakture je sada vidljiv prilikom njenog
								kreiranja
							</li>
							<li>🍾&nbsp;Prikaz načina plaćanja po računu na pregledu dana</li>

							<li>
								🐛&nbsp;Računi su sada sortirani po vremenu na pregledu dana
							</li>
							<li>
								🐛&nbsp;Isravljeno računanje prethodnih avansa i popusta na
								fakturama
							</li>
							<li>
								🐛&nbsp;Popravljen obračun popusta kada se fiskalni račun šalje
								na e-mail
							</li>
							<li>
								🐛&nbsp;Popravljena štampa identifikacione oznake na fakturama
							</li>
							<li>🐛&nbsp;Popravljeno slanje popusta na sistem e-Faktura</li>
							<li>🐛&nbsp;Popravljeno slanje napomene na sistem e-Faktura</li>
							<li>
								🐛&nbsp;Popravljen uvoz informacija o preduzeću sa servisa NBS
								kada matični broj počinje sa 0
							</li>
							<li>🐛&nbsp;Popravljen uvoz KPO knjige sa Pausal aplikacije</li>
						</ul>
						<strong>1.3.0</strong> - 02.07.2023.
						<br />
						<ul>
							<li>🍾&nbsp;KPO knjiga</li>
							<li>🍾&nbsp;Omogućeno storniranje faktura</li>
							<li>🍾&nbsp;Omogućeno dodavanje priloga na fakturama</li>
							<li>
								🍾&nbsp;Dodata mogućnost za unos podrazumevane valute plaćanja
							</li>
							<li>
								🍾&nbsp;Dodata mogućnost izbora tekućih računa podrazumevanih za
								načine plaćanja na fakturi
							</li>
							<li>🐛&nbsp;Popravljena izmena tekućeg računa</li>
						</ul>
						<strong>1.2.3</strong> - 25.06.2023.
						<br />
						<ul>
							<li>🍾&nbsp;Podrška za PeriPage termalne štampače</li>
						</ul>
						<strong>1.2.2</strong> - 19.06.2023.
						<br />
						<ul>
							<li>
								🍾&nbsp;Mogućnost izbora protokola prilikom dodavanja termalnog
								štampača
							</li>
						</ul>
						<strong>1.2.1</strong> - 12.06.2023.
						<br />
						<ul>
							<li>
								🍾&nbsp;Mogućnost podrazumevanog podešavanja za avans bez
								poznatog konačnog iznosa
							</li>
							<li>
								🍾&nbsp;Definisanje popusta po kategoriji, artiklu, vremenskom
								periodu...
							</li>
							<li>🐛&nbsp;Popravljen prikaz fiskalnog računa u aplikaciji</li>
							<li>
								🐛&nbsp;Dodatan tekst se sada ispisuje na preuzetoj verziji
								fiskalnog računa
							</li>
						</ul>
						<strong>1.2.0</strong> - 09.06.2023.
						<br />
						<ul>
							<li>
								🍾&nbsp;Mogućnost izrade i slanja faktura putem sistema
								e-Faktura i putem E-maila
							</li>
							<li>🍾&nbsp;Razdvojena su polja za adresu kompanije</li>
							<li>
								🍾&nbsp;Prilikom registracije se sada automatski kreira prodajno
								mesto
							</li>
							<li>🍾&nbsp;NBS IPS QR na dijalogu za unos plaćanja fakture</li>
							<li>🍾&nbsp;Mogućnost dodavanja lokacija partnerima</li>
							<li>🐛&nbsp;Popravljen problem sa brisanjem opisa kategorije</li>
							<li>🐛&nbsp;Popravljene dozvole za modul Partneri</li>
							<li>🐛&nbsp;Popravljen uvoz pojedinih e-faktura</li>
							<li>
								🐛&nbsp;Popravljen povremen prikaz pogrešnih boja na grafikonu
							</li>
							<li>
								🐛&nbsp;Ispravljeno ponašanje izbora vremenskog okvira na
								periodičnom izveštaju
							</li>
							<li>💄&nbsp;Redizajnirana stranica za prijavljivanje</li>
							<li>💄&nbsp;Redizajnirana komponenta za unos tekućih računa</li>
						</ul>
						<strong>1.1.0</strong> - 01.06.2023.
						<br />
						<ul>
							<li>
								🍾&nbsp;Dodata mogućnost da se na računu više puta doda isti
								artikal, za artikle na kojima je ta opcija omogućena
							</li>
							<li>
								🍾&nbsp;Dodata mogućnost štampe dodatnog teksta nakon fiskalnog
								računa
							</li>
							<li>
								🍾&nbsp;Dodata mogućnost za izdavanje avansnih računa bez
								poznate konačne vrednosti
							</li>
							<li>💄&nbsp; Kozmetičke izmene prozora za plaćanje</li>
							<li>
								🐛&nbsp;Popravljeno dodavanje artikla sa novokreiranom
								kategorijom
							</li>
						</ul>
						<strong>1.0.19</strong> - 08.05.2023.
						<br />
						<ul>
							<li>🍾&nbsp;Omogućeno dodavanje popusta na celom računu</li>
						</ul>
						<strong>1.0.18</strong> - 20.03.2023.
						<br />
						<ul>
							<li>
								🐛&nbsp;U pojedinim situacijama se na POS terminal slala
								vrednost sa više od 2 decimale, što je rezultiralo odbijanjem
								transakcije
							</li>
						</ul>
						<strong>1.0.17</strong> - 16.03.2023.
						<br />
						<ul>
							<li>
								🐛&nbsp;Popust se više ne gubi ponovnim kucanjem istog artikla
							</li>
						</ul>
						<strong>1.0.16</strong> - 07.03.2023.
						<br />
						<ul>
							<li>
								🍾&nbsp;Omogućena pretraga po opisu proizvoda (potrebno je
								uključiti u podešavanjima preduzeća)
							</li>
							<li>
								🐛&nbsp;Popravljen problem sa izdavanjem kopije refundacije
							</li>
						</ul>
						<strong>1.0.15</strong> - 06.03.2023.
						<br />
						<ul>
							<li>
								🐛&nbsp;Popravljen unos cene za usluge i digitalne proizvode na
								varijantama
							</li>
						</ul>
						<strong>1.0.14</strong> - 01.03.2023.
						<br />
						<ul>
							<li>
								🍾&nbsp;Omogućen unos referentnog broja računa prilikom
								izdavanja računa
							</li>
							<li>
								🐛&nbsp;Sprečeno izdavanje refundacije avansa ukoliko je iznos
								refundacije 0
							</li>
						</ul>
						<strong>1.0.13</strong> - 01.03.2023.
						<br />
						<ul>
							<li>
								🍾&nbsp;Omogućeno dodavanje avansne uplate na račun koji je
								izdat pre početka primene eFiskalizacije
							</li>
						</ul>
						<strong>1.0.12</strong> - 25.02.2023.
						<br />
						<ul>
							<li>
								🐛&nbsp;Rešen problem duple inicijalizacije V-PFR biblioteke
							</li>
							<li>🐛&nbsp;Popravljen editor opisa artikla</li>
						</ul>
						<strong>1.0.11</strong> - 21.02.2023.
						<br />
						<ul>
							<li>
								🐛&nbsp;Popravljen problem sa slanjem računa na server posle
								neuspešne sinhronizacije
							</li>
						</ul>
						<strong>1.0.10</strong> - 16.02.2023.
						<br />
						<ul>
							<li>
								🐛&nbsp;Popravljeno preskakanje simbola "," i "." prilikom unosa
								šifre
							</li>
							<li>🍾&nbsp;Obaveštenje o novoj verziji aplikacije</li>
						</ul>
						<strong>1.0.9</strong> - 16.02.2023.
						<br />
						<ul>
							<li>
								🐛&nbsp;Popravljen problem sa nepokretanjem sinhronizacije
								neposlatih računa
							</li>
						</ul>
						<strong>1.0.8</strong> - 12.02.2023.
						<br />
						<ul>
							<li>
								🐛&nbsp;Bolje izveštavanje o greškama prilikom izdavanja računa
							</li>
						</ul>
						<strong>1.0.7</strong> - 08.02.2023.
						<br />
						<ul>
							<li>🐛&nbsp;Popravljen problem sа pojedinim dozvolama</li>
						</ul>
						<strong>1.0.6</strong> - 07.02.2023.
						<br />
						<ul>
							<li>
								🐛&nbsp;Popravljen problem sa preskakanjem karaktera dobijenih
								sa bar kod čitača u režimu emulacije tastature
							</li>
						</ul>
						<strong>1.0.5</strong> - 07.02.2023.
						<br />
						<ul>
							<li>
								🐛&nbsp;Popravljen problem sa obrisanim korisnicima koji su
								ostali ulogovani
							</li>
						</ul>
						<strong>1.0.4</strong> - 06.02.2023.
						<br />
						<ul>
							<li>
								🐛&nbsp;Popravljen problem sa štampom fonta variabilne širine na
								termalnom štampaču
							</li>
						</ul>
						<strong>1.0.3</strong> - 03.02.2023.
						<br />
						<ul>
							<li>
								🐛&nbsp;Popravljen problem sa slanjem pogrešnog računa na e-mail
							</li>
						</ul>
						<strong>1.0.2</strong> - 18.01.2023.
						<br />
						<ul>
							<li>
								🐛&nbsp;Popravljen problem sa povremenim štampanjem/slanjem
								prethodnog računa umesto upravo izdatog
							</li>
						</ul>
						<strong>1.0.1</strong> - 11.01.2023.
						<br />
						<ul>
							<li>🐛&nbsp;Popravljen problem sa izmenom korisnika</li>
						</ul>
						<strong>1.0.0</strong> - 30.12.2022.
						<br />
						<ul>
							<li>🍾&nbsp; Inicijalna verzija</li>
						</ul>
					</>
				) : (
					<>
						<strong>2.4.2</strong> - 07.07.2023.
						<br />
						<ul>
							<li>
								🍾&nbsp;Prikaz načina plaćanja po računu na pregledu poslednjih
								računa
							</li>
							<li>
								🐛&nbsp;Svaka instalacija sada ima unikatni clientId koji se
								može koristiti za pristup putem API-ja
							</li>
						</ul>
						<strong>2.4.1</strong> - 05.07.2023.
						<br />
						<ul>
							<li>
								🍾&nbsp;Automatsko popunjavanje šifre delatnosti prilikom
								registracije
							</li>
							<li>🍾&nbsp;Štampa ostvarenog popusta na fiskalnom računu</li>
							<li>
								🍾&nbsp;PDF fakture se sada šalje kao prilog na sistem e-Faktura
							</li>
							<li>
								🍾&nbsp;Fakture poslate preko sistema e-Faktura su obeležene
								ikonicom na listi faktura
							</li>
							<li>
								🍾&nbsp;Sažetak iznosa fakture je sada vidljiv prilikom njenog
								kreiranja
							</li>
							<li>🍾&nbsp;Prikaz načina plaćanja po računu na pregledu dana</li>

							<li>
								🐛&nbsp;Računi su sada sortirani po vremenu na pregledu dana
							</li>
							<li>
								🐛&nbsp;Isravljeno računanje prethodnih avansa i popusta na
								fakturama
							</li>
							<li>
								🐛&nbsp;Popravljen obračun popusta kada se fiskalni račun šalje
								na e-mail
							</li>
							<li>
								🐛&nbsp;Popravljena štampa identifikacione oznake na fakturama
							</li>
							<li>🐛&nbsp;Popravljeno slanje popusta na sistem e-Faktura</li>
							<li>🐛&nbsp;Popravljeno slanje napomene na sistem e-Faktura</li>
							<li>
								🐛&nbsp;Popravljen uvoz informacija o preduzeću sa servisa NBS
								kada matični broj počinje sa 0
							</li>
							<li>🐛&nbsp;Popravljen uvoz KPO knjige sa Pausal aplikacije</li>
						</ul>
						<strong>2.4.0</strong> - 02.07.2023.
						<br />
						<ul>
							<li>🍾&nbsp;KPO knjiga</li>
							<li>🍾&nbsp;Omogućeno storniranje faktura</li>
							<li>🍾&nbsp;Omogućeno dodavanje priloga na fakturama</li>
							<li>
								🍾&nbsp;Dodata mogućnost za unos podrazumevane valute plaćanja
							</li>
							<li>
								🍾&nbsp;Dodata mogućnost izbora tekućih računa podrazumevanih za
								načine plaćanja na fakturi
							</li>
							<li>🐛&nbsp;Popravljena izmena tekućeg računa</li>
						</ul>
						<strong>2.3.3</strong> - 25.06.2023.
						<br />
						<ul>
							<li>🍾&nbsp;Podrška za PeriPage termalne štampače</li>
						</ul>
						<strong>2.3.2</strong> - 19.06.2023.
						<br />
						<ul>
							<li>
								🍾&nbsp;Mogućnost izbora protokola prilikom dodavanja termalnog
								štampača
							</li>
						</ul>
						<strong>2.3.1</strong> - 12.06.2023.
						<br />
						<ul>
							<li>
								🍾&nbsp;Mogućnost podrazumevanog podešavanja za avans bez
								poznatog konačnog iznosa
							</li>
							<li>
								🍾&nbsp;Definisanje popusta po kategoriji, artiklu, vremenskom
								periodu...
							</li>
							<li>🐛&nbsp;Popravljen prikaz fiskalnog računa u aplikaciji</li>
							<li>
								🐛&nbsp;Dodatan tekst se sada ispisuje na preuzetoj verziji
								fiskalnog računa
							</li>
						</ul>
						<strong>2.3.0</strong> - 09.06.2023.
						<br />
						<ul>
							<li>
								🍾&nbsp;Mogućnost izrade i slanja faktura putem sistema
								e-Faktura i putem E-maila
							</li>
							<li>🍾&nbsp;Razdvojena su polja za adresu kompanije</li>
							<li>
								🍾&nbsp;Prilikom registracije se sada automatski kreira prodajno
								mesto
							</li>
							<li>🍾&nbsp;NBS IPS QR na dijalogu za unos plaćanja fakture</li>
							<li>🍾&nbsp;Mogućnost dodavanja lokacija partnerima</li>
							<li>🐛&nbsp;Popravljen problem sa brisanjem opisa kategorije</li>
							<li>🐛&nbsp;Popravljene dozvole za modul Partneri</li>
							<li>🐛&nbsp;Popravljen uvoz pojedinih e-faktura</li>
							<li>
								🐛&nbsp;Popravljen povremen prikaz pogrešnih boja na grafikonu
							</li>
							<li>
								🐛&nbsp;Ispravljeno ponašanje izbora vremenskog okvira na
								periodičnom izveštaju
							</li>
							<li>💄&nbsp;Redizajnirana stranica za prijavljivanje</li>
							<li>💄&nbsp;Redizajnirana komponenta za unos tekućih računa</li>
						</ul>
						<strong>2.2.0</strong> - 01.06.2023.
						<br />
						<ul>
							<li>
								🍾&nbsp;Dodata mogućnost da se na računu više puta doda isti
								artikal, za artikle na kojima je ta opcija omogućena
							</li>
							<li>
								🍾&nbsp;Dodata mogućnost štampe dodatnog teksta nakon fiskalnog
								računa
							</li>
							<li>
								🍾&nbsp;Dodata mogućnost za izdavanje avansnih računa bez
								poznate konačne vrednosti
							</li>
							<li>💄&nbsp; Kozmetičke izmene prozora za plaćanje</li>
							<li>
								🐛&nbsp;Popravljeno dodavanje artikla sa novokreiranom
								kategorijom
							</li>
						</ul>
						<strong>2.1.19</strong> - 08.05.2023.
						<br />
						<ul>
							<li>🍾&nbsp;Omogućeno dodavanje popusta na celom računu</li>
						</ul>
						<strong>2.1.18</strong> - 20.03.2023.
						<br />
						<ul>
							<li>
								🐛&nbsp;U pojedinim situacijama se na POS terminal slala
								vrednost sa više od 2 decimale, što je rezultiralo odbijanjem
								transakcije
							</li>
						</ul>
						<strong>2.1.17</strong> - 16.03.2023.
						<br />
						<ul>
							<li>
								🐛&nbsp;Popust se više ne gubi ponovnim kucanjem istog artikla
							</li>
						</ul>
						<strong>2.1.16</strong> - 07.03.2023.
						<br />
						<ul>
							<li>
								🍾&nbsp;Omogućena pretraga po opisu proizvoda (potrebno je
								uključiti u podešavanjima preduzeća)
							</li>
							<li>
								🐛&nbsp;Popravljen problem sa izdavanjem kopije refundacije
							</li>
						</ul>
						<strong>2.1.15</strong> - 06.03.2023.
						<br />
						<ul>
							<li>
								🐛&nbsp;Popravljen unos cene za usluge i digitalne proizvode na
								varijantama
							</li>
						</ul>
						<strong>2.1.14</strong> - 01.03.2023.
						<br />
						<ul>
							<li>
								🍾&nbsp;Omogućen unos referentnog broja računa prilikom
								izdavanja računa
							</li>
							<li>
								🐛&nbsp;Sprečeno izdavanje refundacije avansa ukoliko je iznos
								refundacije 0
							</li>
						</ul>
						<strong>2.1.13</strong> - 01.03.2023.
						<br />
						<ul>
							<li>
								🍾&nbsp;Omogućeno dodavanje avansne uplate na račun koji je
								izdat pre početka primene eFiskalizacije
							</li>
						</ul>
						<strong>2.1.12</strong> - 25.02.2023.
						<br />
						<ul>
							<li>
								🐛&nbsp;Rešen problem duple inicijalizacije V-PFR biblioteke
							</li>
							<li>🐛&nbsp;Popravljen editor opisa artikla</li>
						</ul>
						<strong>2.1.11</strong> - 21.02.2023.
						<br />
						<ul>
							<li>
								🐛&nbsp;Popravljen problem sa slanjem računa na server posle
								neuspešne sinhronizacije
							</li>
						</ul>
						<strong>2.1.10</strong> - 16.02.2023.
						<br />
						<ul>
							<li>
								🐛&nbsp;Popravljeno preskakanje simbola "," i "." prilikom unosa
								šifre
							</li>
							<li>🍾&nbsp;Obaveštenje o novoj verziji aplikacije</li>
						</ul>
						<strong>2.1.9</strong> - 16.02.2023.
						<br />
						<ul>
							<li>
								🐛&nbsp;Popravljen problem sa nepokretanjem sinhronizacije
								neposlatih računa
							</li>
						</ul>
						<strong>2.1.8</strong> - 12.02.2023.
						<br />
						<ul>
							<li>🐛&nbsp;Popravljen problem sа pojedinim dozvolama</li>
						</ul>
						<strong>2.1.7</strong> - 08.02.2023.
						<br />
						<ul>
							<li>
								🐛&nbsp;Bolje izveštavanje o greškama prilikom izdavanja računa
							</li>
						</ul>
						<strong>2.1.6</strong> - 07.02.2023.
						<br />
						<ul>
							<li>
								🐛&nbsp;Popravljen problem sa preskakanjem karaktera dobijenih
								sa bar kod čitača u režimu emulacije tastature
							</li>
						</ul>
						<strong>2.1.5</strong> - 07.02.2023.
						<br />
						<ul>
							<li>
								🐛&nbsp;Popravljen problem sa obrisanim korisnicima koji su
								ostali ulogovani
							</li>
						</ul>
						<strong>2.1.4</strong> - 06.02.2023.
						<br />
						<ul>
							<li>
								🐛&nbsp;Popravljen problem sa štampom fonta variabilne širine na
								termalnom štampaču
							</li>
						</ul>
						<strong>2.1.3</strong> - 03.02.2023.
						<br />
						<ul>
							<li>
								🐛&nbsp;Popravljen problem sa slanjem pogrešnog računa na e-mail
							</li>
						</ul>
						<strong>2.1.2</strong> - 18.01.2023.
						<br />
						<ul>
							<li>
								🐛&nbsp;Popravljen problem sa povremenim štampanjem/slanjem
								prethodnog računa umesto upravo izdatog
							</li>
						</ul>
						<strong>2.1.1</strong> - 11.01.2023.
						<br />
						<ul>
							<li>🐛&nbsp;Popravljen problem sa izmenom korisnika</li>
						</ul>
						<strong>2.1.0</strong> - 30.12.2022.
						<br />
						<ul>
							<li>🍾&nbsp;Inicijalna podrška za aplikacije</li>
							<li>🍾&nbsp;Prijem e-faktura</li>
							<li>🍾&nbsp;Moguće je isključiti zvučne efekte</li>
							<li>
								🍾&nbsp;Omogućeno zatvaranje avansnih računa koji su izdati pre
								početka primene eFiskalizacije
							</li>
							<li>💄&nbsp;Nova ikonica</li>
							<li>
								💄&nbsp;Poboljšan korisnički interfejs u slučaju greške prilikom
								naplate karticama
							</li>
							<li>🐛&nbsp;Popravljena komponenta za unos bankovnih računa</li>
							<li>
								🐛&nbsp;Popravljena komponenta za unos kontakt informacija
								partnera
							</li>
							<li>🐛&nbsp;Popravljen unos kategorija za artikal</li>
						</ul>
						<strong>2.0.5</strong> - 26.12.2022.
						<br />
						<ul>
							<li>
								🐛&nbsp;Popravljen problem sa refundacijom računa koji su
								nastali pre početka primene eFiskalizacije.
							</li>
						</ul>
						<strong>2.0.4</strong> - 20.12.2022.
						<br />
						<ul>
							<li>
								🐛&nbsp;Na uredjajima sa ekranom na dodir nije bilo moguće
								otvoriti pod menije
							</li>
						</ul>
						<strong>2.0.3</strong> - 19.12.2022.
						<br />
						<ul>
							<li>🍾&nbsp;Akcije su sada fiksirane sa desne strane tabele</li>
							<li>
								🐛&nbsp;Popravljen unos cena sa decimalama na pojedinim
								uređajima i operativnim sistemima
							</li>
							<li>
								🐛&nbsp;Popravljen problem sa pogrešnim sabiranjem cene ukoliko
								je prilikom izmene stavke računa uneta cena sa decimalama
							</li>
							<li>
								🐛&nbsp;Na računu štampanom sa varijabilnom širinom fonta je
								potpis kupca bio na pogrešnom mestu
							</li>
						</ul>
						<strong>2.0.2</strong> - 17.12.2022.
						<br />
						<ul>
							<li>
								🐛&nbsp;Popravljen problem sa dozvolama za kreiranje artikala
							</li>
						</ul>
						<strong>2.0.1</strong> - 15.12.2022.
						<br />
						<ul>
							<li>
								🐛&nbsp;Popravljena štampa fiskalnih računa u tamnom režimu
								aplikacije
							</li>
						</ul>
						<strong>2.0.0</strong> - 14.12.2022.
						<br />
						<ul>
							<li>⚡&nbsp;Poboljšane performase cele aplikacije</li>
							<li>⚡&nbsp;Zamenjen WYSIWYG editor za manji i brži</li>
							<li>⚡&nbsp;Poboljšane performanse pretrage</li>
							<li>💄&nbsp; Kozmetičke izmene</li>
							<li>
								🍾&nbsp;Aplikaciju je sada moguće koristiti i putem web
								pregledača na mobilnim uređajima.
							</li>
							<li>🍾&nbsp;Aplikacija se sada prilagođava veličini ekrana</li>
							<li>🍾&nbsp;Podrška za tamni režim</li>
							<li>
								🍾&nbsp;Grafikon na početnoj strani sada prikazuje više
								informacija
							</li>
							<li>
								🍾&nbsp;Aplikacija sada održava konstantnu vezu sa serverom kako
								bi se informacije ažurirale u realnom vremenu
							</li>
							<li>
								🍾&nbsp;Poboljšano prikazivanje grešaka koje aplikacija dobija
								od PFR-a
							</li>
							<li>
								🍾&nbsp;Izbor trenutnog prodajnog mesta je premešten u
								korisnički meni
							</li>
							<li>
								🍾&nbsp;Zvučni signali prilikom dodavanja artikala na račun,
								brisanja artikala sa računa ili ukoliko artikal nije pronađen
							</li>
							<li>🍾&nbsp;Izdavanje računa putem API servisa</li>
							<li>
								🍾&nbsp;Mogućnost štampanja fiskalnog računa latiničnim pismom i
								na engleskom jeziku
							</li>
							<li>
								🍾&nbsp;Mogućnost štampanja fiskalnog računa fontom varijabilne
								širine
							</li>
							<li>
								🍾&nbsp;Omogućeno dodavanje kategorija iz prozora za dodavanje
								artikala
							</li>
							<li>
								🍾&nbsp;Prazan prostor oko logo-a na fiskalnom računu se sada
								uklanja
							</li>
							<li>🍾&nbsp;Omogućena ponovna štampa fiskalnog računa</li>
							<li>
								🍾&nbsp;Omogućeno naknadno slanje fiskalnog računa na e-mail
							</li>
							<li>
								🍾&nbsp;Omogućeno preuzimanje fiskalnog računa u PDF formatu
							</li>
							<li>
								🍾&nbsp;Ukoliko je odabran tip kupca, sada je obavezan i unos
								broja dokumenta
							</li>
							<li>
								🍾&nbsp;Ukoliko je odabran tip opcionog polja kupca, sada je
								obavezan i unos broja dokumenta
							</li>
							<li>🍾&nbsp;Promenjen način unosa varijanti</li>
							<li>
								🍾&nbsp;Referentni dokument je sada linkovan na listi računa
							</li>
							<li>🍾&nbsp;Sada je moguće obrisati logo preduzeća</li>
							<li>
								🐛&nbsp;Promenjen način veze sa POS terminalom, tako da se sada
								veza ostvaruje kada je potrebno izvršiti naplatu, i nakon toga
								se zatvara. Ranije je veza ostajala otvorena, što je uzrokovalo
								probleme nakon dužeg vremena rada aplikacije.
							</li>
							<li>
								🐛&nbsp;Popravljen problem sa unosom PIN koda bezbednosnog
								elementa ukoliko se korisnik nalazi na kasi.
							</li>
							<li>
								🐛&nbsp;Poboljšano prikazivanje iskačućih panela, odnosno njihov
								redosled prikaza se više neće obrnuti tako da noviji otvoreni
								panel bude iza starijeg, što se u pojedinim slučajevima dešavalo
							</li>
							<li>
								🐛&nbsp;Promenjen način slanja iznosa gotovine PFR-u, tako da se
								sada šalje stvarno naplaćen iznos, odnosno umanjen za povraćaj
							</li>
							<li>
								🐛&nbsp;Popravljeno štampanje periodičnog izveštaja na termalnom
								štampaču ukoliko je uključena opcija štampanja iznosa po načinu
								plaćanja
							</li>
						</ul>
						<strong>1.2.19</strong> - 29.10.2022.
						<br />
						<ul>
							<li>
								🐛&nbsp;Ispravljen još jedan problem sa obračunom PDV-a kod
								avansnih računa ukoliko je odabran SNPDV ili LNPDV kao tip
								opcionog polja kupca
							</li>
						</ul>
						<strong>1.2.18</strong> - 27.09.2022.
						<br />
						<ul>
							<li>
								🐛&nbsp;Ispravljen problem sa obračunom PDV-a kod avansnih
								računa ukoliko je odabran SNPDV ili LNPDV kao tip opcionog polja
								kupca
							</li>
						</ul>
						<strong>1.2.17</strong> - 15.07.2022.
						<br />
						<ul>
							<li>
								🍾&nbsp;Omogućeno je storniranje računa prema uputstvu iz
								tehničkog vodiča
							</li>
							<li>🍾&nbsp;Prikaz statusa računa u tabeli</li>
							<li>
								🐛&nbsp;Ukoliko je korisnik greškom umesto zatvaranja avansa,
								isti samo refundirao, zatvaranjem avansa će se izvršiti samo taj
								korak, bez refundacije.
							</li>
							<li>
								🐛&nbsp;Ukoliko je bilo više vezanih avansnih uplata,
								zatvaranjem avansnog računa bi se refundirao samo odabrani
								avansni račun umesto svih. Ovaj problem je sada popravljen.
							</li>
						</ul>
						<strong>1.2.16</strong> - 08.07.2022.
						<br />
						<ul>
							<li>
								🐛&nbsp;Potencijalno rešen problem sa neispravnim ponašanjem
								liste dostupnih štampača kod nekih korisnika
							</li>
						</ul>
						<strong>1.2.15</strong> - 08.07.2022.
						<br />
						<ul>
							<li>
								🍾&nbsp;Omogućena izmena korisničkog naloga za korisnike Lite
								paketa
							</li>
							<li>🐛&nbsp;Omogućena refundacija zatvorenog avansnog računa</li>
						</ul>
						<strong>1.2.14</strong> - 03.07.2022.
						<br />
						<ul>
							<li>
								🍾&nbsp;Dodata opcija za štampanje periodičnog izveštaja po
								načinu plaćanja
							</li>
							<li>
								🍾&nbsp;Dodata opcija za razdvajanje izveštaja prodatih artikala
								po načinu plaćanja
							</li>
						</ul>
						<strong>1.2.13</strong> - 24.06.2022.
						<br />
						<ul>
							<li>
								🍾&nbsp;Dodata opcija za štampanje liste prodatih artikala na
								periodičnom izveštaju
							</li>
						</ul>
						<strong>1.2.12</strong> - 12.06.2022.
						<br />
						<ul>
							<li>
								🍾&nbsp; Dodata opcija štampanja broja tekućeg računa i poziva
								na broj na kraju fiskalnog računa
							</li>
							<li>
								🍾&nbsp; Omogućena promena širine panela sa artiklima na kasi
							</li>
							<li>⚡&nbsp; Poboljšane performanse aplikacije</li>
						</ul>
						<strong>1.2.11</strong> - 10.06.2022.
						<br />
						<ul>
							<li>
								🍾&nbsp; Broj računa je dodat na periodični izveštaj (samo na
								A4)
							</li>
							<li>
								🍾&nbsp; Podrška za vagu (samo Elicom vage u režimu
								kontinuiranog slanja težine)
							</li>
							<li>
								🍾&nbsp; Mogućnost izbora prikaza panela sa artiklima na kasi
								(lista i mreža)
							</li>
							<li>
								🐛&nbsp; Popravljen problem da kada se nakon pretrage ne unese
								količina, ona bude NaN
							</li>
						</ul>
						<strong>1.2.10</strong> - 04.06.2022.
						<br />
						<ul>
							<li>
								🍾&nbsp; Promet bez poreza je dodat na periodični izveštaj (samo
								na A4)
							</li>
						</ul>
						<strong>1.2.9</strong> - 04.06.2022.
						<br />
						<ul>
							<li>🍾&nbsp; Omogućena promena lozinke korisnika</li>
							<li>
								🐛&nbsp; Ispravljen problem sa dozvolama za kreiranje artikala i
								kategorija
							</li>
							<li>🐛&nbsp; Popravljen prikaz varijanti na listi artikala</li>
						</ul>
						<strong>1.2.8</strong> - 04.06.2022.
						<br />
						<ul>
							<li>🍾&nbsp; Periodični izveštaj</li>
							<li>
								🐛&nbsp; Sprečena višestruka sinhronizacija istih računa
								prilikom prekida internet veze
							</li>
						</ul>
						<strong>1.2.7</strong> - 27.05.2022.
						<br />
						<ul>
							<li>
								🐛&nbsp; Popravljen problem koji je prouzrokovao da se za LNPDV,
								SNPDV i PPO-PDV obračunava PDV ukoliko se račun šalje na e-mail
							</li>
							<li>
								🐛&nbsp; Potencijalno popravljen problem sa pogrešnim
								obračunavanjem srednjeg kursa u retkim situacijama
							</li>
						</ul>
						<strong>1.2.6</strong> - 23.05.2022.
						<br />
						<ul>
							<li>
								🐛&nbsp; Popravljen problem što se prilikom zatvaranja avansnog
								računa u polju za reklamu prikazivao pogrešan datum poslednjeg
								avansnog računa
							</li>
						</ul>
						<strong>1.2.5</strong> - 21.05.2022.
						<br />
						<ul>
							<li>
								🐛&nbsp; Ispravka za pogrešnu vremensku zonu nije funkcionisala
								na svim računarima.
							</li>
							<li>
								🐛&nbsp; Sprečeno dupliranje artikala u pretrazi prilikom
								dodavanja ili izmene artikala.
							</li>
							<li>
								🐛&nbsp; Ispravljen pogrešan način plaćanja prilikom izrade
								kopije računa.
							</li>
						</ul>
						<strong>1.2.4</strong> - 20.05.2022.
						<br />
						<ul>
							<li>
								🐛&nbsp; Ispravljeno slanje vremena PFR-u u pogrešnoj vremenskoj
								zoni (UTC umesto lokalnog vremena)
							</li>
						</ul>
						<strong>1.2.3</strong> - 20.05.2022.
						<br />
						<ul>
							<li>
								🐛&nbsp; Ispravljen problem da dugme za potvrdu plaćanja nije
								bilo omogućeno u nekim slučajevima kada je unet datum avansnog
								računa.
							</li>
						</ul>
						<strong>1.2.2</strong> - 20.05.2022.
						<br />
						<ul>
							<li>
								🍾&nbsp; Usklađen izbor vrste prometa sa zahtevima Poreske
								uprave. Vrsta prometa se može odabrati samo prilikom
								registracije preduzeća.
							</li>
							<li>🍾&nbsp; Nove ikonice</li>
							<li>
								🐛&nbsp; Mrežni termalni štampač više ne drži otvorenu konekciju
								već se konektuje prilikom štampanja svakog računa. Rešava
								problem sa štampom sa više računara ukoliko štampač ne podržava
								više konekcija.
							</li>
						</ul>
						<strong>1.2.1</strong> - 18.05.2022.
						<br />
						<ul>
							<li>
								🐛&nbsp; Uklonjeno periodično slanje komande štampaču radi
								održavanja konekcije jer prouzrukuje probleme
							</li>
						</ul>
						<strong>1.2.0</strong> - 16.05.2022.
						<br />
						<ul>
							<li>
								🍾&nbsp; Omogućen izbor dostupnih metoda plaćanja. Sada je
								moguće izabrati da budu dostupne sve metode plaćanja ili samo
								gotovina, prenos na račun, vaučer i drugo bezgotovinsko plaćanje
								za prodajna mesta koja vrše promet hrane i pića koja se
								konzumiraju na licu mesta.
							</li>
							<li>
								🍾&nbsp; Omogućen izbor redosleda načina plaćanja, kao i koji
								načini plaćanja će biti sakriveni na dijalogu za plaćanje.
							</li>
							<li>
								🍾&nbsp; Prilikom refundacije, kopija računa sa mestom za potpis
								se sada izdaje samo u slučaju da je metoda plaćanja gotovina
							</li>
							<li>
								🍾&nbsp; Dodato obaveštenje o uspešno izdatom računu i iznosu
								povraćaja novca
							</li>
							<li>
								🐛&nbsp; Mehanizam ponovnog pokušaja štampe za serijske i mrežne
								termalne štampače
							</li>
							<li>
								🐛&nbsp; Ujednačena širina teksta na celom fiskalnom računu
							</li>
							<li>
								🐛&nbsp; Popravljen prikaz ispravne valute ukoliko se sa prikaza
								kategorija na kasi odabere proizvod koji ima varijante
							</li>
							<li>
								🐛&nbsp; Aplikacija više ne štampa račun na termalnom štampaču
								ukoliko je on isključen, a račun je poslat na e-mail
							</li>
							<li>
								🐛&nbsp; Ukoliko se vrši akcija na referentnom računu (kopija,
								refundacija, itd.), a nije odabrano prodajno mesto, aplikacija
								će zahtevati odabir prodajnog mesta
							</li>
							<li>
								⚡&nbsp; Poboljšane performase generisanja računa za štampu
							</li>
						</ul>
						<strong>1.1.4</strong> - 12.05.2022.
						<br />
						<ul>
							<li>🐛&nbsp; Omogućen unos ":" u polju identifikacije kupca</li>
						</ul>
						<strong>1.1.3</strong> - 12.05.2022.
						<br />
						<ul>
							<li>
								🐛&nbsp; Ispravljena greška pri refundaciji avansa izdatih po
								sistemu pre verzije 1.1.0
							</li>
							<li>
								🍾&nbsp; Dodat mehanizam za prepoznavanje nedostupnosti
								sertifikata ili problema u radu V-PFR-a
							</li>
						</ul>
						<strong>1.1.2</strong> - 09.05.2022.
						<br />
						<ul>
							<li>
								🍾&nbsp; Omogućeno ručno unošenje podataka za preduzeća čiji se
								podaci ne nalaze u bazi podataka Narodne Banke Srbije
							</li>
							<li>
								🐛&nbsp; Popravljena sinhronizacija prodaja ukoliko je došlo do
								prekida interneta, ili je server bio nedostupan tokom izdavanja
								računa.
							</li>
						</ul>
						<strong>1.1.1</strong> - 09.05.2022.
						<br />
						<ul>
							<li>
								🍾&nbsp; Dodat izbor komande koja se koriti za štampanje računa
							</li>
						</ul>
						<strong>1.1.0</strong> - 09.05.2022.
						<br />
						<ul>
							<li>
								🍾&nbsp; Avansni računi su izmenjeni prema novom tehničkom
								uputstvu Poreske uprave
							</li>
							<li>
								🍾&nbsp; Poboljšano štampanje logo-a preduzeća. Sada se nijanse
								sive štampaju dither metodom.
							</li>
							<li>
								🍾&nbsp; Dodata pauza između uzastopnih računa (trajanje se može
								konfigurisati)
							</li>
							<li>🐛&nbsp; Popravljen filter računa po datumu</li>
							<li>
								🐛&nbsp; Poboljšana detekcija završetka štampe na štampačima
								preko serijskog porta
							</li>
							<li>
								🐛&nbsp; Popravljena povremena ispadanja teksta van okvira
								papira na nekim štampačima
							</li>
						</ul>
						<strong>1.0.16</strong> - 06.05.2022.
						<br />
						<ul>
							<li>
								🍾&nbsp; Prilikom odabira PPO-PDV dokumenta, na artiklima se ne
								obračunava PDV
							</li>
						</ul>
						<strong>1.0.15</strong> - 03.05.2022.
						<br />
						<ul>
							<li>🐛&nbsp; Popravljen problem prilikom dodavanja uređaja</li>
						</ul>
						<strong>1.0.14</strong> - 01.05.2022.
						<br />
						<ul>
							<li>
								🍾&nbsp; Mogućnost biranja poreskog tretmana za dokumenta LNPDV
								i SNPDV
							</li>
							<li>
								🐛&nbsp; Popravljena validacija polja ID kupca i Opciono polje
								kupca
							</li>
						</ul>
						<strong>1.0.13</strong> - 30.04.2022.
						<br />
						<ul>
							<li>
								🍾&nbsp; Mogućnost refundacije računa koji su nastali pre
								početka korišćenja aplikacije, ili su izdati po starom modelu
								fiskalizacije
							</li>
							<li>⚡&nbsp; Poboljšane performanse kase i proizvoda</li>
						</ul>
						<strong>1.0.12</strong> - 28.04.2022.
						<br />
						<ul>
							<li>
								🍾&nbsp; Mogućnost dodavanja šifre proizvođača uz proizvod
							</li>
							<li>🐛&nbsp; Popravljeno nekoliko grešaka prilikom uvoza</li>
							<li>
								🐛&nbsp; Popravljen unos količine prilikom skeniranja bar koda
							</li>
						</ul>
						<strong>1.0.11</strong> - 28.04.2022.
						<br />
						<ul>
							<li>🐛&nbsp; Popravljeno povezivanje POS terminala</li>
							<li>🐛&nbsp; Popravljena štampa QR koda na A4 formatu</li>
							<li>
								🐛&nbsp; Popravljena greška koja je uzrokovala da neki elementi
								prvog odštampanog računa budu van dimenzija papira
							</li>
						</ul>
						<strong>1.0.10</strong> - 28.04.2022.
						<br />
						<ul>
							<li>⚡&nbsp; Ubrzano generisanje računa za štampu</li>
							<li>
								🍾&nbsp; Mogućnost štampe logo-a preduzeća i dodatnog
								nefiskalnog teksta
							</li>
							<li>🐛&nbsp; Popravljeno povezivanje bar kod čitača</li>
						</ul>
						<strong>1.0.9</strong> - 27.04.2022.
						<br />
						<ul>
							<li>
								🍾&nbsp; Mogućnost podešavanja rezolucije termalnog štampača
							</li>
						</ul>
						<strong>1.0.8</strong> - 27.04.2022.
						<br />
						<ul>
							<li>🍾&nbsp; Podrška za otvaranje fioke za novac</li>
							<li>
								💄&nbsp; Štampa na termalnom štampaču sada zauzima potpunu
								širinu papira
							</li>
							<li>🐛&nbsp; Popravljeno dodavanje mrežnih termalnih štampača</li>
							<li>🐛&nbsp; Popravljeno dodavanje korisnika</li>
							<li>
								🐛&nbsp; Popravljena podrška za termalne štampače preko
								serijskog porta
							</li>
							<li>🐛&nbsp; Popravljena pozicija sečenja papira</li>
							<li>
								🐛&nbsp; Popravljena greška da račun nije mogao da se
								fiskalizuje ukoliko se na njemu nalazi uvezen artikal bez GTIN-a
							</li>
						</ul>
						<strong>1.0.7</strong> - 24.04.2022.
						<br />
						<ul>
							<li>
								🍾&nbsp; Kod naziva kategorija na kasi se sada ispisuje broj
								kategorija i artikala koji se nalaze u njoj
							</li>
							<li>💄&nbsp; Popravljen font na pojedinim web browserima</li>
							<li>
								💄&nbsp; Podešene margine grafikona tako da sadržaj ne bude
								odsečen
							</li>
							<li>
								🐛&nbsp; Popravljena greška da se preostali iznos avansog računa
								ne izračunava kako treba ukoliko postoji artikal čija je cena u
								stranoj valuti a izabran je drugi datum izdavanja računa
							</li>
						</ul>
						<strong>1.0.6</strong> - 21.04.2022.
						<br />
						<ul>
							<li>
								🍾&nbsp; Aplikacija je odobrena za upotrebu od strane Poreske
								Uprave Republike Srbije
							</li>
							<li>
								🐛&nbsp; Popravljena greška da prilikom refundacije nekih tipova
								računa nije tražena identifikacija kupca
							</li>
							<li>
								🐛&nbsp; Popravljena greška da prilikom refundacije nije
								štampano referentno vreme na kopiji računa.
							</li>
						</ul>
						<strong>1.0.5</strong> - 21.04.2022.
						<br />
						<ul>
							<li>
								🍾&nbsp; Kurnsa lista se sada periodično učitava, pa nije više
								potrebno restartovati aplikaciju da bi se učitala nova
							</li>
							<li>🍾&nbsp; Uvoz/Izvoz artikala sada podržava strane valute</li>
							<li>
								🍾&nbsp; Upozorenje na modulu kasa ukoliko artikal nema cenu
							</li>
							<li>
								🐛&nbsp; Ispravljena greška koja je uzrokovala da artikli u
								stranoj valuti imaju cenu 0 RSD prilikom dodavanja na račun
							</li>
							<li>
								🐛&nbsp; Ispravljena greška koja je uzrokovala da se prodajno
								mesto ne može sačuvati ukoliko je adresa prazna
							</li>
							<li>
								🐛&nbsp; Popravljen izvoz artikala ukoliko proizvod ima
								varijante
							</li>
							<li>
								🐛&nbsp; Valuta RSD se sada uvek pojavljuje u padajućem meniju
								prilikom dodavanja/izmene artikla
							</li>
						</ul>
						<strong>1.0.4</strong> - 20.04.2022.
						<br />
						<ul>
							<li>
								🐛&nbsp; Poreske oznake su izmenjene tako da se može postaviti
								više poreskih oznaka po artiklu
							</li>
						</ul>
						<strong>1.0.3</strong> - 19.04.2022.
						<br />
						<ul>
							<li>
								🐛&nbsp; Ispravljena je greška koja je onemogućavala izbor
								kategorije na artiklu ukoliko u njoj postoje podkategorije
							</li>
							<li>
								🐛&nbsp; Ispravljena greška usled koje samostalna aplikacija
								nije bila u mogućnosti da se poveže na razvojni LPFR
							</li>
							<li>
								🐛&nbsp; Artikli koji više nisu dostupni se uklanjaju iz kase, i
								nije ih moguće otvoriti iz izveštaja.
							</li>
						</ul>
						<strong>1.0.2</strong> - 19.04.2022.
						<br />
						<ul>
							<li>
								🍾&nbsp; Mogućnost izbora dostupnih metoda izdavanja računa po
								prodajnom mestu
							</li>
							<li>
								💄&nbsp; Promenjen prikaz u kalendarskom pregledu računa kako bi
								dani kada je bilo računa bili uočljiviji
							</li>
							<li>
								💄&nbsp; Dodato pojašnjenje u vezi naziva i adrese prodajnog
								mesta
							</li>
							<li>
								💄&nbsp; Iznos popusta u modulu kasa se sada ne prelama u više
								redova
							</li>
							<li>
								🐛&nbsp; Unos adrese i broja telefona prodajnog mesta nije više
								obavezan prilikom registracije
							</li>
							<li>
								🐛&nbsp; Prilikom izbora kategorija, više se neće birati cele
								kategorije ukoliko su sve podkategorije izabrane
							</li>
							<li>
								🐛&nbsp; U izboru kategorije u modulu kasa, u korenoj kategoriji
								se sada prikazuju artikli bez kategorije
							</li>
						</ul>
						<strong>1.0.1</strong> - 18.04.2022.
						<br />
						<ul>
							<li>🍾&nbsp; Podrška za webp format</li>
							<li>
								🍾&nbsp; Podrška za termalne štampače povezane putem serijskog
								porta
							</li>
							<li>
								🍾&nbsp; Mogućnost definisanja cene artikla u stranoj valuti i
								automatska konverzija po srednjem kursu NBS
							</li>
							<li>
								🐛&nbsp; Ispravljena greška prilikom izbora korene kategorije za
								oca
							</li>
							<li>
								🐛&nbsp; Aplikacija sada izveštava da postoji greška ukoliko je
								dobije od API-ja
							</li>
							<li>
								🐛&nbsp; Sužena površina štampe jer na nekim termalnim
								štampačima nije bilo moguće štampati van određenih granica
							</li>
							<li>
								🐛&nbsp; Popravljena greška usled koje se nije štampalo više od
								50 linija fiskalnog računa
							</li>
							<li>
								🐛&nbsp; Popravljena greška koja je uzrokovala da se prilikom
								dodavanja prodajnog mesta iz modula kasa, pritiskom na numeričke
								karaktere, otvarao prozor za unos šifre proizvoda.
							</li>
							<li>
								🐛&nbsp; Popravljena je validacija GTIN-a prilikom unosa
								proizvoda.
							</li>
							<li>
								🐛&nbsp; Popravljena je validacija naziva varijante prilikom
								unosa proizvoda.
							</li>
						</ul>
						<strong>1.0.0</strong> - 08.04.2022.
						<br />
						<ul>
							<li>🍾&nbsp; Inicijalna verzija</li>
						</ul>
					</>
				)}
			</p>
		</div>
	);
}

export default About;
